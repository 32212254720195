import * as yup from "yup";
import { useContext, useMemo } from "react";
import CommentIcon from "@mui/icons-material/Comment";
import i18next, { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { startOfMonth } from "date-fns";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TimesheetMonthDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { TimesheetObservationFormValues } from "./shared/values.types";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { saveTimesheetObservation } from "../../../../../../../../../services/timesheet/timesheets.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { TimesheetContext } from "../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useSaveTimesheetObservation = ({ reloadTablePage }: OwnProps) => {
  const { dateFilter } = useContext(TimesheetContext);

  const isPastMonth = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    return startOfCurrentMonth > startDateFilter;
  }, [dateFilter]);

  const formikConfig = useMemo(() => {
    const initialValues: TimesheetObservationFormValues = {
      observation: "",
    };

    const validationSchema = yup.object({
      observation: yup
        .string()
        .nullable()
        .required("general.errors.data.fields.general.required"),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, []);

  const {
    ContentButton: TimesheetObservationButtonContainer,
    contentModal: timesheetObservationModal,
  } = useFormikModalButton<
    TimesheetObservationFormValues,
    TimesheetMonthDTO,
    TimesheetMonthDTO
  >({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => (
        <div>{t("timesheets.actions.general.insertObservation")}</div>
      ),
      modalMode: "dialog",
    },
    button: {
      onClickContentButtonComponent: async ({
        contentButtonContentProps: timesheet,
        setInternalFormData,
        setFormValues,
      }) => {
        setInternalFormData(timesheet);
        setFormValues({
          observation: timesheet.observation || "",
        });
      },
      FormButtonMemo: ({ onClick }) => (
        <SimpleIconButton
          Icon={CommentIcon}
          tooltipText={t("allocationsMap.keywords.general.observation")}
          onClick={onClick}
        />
      ),
    },
    form: {
      formikConfig,
      FormContentMemo: () => (
        <TextFieldFormik
          name="observation"
          InputProps={{
            disabled: isPastMonth,
            sx: { width: "350px" },
          }}
          label={t("allocationsMap.keywords.general.observation")}
        />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              onClick={submitFormValues}
              variant="contained"
              loading={isSubmitting}
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        setSubmitting(true);
        if (!internalFormData)
          throw new Error("Missing internal form data on submit.");

        try {
          await saveTimesheetObservation({
            observation: formValues.observation,
            ids: internalFormData.days.map((day) => day.id),
          });

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "timesheets.errors.data.edit.failedToEditTimesheetEntryp",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return {
    TimesheetObservationButtonContainer,
    timesheetObservationModal,
  };
};
