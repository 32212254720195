import * as yup from "yup";
import { CorporateSegmentsCreateDTO } from "../../../shared/specific/DTOs/corporateSegment/CorporateSegmentsCreateDTO";
import {
  CorporateSegmentsDTO,
  schemaCorporateSegmentsDTO,
} from "../../../shared/specific/DTOs/corporateSegment/CorporateSegmentsDTO";
import { CorporateSegmentsUpdateDTO } from "../../../shared/specific/DTOs/corporateSegment/CorporateSegmentsUpdateDTO";
import { CorporateSegmentsFilterDTO } from "../../../shared/specific/DTOs/corporateSegment/filters/CorporateSegmentsFilterDTO";
import {
  PaginatedCorporateSegmentsDTO,
  schemaPaginatedCorporateSegmentsDTO,
} from "../../../shared/specific/DTOs/corporateSegment/PaginatedCorporateSegmentsDTO";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { EmployeeAllocationType } from "../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { riseApi } from "./connection";

export const getCorporateSegments = async ({
  filters,
  pagination,
}: {
  filters?: CorporateSegmentsFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedCorporateSegmentsDTO> => {
  const { data: corporateSegments } = await riseApi.get("CorporateSegment", {
    params: {
      ...pagination,
      ...filters,
      showingOnTimesheet: true,
    },
  });

  return schemaPaginatedCorporateSegmentsDTO.validateSync(corporateSegments);
};

export const getDropdownCorporateSegments = async ({
  filters,
  pagination,
}: {
  filters?: CorporateSegmentsFilterDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedCorporateSegmentsDTO> => {
  const { data: corporateSegments } = await riseApi.get(
    "CorporateSegment/Dropdown",
    {
      params: {
        ...pagination,
        ...filters,
      },
    }
  );

  return schemaPaginatedCorporateSegmentsDTO.validateSync(corporateSegments);
};

export const getDropdownCorporateSegmentsCache = async ({
  filters,
}: {
  filters?: CorporateSegmentsFilterDTO;
}): Promise<CorporateSegmentsDTO[]> => {
  const { data: corporateSegments } = await riseApi.get(
    "CorporateSegment/Dropdown/cache",
    {
      params: {
        ...filters,
      },
    }
  );

  return yup
    .array(schemaCorporateSegmentsDTO.defined())
    .defined()
    .validateSync(corporateSegments);
};

export const getCorporateSegmentById = async (
  id: number
): Promise<CorporateSegmentsDTO | null> => {
  const { data: corporateSegment } = await riseApi.get(
    `/CorporateSegment/${id}`
  );
  return schemaCorporateSegmentsDTO.nullable().validateSync(corporateSegment);
};

export const getCostCentersByAllocationType = async (
  allocationType: EmployeeAllocationType
): Promise<CostCenterDTO[]> => {
  const { data: costCenters } = await riseApi.get(
    `/CorporateSegment/ListAsyncByCostCenter/${allocationType}`
  );

  return yup.array(schemaCostCenterDTO).defined().validateSync(costCenters);
};

export const getCostCentersByAllocationTypeForCorporateAllocation = async (
  allocationType: EmployeeAllocationType
): Promise<CostCenterDTO[]> => {
  const { data: costCenters } = await riseApi.get(
    `/CorporateSegment/ListAsyncByCostCenterForCorporateAllocation/${allocationType}`
  );

  return yup.array(schemaCostCenterDTO).defined().validateSync(costCenters);
};

export const createCorporateSegment = async (
  corporateSegment: CorporateSegmentsCreateDTO
) => {
  await riseApi.post("CorporateSegment", corporateSegment);
};

export const updateCorporateSegment = async (
  corporateSegment: CorporateSegmentsUpdateDTO
) => {
  await riseApi.put("CorporateSegment", corporateSegment);
};

export const deleteCorporateSegment = async (id: number, version: string) => {
  await riseApi.delete(`CorporateSegment/${id}/${version}`);
};
