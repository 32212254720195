import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TimesheetWeekFormValues } from "../shared/types/form.values";
import { checkIsValidHourString } from "../../../shared/helpers/data/calendar/validators.helpers";
import { ActivityDropdownDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDropdownDTO";

export const useFormikConfig = () => {
  const { t, i18n } = useTranslation();

  const formikConfig = useMemo(() => {
    const initialValues: TimesheetWeekFormValues = {
      projectTimesheets: [],
      costCenterTimesheets: [],
      projectCreateTimesheets: [],
      costCenterCreateTimesheets: [],
    };

    const validationSchema = yup.object({
      projectTimesheets: yup.array(
        yup.object({
          timesheet: yup.object().defined(),
          days: yup.array(yup.object()),
        })
      ),
      costCenterTimesheets: yup.array(
        yup.object({
          timesheet: yup.object().defined(),
          days: yup.array(
            yup
              .object()
              .required(t("general.errors.data.fields.general.required"))
          ),
        })
      ),
      projectCreateTimesheets: yup.array(
        yup.object({
          appointment: yup
            .object()
            .nullable()
            .required(t("general.errors.data.fields.general.required")),
          activity: yup
            .object()
            .nullable()
            .test({
              name: "activity-required",
              message: t("Essa atividade não pertence ao projeto selecionado."),
              test(value) {
                const project = this.parent.appointment;
                const activity = value as ActivityDropdownDTO | null;
                if (!activity) return true;
                if (!project && activity) return false;
                if (project?.id !== activity?.idProject) return false;
                return true;
              },
            }),
          days: yup.array(
            yup
              .string()
              .required(t("general.errors.data.fields.general.required"))
              .test(
                "is-valid-hour",
                t("general.errors.data.fields.dates.invalidTime"),
                (value) => {
                  if (!value) return false;
                  return checkIsValidHourString(value);
                }
              )
          ),
        })
      ),
      costCenterCreateTimesheets: yup.array(
        yup.object({
          appointment: yup
            .object()
            .nullable()
            .required(t("general.errors.data.fields.general.required")),
          days: yup.array(
            yup
              .string()
              .test(
                "is-valid-hour",
                t("general.errors.data.fields.dates.invalidTime"),
                (value) => {
                  if (!value) return false;
                  return checkIsValidHourString(value);
                }
              )
              .required(t("general.errors.data.fields.general.required"))
          ),
        })
      ),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, [i18n]);

  return formikConfig;
};
