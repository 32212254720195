import { TFunction } from "i18next";
import { TimesheetRecurrenceDTO } from "../../../../../../../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceDTO";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";

export const getTimesheetRecurrenceLineIdentification = (
  timesheet: TimesheetRecurrenceDTO,
  t: TFunction<"translation", undefined>
) => {
  if (timesheet.costCenter && timesheet.corporateSegment) {
    return `${timesheet.costCenter.code} - ${timesheet.corporateSegment.name}`;
  }

  if (timesheet.costCenter && !timesheet.corporateSegment) {
    return `${timesheet.costCenter.code} - ${t(employeeAllocationTypeToTranslationCodeMap[timesheet.allocationType])}`;
  }

  if (timesheet.corporateSegment) {
    return `${timesheet.corporateSegment.name}`;
  }

  if (timesheet.project) {
    return timesheet.project.name;
  }

  if (timesheet.allocationType) {
    return t(
      employeeAllocationTypeToTranslationCodeMap[timesheet.allocationType]
    );
  }

  return "-";
};
