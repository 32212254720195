import { styled } from "@mui/material/styles";

export const StyledTotalTableCell = styled("span")`
  font-weight: bold;
`;

export const StyledIdentificationCell = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`;

export const StyledIdentificationAddCellContainer = styled("div")`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledIdentificationAddCell = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledExtraTimeText = styled("span")`
  font-weight: bold;
  color: "#ffffff";
  font-size: 0.8rem;
`;

export const StyledExtraTimeObservationContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
