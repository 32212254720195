import * as yup from "yup";
import {
  schemaTimesheetRecurrenceDTO,
  TimesheetRecurrenceDTO,
} from "../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceDTO";
import { riseApi } from "./connection";
import { TimesheetRecurrenceModifyDTO } from "../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceModifyDTO";

export const getLoggedUserTimesheetRecurrence = async (): Promise<
  TimesheetRecurrenceDTO[]
> => {
  const { data: timesheetsPerMonth } = await riseApi.get(
    "/TimesheetRecurrence"
  );

  return yup
    .array(schemaTimesheetRecurrenceDTO)
    .defined()
    .validateSync(timesheetsPerMonth);
};

export const mofifyTimesheetRecurrennce = async (
  creationDTO: TimesheetRecurrenceModifyDTO
) => {
  await riseApi.put("/TimesheetRecurrence", creationDTO);
};

export const deleteTimesheetRecurence = async (ids: number[]) => {
  await riseApi.delete("/TimesheetRecurrence", {
    data: ids,
  });
};
