import { useEffect, useState } from "react";
import { ButtonBase, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useTranslation } from "react-i18next";
import { PageCardButtonContainer } from "../../../../shared/common/react/components/navigation/PageCardButton/index.styles";
import {
  CheckCanGenerateDreProjection,
  GenerateDreProjection,
} from "../../../../services/DreQueue/dreQueue.service";
import { notifyIf4xxApiErrorDTO } from "../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../services/applicationState/toast.service";

export const DreProjectionCardComponent = () => {
  const { t } = useTranslation();
  const [disabledDreProjection, setDisabledDreProjection] =
    useState<boolean>(true);

  const onclick = async () => {
    try {
      await GenerateDreProjection();
      notifySuccess(t("home.suscess.data.GenerateDreProjection"));
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "",
      });
    } finally {
      setDisabledDreProjection(true);
    }
  };

  const VerifyDreProjection = async () => {
    const result = await CheckCanGenerateDreProjection();
    setDisabledDreProjection(!result);
  };

  useEffect(() => {
    VerifyDreProjection();
    const intervalId = setInterval(VerifyDreProjection, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ButtonBase onClick={onclick} disabled={disabledDreProjection}>
      <PageCardButtonContainer>
        <AddAPhotoIcon color={disabledDreProjection ? "disabled" : "primary"} />
        <Typography>
          {t("projection.keywords.fields.projectionsPhotography")}
        </Typography>
      </PageCardButtonContainer>
    </ButtonBase>
  );
};
