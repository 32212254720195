import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getCorporateSegments } from "../../../../../services/corporateSegments/corporateSegments.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { CorporateSegmentsFilterDTO } from "../../../../../shared/specific/DTOs/corporateSegment/filters/CorporateSegmentsFilterDTO";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";

interface OwProps {
  filters?: CorporateSegmentsFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage, filters }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("costCenters.keywords.general.costCenter"),
      t("general.keywords.fields.name"),
      t("corporateSegment.keywords.fields.allocationType"),
      t("contractRole.keywords.fields.deactivatedDate"),
      {
        value: "",
        width: 112,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onCorporateSegmentsPageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = ["costCenter", "name", "allocationType"];

        try {
          const {
            tableData: corporateSegmentsData,
            page,
            totalCount,
          } = await getCorporateSegments({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = corporateSegmentsData.map(
            (corporateSegmentsData): ExternalEnhancedRow => ({
              id: corporateSegmentsData.id,
              cells: [
                corporateSegmentsData.costCenter?.code ?? "-",
                corporateSegmentsData.corporateSegmentTranslation.find(
                  (translation) => translation.idLanguage === i18n.language
                )?.name ?? corporateSegmentsData.name,
                {
                  CellRenderer: () => (
                    <div>
                      {t(
                        employeeAllocationTypeToTranslationCodeMap[
                          corporateSegmentsData.allocationType
                        ]
                      )}
                    </div>
                  ),
                },
                {
                  value: corporateSegmentsData.deactivatedDate,
                  displayValue:
                    corporateSegmentsData.deactivatedDate?.toLocaleDateString(
                      i18n.language
                    ) ?? "-",
                },
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.CorporateSegment,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...corporateSegmentsData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.CorporateSegment,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...corporateSegmentsData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [i18n, filters]
    );

  return {
    headCells,
    onCorporateSegmentsPageChange,
    editingModal,
    deletionModal,
  };
};
