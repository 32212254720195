import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledModalContent } from "./index.styles";
import { TimesheetMonthDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { getTimesheetLineIdentification } from "../../../helpers/data/timesheet/general.helpers";

interface OwnProps {
  timesheetMonth: TimesheetMonthDTO;
}

export const ModalContent = ({ timesheetMonth }: OwnProps) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledModalContent>
      <TextField
        label={t("general.keywords.dateTime.general.month")}
        value={timesheetMonth.yearMonth.toPrettyString()}
        disabled
      />
      <TextField
        label={t("general.keywords.fields.code")}
        value={getTimesheetLineIdentification(timesheetMonth, t, i18n)}
        disabled
      />
    </StyledModalContent>
  );
};
