import { addDays, addWeeks } from "date-fns";
import { DateOnly } from "../../../classes/data/date/DateOnly";
import { YearMonth } from "../../../classes/data/date/YearMonth";
import { MonthWeeksProporcion } from "../../../DTOs/publicData/calendar/MonthWeeksProporcion";
import { WeeksProporcion } from "../../../DTOs/publicData/calendar/WeeksProporcion";
import {
  convertDateToUTC,
  convertToBrazilianTimezone,
} from "./converters.helpers";

export const getNowUTC = () => {
  return convertDateToUTC(new Date());
};

export const getNowBrazilianDate = () => {
  return convertToBrazilianTimezone(new Date());
};

export const getNowBrazilianDateOnly = () => {
  return DateOnly.createFromDate(getNowBrazilianDate());
};

export const getNowYearMonthBrazil = () => {
  return YearMonth.createFromDate(getNowBrazilianDate());
};

export const getMondaysYearMonth = (yearMonth: YearMonth) => {
  const monthStart = yearMonth.toDate();
  const monthEnd = yearMonth.toDateEndOfMonth();

  const MONDAY = 1;
  const WEEK_DAYS = 7;
  const monthFirstMonday = addDays(
    monthStart,
    monthStart.getDay() <= MONDAY
      ? MONDAY - monthStart.getDay()
      : WEEK_DAYS - monthStart.getDay() + MONDAY
  );

  const mondaysMonth = addWeeks(monthFirstMonday, 4) > monthEnd ? 4 : 5;
  return mondaysMonth;
};

export const GetWorkingDaysYearMonth = (yearMonth: YearMonth) => {
  const monthStart = yearMonth.toDate();
  const monthEnd = yearMonth.toDateEndOfMonth();
  let workingDays = 0;
  let weekDays = 0;
  let idWeek = 1;
  const weeksProporcion: WeeksProporcion[] = [];
  let monthWeeksProporcion: MonthWeeksProporcion = {
    workingDays: 0,
    weeks: weeksProporcion,
  };
  while (monthStart <= monthEnd) {
    if (monthStart.getDay() !== 0 && monthStart.getDay() !== 6) {
      workingDays += 1;
      weekDays += 1;
    }

    if (
      monthStart.getDay() === 0 ||
      monthStart.getDate() === monthEnd.getDate()
    ) {
      if (weekDays !== 0) {
        weeksProporcion[idWeek] = { id: idWeek, totalDays: weekDays };
        monthWeeksProporcion = { workingDays, weeks: weeksProporcion };
        idWeek += 1;
      }
      weekDays = 0;
    }
    monthStart.setDate(monthStart.getDate() + 1);
  }
  return monthWeeksProporcion;
};

export function getWeekNumberOfMonth(date: Date): number {
  const dayOfMonth = date.getDate();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const startingDay = firstDayOfMonth.getDay();

  const weekNumber = Math.ceil((dayOfMonth + startingDay) / 7);

  return weekNumber;
}
