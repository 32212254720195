import * as yup from "yup";
import LoopIcon from "@mui/icons-material/Loop";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FillRecurrenceFormValues } from "./shared/values.types";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TimesheetContext } from "../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { getLoggedUserTimesheetRecurrence } from "../../../../../../../../../services/timesheetRecurrence/timesheetRecurrence.service";
import { RecurrenceForm } from "./shared/components/recurrenceForm";
import { mofifyTimesheetV2 } from "../../../../../../../../../services/timesheet/timesheets.service";
import { setTimesheetRecurrenceValues } from "./shared/setTimesheetRecurrenceValues";

export const useFillRecurrenceForm = () => {
  const { t } = useTranslation();
  const { dateFilter, timesheetData, reloadTimesheetData } =
    useContext(TimesheetContext);

  const formikConfig = useMemo(() => {
    const initialValues: FillRecurrenceFormValues = {
      recurrences: [],
    };

    const validationSchema = yup.object({
      recurrences: yup.array(),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, []);

  const { ContentButton: RecurrenceButton, contentModal: recurrenceModal } =
    useFormikModalButton<FillRecurrenceFormValues>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => (
          <div>{t("timesheets.actions.general.useRecurrence")}</div>
        ),
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();
          return (
            <Button onClick={onClick}>
              <LoopIcon />
              {t("timesheetRecurrence.pages.main.recurrence")}
            </Button>
          );
        },
        onClickContentButtonComponentMemo: async ({
          setFormValues,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const recurrences = await getLoggedUserTimesheetRecurrence();

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setFormValues({
              recurrences: recurrences.map((recurrence) => ({
                recurrence,
                use: true,
              })),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: t("general.errors.data.fetch.failedToFetchData"),
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: (props) => {
          return <RecurrenceForm {...props} />;
        },
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                onClick={submitFormValues}
                variant="contained"
                loading={isSubmitting}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            setSubmitting(true);
            const updateList = setTimesheetRecurrenceValues({
              timesheetData,
              formValues,
              dateFilter,
            });

            if (!updateList) {
              return;
            }

            await mofifyTimesheetV2(updateList);

            await reloadTimesheetData();

            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "timesheets.errors.data.edit.failedToEditTimesheetEntry",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { RecurrenceButton, recurrenceModal };
};
