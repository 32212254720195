import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  ContractType,
  schemaContractType,
} from "../../enums/projects/ContractType.enum";
import {
  ProjectStatus,
  schemaProjectStatus,
} from "../../enums/projects/ProjectStatus.enum";
import { CustomerDTO, schemaCustomerDTO } from "../customers/CustomerDTO";
import { DivisionDTO, schemaDivisionDTO } from "../division/DivisionDTO";
import { FrameOnlyDTO, schemaFrameOnlyDTO } from "../frame/FrameOnlyDTO";
import {
  ProductServiceDTO,
  schemaProductServiceDTO,
} from "../productService/ProductServiceDTO";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import {
  schemaUserCollaboratorDTO,
  UserCollaboratorDTO,
} from "../user/UserCollaboratorDTO";
import { SegmentDTO, schemaSegmentDTO } from "../Segment/SegmentDTO";
import { TypeOfEvidenceEnum } from "../../enums/projects/TypeOfEvidence.enum";

export interface ProjectWithRelatedInfoDTO {
  id: number;
  code: string;
  name: string;
  description: string;
  contractType: ContractType;
  typeOfEvidence: TypeOfEvidenceEnum | null;
  daysForPayment: number;
  billingAmount: number;
  status: ProjectStatus;
  scheduledStartDate: DateOnly | null;
  scheduledEndDate: DateOnly | null;
  mv: number | null;
  isValid: boolean;
  lastInvalidationDate: Date | null;
  validatedAt: Date | null;
  validatedBy: string | null;
  createdBy: string;
  insertedDate: Date;
  updatedDate: Date;
  customer: CustomerDTO;
  userManager: UserCollaboratorDTO;
  userCoordinator: UserCollaboratorDTO | null;
  subsidiary: SubsidiaryDTO;
  division: DivisionDTO;
  productService: ProductServiceDTO;
  frame: FrameOnlyDTO | null;
  segment: SegmentDTO | null;
  dealApprovedCredit: boolean;
  isInvestmentProject: boolean;

  finalScheduledEndDate: DateOnly | null;
  additivesEndDate: DateOnly | null;
  scheduledEndDateWithAdditives: DateOnly | null;
  additivesAmount: number;
  billingAmountWithAdditives: number;
  latestActivityScheduledDate: DateOnly | null;
  latestActivityCompletedDate: DateOnly | null;
  latestInvoiceFinalDate: DateOnly | null;
  latestAllocationDate: YearMonth | null;
  latestEndDateJustification: DateOnly | null;

  version: string;
  clientAccount: string | null;
  allAdditivesAreWithApprovedCredit: boolean;
  projectInvalidatedToGenerateBaseline: boolean;
}

export const schemaProjectWithRelatedInfoDTO = yup.object({
  id: yup.number().integer().defined(),
  code: yup.string().defined(),
  name: yup.string().defined(),
  description: yup.string().defined(),
  contractType: schemaContractType.defined(),
  typeOfEvidence: yup.number().integer().nullable().defined(),
  daysForPayment: yup.number().integer().defined(),
  billingAmount: yup.number().defined(),
  status: schemaProjectStatus.defined(),
  scheduledStartDate: schemaDateOnly.nullable().defined(),
  lastInvalidationDate: yup.date().nullable().defined(),
  scheduledEndDate: schemaDateOnly.nullable().defined(),
  mv: yup.number().nullable().defined(),
  isValid: yup.boolean().defined(),
  validatedAt: yup.date().nullable().defined(),
  validatedBy: yup.string().nullable().defined(),
  createdBy: yup.string().defined(),
  insertedDate: yup.date().defined(),
  updatedDate: yup.date().defined(),
  customer: schemaCustomerDTO.defined(),
  userManager: schemaUserCollaboratorDTO.defined(),
  userCoordinator: schemaUserCollaboratorDTO.nullable().defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
  division: schemaDivisionDTO.defined(),
  productService: schemaProductServiceDTO.defined(),
  frame: schemaFrameOnlyDTO.nullable().defined(),
  segment: schemaSegmentDTO.nullable().defined(),
  dealApprovedCredit: yup.boolean().defined(),
  isInvestmentProject: yup.boolean().defined(),

  finalScheduledEndDate: schemaDateOnly.nullable().defined(),
  additivesEndDate: schemaDateOnly.nullable().defined(),
  scheduledEndDateWithAdditives: schemaDateOnly.nullable().defined(),
  additivesAmount: yup.number().defined(),
  billingAmountWithAdditives: yup.number().defined(),
  latestActivityScheduledDate: schemaDateOnly.nullable().defined(),
  latestActivityCompletedDate: schemaDateOnly.nullable().defined(),
  latestInvoiceFinalDate: schemaDateOnly.nullable().defined(),
  latestAllocationDate: schemaYearMonth.nullable().defined(),
  latestEndDateJustification: schemaDateOnly.nullable().defined(),

  version: yup.string().defined(),
  clientAccount: yup.string().nullable().defined(),
  allAdditivesAreWithApprovedCredit: yup.boolean().defined(),
  projectInvalidatedToGenerateBaseline: yup.boolean().defined(),
});
