import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedRow,
  ExternalEnhancedRowCells,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { formatMinutesToHourMinute } from "../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { StyledTotalTableCell } from "../shared/styles/common.styles";
import { TotalTableRow } from "../../WeekTab/index.styles";

export const useTotalTableData = () => {
  const { t } = useTranslation();
  const { dateFilter, timesheetData } = useContext(TimesheetContext);

  const rows: ExternalEnhancedRow[] = useMemo(() => {
    const lines = [t("timesheets.keywords.fields.appointedHours")];
    const currentAppointments =
      lines.map((type, i): ExternalEnhancedRow => {
        const extraminutes = timesheetData?.extraMinutesSum ?? 0;
        const minutes = timesheetData?.minutesSum ?? 0;

        const cells: ExternalEnhancedRowCells = [
          {
            CellRenderer: () => (
              <StyledTotalTableCell>{type}</StyledTotalTableCell>
            ),
          },
          "",
          formatMinutesToHourMinute(extraminutes + minutes),
        ];

        cells.push("");

        return {
          id: i,
          cells,
          CustomTableRow: TotalTableRow,
        };
      }) ?? [];

    return currentAppointments.map((x, i) => ({
      ...x,
      id: i,
    }));
  }, [dateFilter, timesheetData, t]);

  return {
    rows,
  };
};
