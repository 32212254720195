import { TextFieldProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../../store";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../../common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../../common/types/data/form/autocomplete.types";
import { RestrictionsOperand } from "../../../../../../../../classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { RootPermissionsOperator } from "../../../../../../../../classes/user/permissions/permissionsOperators/RootPermissionsOperator";
import { EmployeeAllocationType } from "../../../../../../../../enums/allocations/EmployeeAllocationType.enums";
import { PermissionType } from "../../../../../../../../enums/users/permissions/PermissionType.enum";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../maps/allocations/EmployeeAllocationType.maps";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<EmployeeAllocationType>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const CorporateAllocationTypeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userData);

  const canAccessProperty = useCallback(
    (restrictions: RestrictionsOperand) => {
      if (!userData) return false;

      const permissionsOperator = new RootPermissionsOperator(restrictions);

      return permissionsOperator.checkPermissions({
        userPermissions: userData.permissions,
        isAdmin: userData.isAdmin,
      });
    },
    [userData?.permissions, userData?.isAdmin]
  );

  const options = useMemo(() => {
    const filteredAllocationType = Object.values(EmployeeAllocationType)
      .filter((x) => typeof x === "number")
      .map((x) => x as EmployeeAllocationType)
      .filter((x) => {
        if (x === EmployeeAllocationType.SupplementaryAllocation) return false;
        if (
          [
            EmployeeAllocationType.Allocation,
            EmployeeAllocationType.MonthlyOnboarding,
            EmployeeAllocationType.PerformanceEvaluation,
            EmployeeAllocationType.CorporateMeeting,
            EmployeeAllocationType.Holiday,
            EmployeeAllocationType.RadixActivities,
          ].includes(x)
        )
          return false;

        if (x === EmployeeAllocationType.Unallocated) {
          return canAccessProperty({
            type: PermissionType.CanAccessDeallocationInCorporateAllocation,
          });
        }
        if (x === EmployeeAllocationType.Leave) {
          return canAccessProperty({
            type: PermissionType.CanAccessLeaveInCorporateAllocation,
          });
        }
        if (x === EmployeeAllocationType.Training) {
          return canAccessProperty({
            type: PermissionType.CanAccessTrainingInCorporateAllocation,
          });
        }

        return true;
      });

    return filteredAllocationType.map(
      (x): AutocompleteOption<EmployeeAllocationType> => ({
        id: x,
        label: t(employeeAllocationTypeToTranslationCodeMap[x]),
      })
    );
  }, [i18n.language]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: t("general.keywords.fields.type"),
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
