import * as yup from "yup";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useCallback, useContext, useMemo } from "react";
import i18next, { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { startOfMonth } from "date-fns";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TimesheetMonthDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { AttachPipedriveOrganizationFormValues } from "./shared/values.types";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { attachPipedriveOrganization } from "../../../../../../../../../services/timesheet/timesheets.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { PipedriveOrganizationAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/pipedriveDeal/PipedriveOrganizationAutocompleteFormik";
import { TimesheetContext } from "../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";

interface AttachPipedriveOrganizationButtonProps {
  timesheetMonth: TimesheetMonthDTO;
  children: React.ReactNode;
}

interface OwnProps {
  reloadTablePage: () => void;
}

export const useAttachPipedriveOrganization = ({
  reloadTablePage,
}: OwnProps) => {
  const { dateFilter } = useContext(TimesheetContext);

  const formikConfig = useMemo(() => {
    const initialValues: AttachPipedriveOrganizationFormValues = {
      pipedriveOrganization: null,
    };

    const validationSchema = yup.object({
      pipedriveOrganization: yup.object().nullable().defined(),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, []);

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      AttachPipedriveOrganizationFormValues,
      TimesheetMonthDTO,
      AttachPipedriveOrganizationButtonProps
    >
  >(
    async ({
      contentButtonContentProps,
      setInternalFormData,
      setFormValues,
    }) => {
      setFormValues({
        pipedriveOrganization:
          contentButtonContentProps.timesheetMonth.pipedriveOrganization ??
          null,
      });
      setInternalFormData(contentButtonContentProps.timesheetMonth);
    },
    []
  );

  const {
    ContentButton: AttachPipedriveOrganizationButtonContainer,
    contentModal: attachPipedriveOrganizationModal,
  } = useFormikModalButton<
    AttachPipedriveOrganizationFormValues,
    TimesheetMonthDTO,
    AttachPipedriveOrganizationButtonProps
  >({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => (
        <div>{t("timesheets.actions.general.associeteClientAccount")}</div>
      ),
      modalMode: "dialog",
    },
    button: {
      onClickContentButtonComponent,
      createModalButtonContainer: ({
        onClickButton,
        onClickContentButtonComponent,
      }) => {
        const startOfCurrentMonth = startOfMonth(new Date());
        const startDateFilter = startOfMonth(dateFilter);
        const isPast = startOfCurrentMonth > startDateFilter;
        return (props) => {
          const { children } = props;
          const onClick = () => {
            onClickButton();
            onClickContentButtonComponent({ props });
          };

          return (
            <Button
              onClick={onClick}
              startIcon={<InsertLinkIcon />}
              sx={{
                textTransform: "none",
                width: "fit-content",
              }}
              disabled={isPast}
            >
              {children}
            </Button>
          );
        };
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <div>
          <PipedriveOrganizationAutocompleteFormik name="pipedriveOrganization" />
        </div>
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              onClick={submitFormValues}
              variant="contained"
              loading={isSubmitting}
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        setSubmitting(true);
        if (!internalFormData)
          throw new Error("Missing internal form data on submit.");

        try {
          await attachPipedriveOrganization({
            idPipedriveOrganization:
              formValues.pipedriveOrganization?.id || null,
            ids: internalFormData.days.map((day) => day.id),
          });

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "timesheets.errors.data.edit.failedToEditTimesheetEntryp",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return {
    AttachPipedriveOrganizationButtonContainer,
    attachPipedriveOrganizationModal,
  };
};
