import { Badge, Paper, styled } from "@mui/material";
import {
  isLastDayOfMonth,
  isPast,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isValid,
} from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import { useContext, useState } from "react";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { TimesheetContext } from "../../../contexts/TimesheetContext/TimesheetContextProvider";
import { Week } from "../../../../../../../../../../shared/common/enums/data/dates/Week.enum";

interface StyledPickersDayProps extends PickersDayProps<Date> {
  sameWeek: boolean;
  isFirstDayOfWeekOrMonth: boolean;
  isLastDayOfWeekOrMonth: boolean;
}

const StyledPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "sameWeek" &&
    prop !== "isFirstDayOfWeekOrMonth" &&
    prop !== "isLastDayOfWeekOrMonth",
})<StyledPickersDayProps>(
  ({ theme, sameWeek, isFirstDayOfWeekOrMonth, isLastDayOfWeekOrMonth }) => ({
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: sameWeek
      ? `${theme.palette.primary.main}4d`
      : "transparent",
    color: "black",
    "&.Mui-selected": {
      backgroundColor: sameWeek
        ? `${theme.palette.primary.main}4d`
        : "transparent",
      color: "black",
    },
    borderRadius: (() => {
      if (sameWeek && isFirstDayOfWeekOrMonth) return "50% 0% 0% 50%";
      if (sameWeek && isLastDayOfWeekOrMonth) return "0% 50% 50% 0%";
      if (sameWeek) return "0%";
      return "50%";
    })(),
    margin: 0,
    "&:hover": {
      backgroundColor: sameWeek
        ? theme.palette.primary.dark
        : theme.palette.action.hover,
      color: sameWeek ? theme.palette.primary.contrastText : "inherit",
      cursor: "pointer",
    },
  })
) as React.ComponentType<StyledPickersDayProps>;

export const CalendarFilter = () => {
  const { setDateFilter, dateFilter, timesheetData } =
    useContext(TimesheetContext);

  const [calendarMonth, setCalendarMonth] = useState(dateFilter);

  return (
    <Paper>
      <DateCalendar
        value={dateFilter}
        onMonthChange={(date: Date) => setCalendarMonth(date)}
        onChange={(date: Date) => {
          if (isValid(date)) {
            setDateFilter(date);
          }
        }}
        slots={{
          day: (props: PickersDayProps<Date>) => {
            const sameWeek =
              isSameWeek(props.day, dateFilter) &&
              isSameMonth(props.day, dateFilter);
            const isFirstDayOfWeekOrMonth =
              props.day.getDay() === Week.Sunday || props.day.getDate() === 1;

            const isLastDayOfWeekOrMonth =
              props.day.getDay() === Week.Saturday ||
              isLastDayOfMonth(props.day);

            const isPastDate = isPast(props.day);
            const isToday = isSameDay(props.day, new Date());
            const hasAppointments = timesheetData?.timesheetMonths.some(
              (timesheet) =>
                timesheet.days.some((timesheetDay) =>
                  isSameDay(
                    new Date(timesheetDay.entryDate.toDate()),
                    props.day
                  )
                )
            );
            const isSameCurentMonth = isSameMonth(props.day, new Date());
            const isFilterSameCurrentMonth = isSameMonth(
              dateFilter,
              new Date()
            );
            const isInTheCalendarSameCurentMonthDateFilter = isSameMonth(
              calendarMonth,
              dateFilter
            );

            const isWeekend =
              props.day.getDay() === Week.Saturday ||
              props.day.getDay() === Week.Sunday;

            const iconBadge =
              (isPastDate || isToday) &&
              isSameCurentMonth &&
              isFilterSameCurrentMonth &&
              isInTheCalendarSameCurentMonthDateFilter &&
              !hasAppointments &&
              !isWeekend ? (
                <CancelIcon
                  fontSize="small"
                  sx={{
                    fontSize: "1rem",
                    color: "red",
                  }}
                />
              ) : (
                ""
              );

            return (
              <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={iconBadge}
              >
                <StyledPickersDay
                  {...props}
                  sameWeek={sameWeek}
                  isFirstDayOfWeekOrMonth={isFirstDayOfWeekOrMonth}
                  isLastDayOfWeekOrMonth={isLastDayOfWeekOrMonth}
                >
                  {props.children}
                </StyledPickersDay>
              </Badge>
            );
          },
        }}
        sx={{
          height: "320px",
          width: "290px",
          padding: "0px",
        }}
      />
    </Paper>
  );
};
