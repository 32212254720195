import { TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { PipedriveOrganizationDropdownDTO } from "../../../../../../../DTOs/pipedrive/PipedriveOrganizationDropdownDTO";
import { getPipedriveOrganizationDropdown } from "../../../../../../../../../services/pipedriveDeal/pipedriveDeal.service";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    PipedriveOrganizationDropdownDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnMount?: boolean;
}

export const PipedriveOrganizationAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnMount = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const getPipedriveOganizationsFinal = useCallback(async () => {
    const paginatedPipedriveOganizations =
      await getPipedriveOrganizationDropdown({});
    return paginatedPipedriveOganizations;
  }, []);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t("general.errors.data.fetch.failedToFetchData")}
      fetchOptionsMemo={getPipedriveOganizationsFinal}
      textfieldProps={{
        label: t("general.keywords.fields.clientAccount"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${x.name}${x.registrationCode ? ` - ${x.registrationCode}` : ""}`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
