import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { ActivityDropdownDTO } from "../../../../../../../DTOs/activity/ActivityDropdownDTO";
import { ActivityBackendFiltersDTO } from "../../../../../../../DTOs/activity/filters/ActivityBackendFiltersDTO";
import { getActivitiesDropdown } from "../../../../../../../../../services/activities/activities.service";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    ActivityDropdownDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: ActivityBackendFiltersDTO;
}

export const ActivitiesWithNoPermissionAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const internalGetSegments = useCallback(async () => {
    if (!filters?.idProject) return [];
    return getActivitiesDropdown({
      filters,
    });
  }, [filters?.idProject, filters?.yearmonth]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "activities.errors.data.fetch.failedToFetchActivity"
      )}
      fetchOptionsMemo={internalGetSegments}
      textfieldProps={{
        label: t("activities.keywords.general.activities"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${x.description} - ${x.scheduledDate.toLocaleDateString()}`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
