import * as yup from "yup";
import { ProjectsFiltersDTO } from "../../../shared/specific/DTOs/project/filters/ProjectsFiltersDTO";
import {
  PaginatedProjectDropdownDTO,
  schemaPaginatedProjectDropdownDTO,
} from "../../../shared/specific/DTOs/project/PaginatedProjectDropdownDTO";
import {
  PaginatedProjectDTO,
  schemaPaginatedProjectDTO,
} from "../../../shared/specific/DTOs/project/PaginatedProjectDTO";
import {
  PaginatedProjectOnlyDTO,
  schemaPaginatedProjectOnlyDTO,
} from "../../../shared/specific/DTOs/project/PaginatedProjectOnlyDTO";
import { PlanningFinishDTO } from "../../../shared/specific/DTOs/project/planningValidation/PlanningFinishDTO";
import { PlanningReopenDTO } from "../../../shared/specific/DTOs/project/planningValidation/PlanningReopenDTO";
import { PlanningSaveDTO } from "../../../shared/specific/DTOs/project/planningValidation/PlanningSaveDTO";
import {
  ProjectPlanningFinishingValidationDTO,
  schemaProjectPlanningFinishingValidationDTO,
} from "../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningFinishingValidationDTO";
import {
  ProjectPlanningSavingValidationDTO,
  schemaProjectPlanningSavingValidationDTO,
} from "../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningSavingValidationDTO";
import { ProjectCreateDTO } from "../../../shared/specific/DTOs/project/ProjectCreateDTO";
import {
  ProjectDTO,
  schemaProjectDTO,
} from "../../../shared/specific/DTOs/project/ProjectDTO";
import {
  ProjectOnlyDTO,
  schemaProjectOnlyDTO,
} from "../../../shared/specific/DTOs/project/ProjectOnlyDTO";
import { ProjectPlanningUpdateDTO } from "../../../shared/specific/DTOs/project/ProjectPlanningUpdateDTO";
import { ProjectUpdateDTO } from "../../../shared/specific/DTOs/project/ProjectUpdateDTO";
import {
  ProjectWithRelatedInfoDTO,
  schemaProjectWithRelatedInfoDTO,
} from "../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";
import {
  ProjectSummaryCompleteDTO,
  schemaProjectSummaryCompleteDTO,
} from "../../../shared/specific/DTOs/project/summary/ProjectSummaryCompleteDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import {
  schemaUserCollaboratorDTO,
  UserCollaboratorDTO,
} from "../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { riseApi } from "./connection";
import {
  PaginatedPipedriveDealDTO,
  schemaPaginatedPipedriveDealDTO,
} from "../../../shared/specific/DTOs/pipedrive/PaginatedPipedriveDealDTO";
import {
  PipedriveDealDTO,
  schemaPipedriveDealDTO,
} from "../../../shared/specific/DTOs/pipedrive/PipedriveDealDTO";
import {
  PipedriveDealCostCenterDTO,
  schemaPipedriveDealCostCenterDTO,
} from "../../../shared/specific/DTOs/pipedrive/PipedriveDealCostCenterDTO";
import { PipedriveDealPlanningProjectDTO } from "../../../shared/specific/DTOs/pipedrive/PipedriveDealProjectPlanningDTO";
import { ForceFnishProjectNoMatterWhatDTO } from "../../../shared/specific/DTOs/project/ForceFnishProjectNoMatterWhatDTO";
import { PipedriveDealFiltersDTO } from "../../../shared/specific/DTOs/pipedrive/filters/PipedriveDealFiltersDTO";
import {
  PipedriveServiceLineDTO,
  schemaPipedriveServiceLineDTO,
} from "../../../shared/specific/DTOs/pipedrive/PipedriveServiceLineDTO";
import {
  KeywordDTO,
  schemaKeywordDTO,
} from "../../../shared/specific/DTOs/Keyword/KeywordDTO";
import {
  ProjectAdditionalInformationDTO,
  schemaProjectAdditionalInformationDTO,
} from "../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { ProjectUpdateAdditionalInformationDTO } from "../../../shared/specific/DTOs/project/ProjectUpdateAdditionalInformationDTO";
import {
  ProjectDropdownDTO,
  schemaProjectDropdownDTO,
} from "../../../shared/specific/DTOs/project/ProjectDropdownDTO";

export const getProjects = async ({
  filters,
  pagination,
}: {
  filters?: ProjectsFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedProjectDTO> => {
  const queryParams = new URLSearchParams(JSON.stringify(pagination));
  const { data: projects } = await riseApi.post(
    `/projects/PaginationProjects?${queryParams}`,
    {
      pagination,
      filters,
    }
  );

  return schemaPaginatedProjectDTO.validateSync(projects);
};

export const getDropdownProjects = async ({
  filters,
  pagination,
}: {
  filters?: ProjectsFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedProjectDropdownDTO> => {
  if (filters?.statusIncludeOnly) {
    const projectsQueryString = `?${filters?.statusIncludeOnly
      .map((n, index) => `statusIncludeOnly[${index}]=${n}`)
      .join("&")}`;
    const { data: dropdownProjects } = await riseApi.get(
      `/projects/dropdown/${projectsQueryString}`,
      {
        params: {
          ...pagination,
          ...filters,
        },
      }
    );

    return schemaPaginatedProjectDropdownDTO.validateSync(dropdownProjects);
  }

  const { data: dropdownProjects } = await riseApi.get("/projects/dropdown", {
    params: {
      ...pagination,
      ...filters,
    },
  });

  return schemaPaginatedProjectDropdownDTO.validateSync(dropdownProjects);
};

export const getDropdownProjectsCache = async ({
  filters,
}: {
  filters?: ProjectsFiltersDTO;
} = {}): Promise<ProjectDropdownDTO[]> => {
  if (filters?.statusIncludeOnly) {
    const projectsQueryString = `?${filters?.statusIncludeOnly
      .map((n, index) => `statusIncludeOnly[${index}]=${n}`)
      .join("&")}`;
    const { data: dropdownProjects } = await riseApi.get(
      `/projects/dropdown/cache/${projectsQueryString}`,
      {
        params: {
          ...filters,
        },
      }
    );

    return yup
      .array(schemaProjectDropdownDTO)
      .defined()
      .validateSync(dropdownProjects);
  }

  const { data: dropdownProjects } = await riseApi.get(
    "/projects/dropdown/cache",
    {
      params: {
        ...filters,
      },
    }
  );

  return yup
    .array(schemaProjectDropdownDTO)
    .defined()
    .validateSync(dropdownProjects);
};

export const getProjectsOnly = async ({
  filters,
  pagination,
}: {
  filters?: ProjectsFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedProjectOnlyDTO> => {
  const projectsQueryString = filters?.statusIncludeOnly
    ? `?${filters?.statusIncludeOnly
        .map((n, index) => `statusIncludeOnly[${index}]=${n}`)
        .join("&")}`
    : "";
  const { data: projectsOnly } = await riseApi.get(
    `/projects/only/${projectsQueryString}`,
    {
      params: {
        ...pagination,
        ...filters,
      },
    }
  );

  return schemaPaginatedProjectOnlyDTO.validateSync(projectsOnly);
};

export const getProjectById = async (
  id: number
): Promise<ProjectDTO | null> => {
  const { data: project } = await riseApi.get(`/projects/${id}`);

  return schemaProjectDTO.nullable().validateSync(project);
};

export const getProjectByIdWithRelatedInfo = async (
  id: number
): Promise<ProjectWithRelatedInfoDTO | null> => {
  const { data: project } = await riseApi.get(
    `/projects/${id}/withRelatedInfo`
  );

  return schemaProjectWithRelatedInfoDTO.nullable().validateSync(project);
};

export const getProjectOnlyById = async (
  id: number
): Promise<ProjectOnlyDTO | null> => {
  const { data: projectOnly } = await riseApi.get(`/projects/only/${id}`);

  return schemaProjectOnlyDTO.nullable().validateSync(projectOnly);
};

export const getProjectManagers = async (): Promise<UserCollaboratorDTO[]> => {
  const { data: projectManagers } = await riseApi.get("projects/managers");

  return yup
    .array(schemaUserCollaboratorDTO)
    .defined()
    .validateSync(projectManagers);
};

export const createProject = async (
  projectCreationDTO: ProjectCreateDTO
): Promise<ProjectOnlyDTO> => {
  const { data: createdProject } = await riseApi.post(
    "/projects/",
    projectCreationDTO
  );

  return schemaProjectOnlyDTO.validateSync(createdProject);
};

export const updateProject = async (projectUpdateDTO: ProjectUpdateDTO) => {
  await riseApi.put("/projects/", projectUpdateDTO);
};

export const updateProjectPlanning = async (
  projectPlanningUpdateDTO: ProjectPlanningUpdateDTO
) => {
  await riseApi.put("/projects/planning", projectPlanningUpdateDTO);
};

export const getProjectSummary = async (
  idProject: number
): Promise<ProjectSummaryCompleteDTO | null> => {
  const { data: projectSummary } = await riseApi.get(
    `Projects/${idProject}/Summary`
  );

  return schemaProjectSummaryCompleteDTO
    .nullable()
    .validateSync(projectSummary);
};

export const validatePlanningSaving = async (
  idProject: number
): Promise<ProjectPlanningSavingValidationDTO | null> => {
  const { data: planningValidation } = await riseApi.get(
    `Projects/${idProject}/ValidatePlanning/Saving`
  );

  return schemaProjectPlanningSavingValidationDTO
    .nullable()
    .validateSync(planningValidation);
};

export const validatePlanningFinishing = async (
  idProject: number
): Promise<ProjectPlanningFinishingValidationDTO | null> => {
  const { data: planningValidation } = await riseApi.get(
    `Projects/${idProject}/ValidatePlanning/Finishing`
  );

  return schemaProjectPlanningFinishingValidationDTO
    .nullable()
    .validateSync(planningValidation);
};

export const savePlanning = async (planningSaveDTO: PlanningSaveDTO) => {
  await riseApi.post(`Projects/SavePlanning`, planningSaveDTO);
};

export const finishPlanning = async (planningFinishDTO: PlanningFinishDTO) => {
  await riseApi.post(`Projects/FinishPlanning`, planningFinishDTO);
};

export const reopenProject = async (planningReopenDTO: PlanningReopenDTO) => {
  await riseApi.put("Projects/Reopen", planningReopenDTO);
};

export const UpdateProjectAdditionalInformation = async (
  additionalInformation: ProjectUpdateAdditionalInformationDTO
) => {
  await riseApi.put(
    "Projects/ProjectAdditionalInformation",
    additionalInformation
  );
};

export const getPipedriveDeals = async ({
  filters,
  pagination,
}: {
  filters?: PipedriveDealFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedPipedriveDealDTO> => {
  const { data: pipedriveDeals } = await riseApi.get("/Pipedrive/", {
    params: {
      ...filters,
      ...pagination,
    },
  });

  return schemaPaginatedPipedriveDealDTO.validateSync(pipedriveDeals);
};

export const getPipedriveDealById = async (
  id: number
): Promise<PipedriveDealDTO | null> => {
  const { data: pipedriveDeal } = await riseApi.get(`/Pipedrive/${id}`);

  return schemaPipedriveDealDTO.nullable().validateSync(pipedriveDeal);
};

export const deactivateDealOnRise = async (id: number): Promise<void> => {
  await riseApi.get(`/Pipedrive/deactivate/${id}`);
};

export const validateDealAdditive = async (
  id: number,
  additive: string
): Promise<void> => {
  await riseApi.get(`/Pipedrive/validateAdditive/${id}/${additive}`);
};

export const getPipedriveDealCostCenterByRiseId = async (
  riseId: number
): Promise<PipedriveDealCostCenterDTO | null> => {
  const { data: pipedriveDeal } = await riseApi.get(`/Pipedrive/pep/${riseId}`);

  return schemaPipedriveDealCostCenterDTO
    .nullable()
    .validateSync(pipedriveDeal);
};

export const getPipedriveDealPlanningProjectByRiseId = async (
  riseId: number
): Promise<PipedriveDealPlanningProjectDTO | null> => {
  const { data: pipedriveDeal } = await riseApi.get(
    `/Pipedrive/planning/${riseId}`
  );

  return pipedriveDeal;
};

export const getPipedriveServiceLinesDropdown = async (): Promise<
  PipedriveServiceLineDTO[]
> => {
  const { data: serviceLines } = await riseApi.get(
    "Pipedrive/ServiceLinesDropdown"
  );

  return yup
    .array(schemaPipedriveServiceLineDTO)
    .defined()
    .validateSync(serviceLines);
};

export const getKeywordsDropdown = async (): Promise<KeywordDTO[]> => {
  const { data: keywords } = await riseApi.get("Projects/KeywordsDropdown");

  return yup.array(schemaKeywordDTO).defined().validateSync(keywords);
};

export const getProjectAdditionalInformation = async (
  idProject: number
): Promise<ProjectAdditionalInformationDTO> => {
  const { data: addinfos } = await riseApi.get(
    `Projects/ProjectAdditionalInformation/${idProject}`
  );

  return schemaProjectAdditionalInformationDTO.defined().validateSync(addinfos);
};

export const forceFnishProjectNoMatterWhatDTO = async (
  project: ForceFnishProjectNoMatterWhatDTO
) => {
  await riseApi.post("/projects/ForceFnishProjectNoMatterWhat", project);
};
