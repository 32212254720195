import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { YearMonth } from "../../../../../../classes/data/date/YearMonth";
import { Week } from "../../../../../../enums/data/dates/Week.enum";
import { weekToTranslationCode } from "../../../../../../maps/enums/data/dates/weekToTranslationCode.maps";
import {
  StyledCalendarGridBody,
  StyledCalendarGridHeader,
} from "../../shared/styles/grid.styles";
import { CalendarCellContent } from "../../shared/types/props.types";
import { CalendarCell } from "./CalendarCell";

interface OwnProps {
  yearMonth: YearMonth;
  CalendarCellContent: CalendarCellContent;
}

export const useTableData = ({ yearMonth, CalendarCellContent }: OwnProps) => {
  const { t } = useTranslation();

  const headCells = useMemo(() => {
    const sortedWeekDays = [
      Week.Sunday,
      Week.Monday,
      Week.Tuesday,
      Week.Wednesday,
      Week.Thursday,
      Week.Friday,
      Week.Saturday,
    ];

    return sortedWeekDays.map((weekDay) => (
      <StyledCalendarGridHeader key={`head-${weekDay}`}>
        {t(weekToTranslationCode[weekDay])}
      </StyledCalendarGridHeader>
    ));
  }, [t]);

  const getWeekDaysBeforeFirstDayOfMonth = () => {
    const firstWeekDayMonth = yearMonth.toDate().getDay();

    return firstWeekDayMonth;
  };

  const cells = useMemo(() => {
    const daysInMonth = yearMonth.toDateEndOfMonth().getDate();

    const cells: JSX.Element[] = [];

    const weekDaysBefore = getWeekDaysBeforeFirstDayOfMonth();
    for (let i = 0; i < weekDaysBefore; i++) {
      cells.push(<StyledCalendarGridBody key={`blank-${i}`} />);
    }

    for (let day = 0; day < daysInMonth; day++)
      cells.push(
        <CalendarCell
          key={day + 1}
          day={day + 1}
          yearMonth={yearMonth}
          CalendarCellContent={CalendarCellContent}
        />
      );

    return cells;
  }, [t, yearMonth, CalendarCellContent]);

  return { headCells, cells };
};
