import { useContext } from "react";
import { MaskedTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { TimesheetContext } from "../../../contexts/TimesheetContext/TimesheetContextProvider";

interface OwnProps {
  name: string;
}

export const CalendarWeekCell = ({ name }: OwnProps) => {
  const { canEditTimesheet } = useContext(TimesheetContext);

  return (
    <MaskedTextFieldFormik
      name={name}
      overwrite="shift"
      mask="00:00"
      textFieldProps={{
        variant: "standard",
        disabled: !canEditTimesheet,
      }}
    />
  );
};
