import { useTranslation } from "react-i18next";
import { getNowBrazilianDate } from "../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { DatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AllocationTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/AllocationTypeAutocompleteFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { FormMode, FormValues } from "../../../types/form.types";
import { StyledFormContainer } from "./indes.styles";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData"> & {
  mode: FormMode;
};

export const CommonSegmentCorporateForm = ({
  formikProps: { isSubmitting },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledFormContainer>
      <CostCentersSearchAutocompleteFormik
        name="costCenter"
        autocompleteProps={{ disabled: isSubmitting }}
      />
      <TextFieldFormik
        name="namePt"
        label={t("corporateSegment.keywords.general.namePt")}
        inputProps={{ maxLength: 700 }}
        required
        disabled={isSubmitting}
      />
      <TextFieldFormik
        name="nameUs"
        label={t("corporateSegment.keywords.general.nameEn")}
        inputProps={{ maxLength: 700 }}
        required
        disabled={isSubmitting}
      />
      <AllocationTypeAutocompleteFormik
        name="allocationType"
        autocompleteProps={{
          disabled: isSubmitting,
        }}
      />
      {mode === "editing" && (
        <DatePickerFormik
          name="deactivatedDate"
          datePickerProps={{
            label: t("contractRole.keywords.fields.deactivatedDate"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            maxDate: getNowBrazilianDate(),
            disabled: isSubmitting,
          }}
        />
      )}
    </StyledFormContainer>
  );
};
