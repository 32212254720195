import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { useTableData } from "./hooks/useTableData";
import { useEffectAfterRenders } from "../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useCreationForm } from "./hooks/useCreationForm";

export const TimesheetRecurrence = () => {
  const { i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { headCells, onPageChange, editingModal, deletionModal } = useTableData(
    {
      reloadTablePage,
    }
  );

  const { CreationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: CreationButtonContainer,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: false,
          externalPagination: {
            loadSinglePageContent: onPageChange,
          },
        }}
      />
      {creationModal}
      {editingModal}
      {deletionModal}
    </>
  );
};
