import { TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CorporateSegmentsDTO } from "../../../../../../../DTOs/corporateSegment/CorporateSegmentsDTO";
import { CostCenterDropdownDTO } from "../../../../../../../DTOs/costCenter/CostCenterDropdownDTO";
import { EmployeeAllocationType } from "../../../../../../../enums/allocations/EmployeeAllocationType.enums";
import { AsyncFastAutocompleteFormikProps } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { getDropdownCorporateSegmentsCache } from "../../../../../../../../../services/corporateSegments/corporateSegments.service";
import { getDropdownCostCentersCache } from "../../../../../../../../../services/costCenter/costCenter.service";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../maps/allocations/EmployeeAllocationType.maps";
import { SearchAutocompleteFormik } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";

export interface TimesheetAppointmentType {
  corporateSegment: CorporateSegmentsDTO | null;
  costCenter: CostCenterDropdownDTO | null;
  allocationType: EmployeeAllocationType | null;
  id: number;
}

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    TimesheetAppointmentType,
    DisableClearable
  >["autocompleteProps"];
  shouldReplaceValueOnMount?: boolean;
  idSubsidiaryLastHistory?: number | null;
}

export const AppointmentTypeAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnMount = true,
  idSubsidiaryLastHistory = null,
}: OwnProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  const getItems = useCallback(async (name: string) => {
    const corporateSegments = await getDropdownCorporateSegmentsCache({
      filters: {
        onlyActivated: true,
        showingOnTimesheet: true,
        subsidiaryId: idSubsidiaryLastHistory ?? undefined,
      },
    });
    const costCenters = await getDropdownCostCentersCache({
      filters: {
        idSubsidiary: idSubsidiaryLastHistory ?? undefined,
        IsBlockCorporateAllocationCostCenters: true,
      },
    });

    const mappedCorporateSegments: TimesheetAppointmentType[] =
      corporateSegments.map((x) => ({
        corporateSegment: x,
        costCenter: null,
        id: x.id,
        allocationType: null,
      }));

    const mappedCostCenters: TimesheetAppointmentType[] = costCenters.map(
      (x) => ({
        corporateSegment: null,
        costCenter: x,
        id: x.id,
        allocationType: null,
      })
    );

    const otherAllocationsType = [
      EmployeeAllocationType.Vacation,
      EmployeeAllocationType.Leave,
      EmployeeAllocationType.MonthlyOnboarding,
      EmployeeAllocationType.PerformanceEvaluation,
      EmployeeAllocationType.CorporateMeeting,
    ]
      .map((x) => ({
        project: null,
        corporateSegment: null,
        costCenter: null,
        id: 0,
        allocationType: x,
      }))
      .filter((x) =>
        t(employeeAllocationTypeToTranslationCodeMap[x.allocationType])!
          .toLowerCase()
          .includes(name.toLowerCase())
      );

    return [
      ...mappedCorporateSegments,
      ...mappedCostCenters,
      ...otherAllocationsType,
    ].map((x, i) => ({
      ...x,
      id: i,
    }));
  }, []);

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t("general.errors.data.fetch.failedToFetchData")}
      fetchOptionsMemo={getItems}
      textfieldProps={{
        placeholder: t("timesheets.keywords.fields.appointment"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => {
          if (x.corporateSegment) {
            const translatedName =
              x.corporateSegment.corporateSegmentTranslation.find(
                (translation) => translation.idLanguage === i18n.language
              )?.name || x.corporateSegment.name;

            return x.corporateSegment.costCenter
              ? `${x.corporateSegment.costCenter?.code} - ${translatedName}`
              : translatedName;
          }

          if (x.costCenter) {
            return `${x.costCenter.code} - ${x.costCenter.name}`;
          }

          if (x.allocationType) {
            return t(
              employeeAllocationTypeToTranslationCodeMap[x.allocationType]
            );
          }

          return "";
        },
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
