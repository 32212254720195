import { useTranslation } from "react-i18next";
import { Button, Tooltip, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { TipPopoverButton } from "../../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";
import { CalendarWeekCell } from "../../../../../shared/react/components/content/CalendarCell/WeekCell";
import { MoreTimeIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/MoreTimeIconButton";
import { CreateFormValues } from "../../../hooks/useCreationForm/shared/types/data/form/values.types";
import { EditingFormValues } from "../../../hooks/useEditingForm/types/values.types";
import { StylesContainer } from "./index.styles";

interface OwnProps {
  field: string;
  day: number;
}

export const ExtraTime = ({ field, day }: OwnProps) => {
  const { t } = useTranslation();

  const { values: createValues } = useFormikContext<CreateFormValues>();
  const { values: editValues } = useFormikContext<EditingFormValues>();
  const minuteValue = useMemo(() => {
    return (
      createValues.days.find((x) => x.day === day) ??
      editValues.days.find((x) => x.day === day)
    );
  }, [createValues.days, editValues.days, day]);

  return (
    <TipPopoverButton
      button={
        <>
          {minuteValue && minuteValue.extraTime !== "00:00" ? (
            <Tooltip title={t("timesheets.keywords.general.extraTime")}>
              <Button
                sx={{
                  padding: "1px",
                }}
                size="small"
                color="error"
              >
                {minuteValue.extraTime}
              </Button>
            </Tooltip>
          ) : (
            <MoreTimeIconButton
              size="small"
              tooltipText={t("timesheets.keywords.general.extraTime")}
            />
          )}
        </>
      }
      content={
        <div>
          <Typography variant="h6">
            {t("timesheets.keywords.general.extraTime")}
          </Typography>
          <StylesContainer>
            <CalendarWeekCell name={field} />
          </StylesContainer>
        </div>
      }
    />
  );
};
