import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { getLoggedUserTimesheetRecurrence } from "../../../../../../../services/timesheetRecurrence/timesheetRecurrence.service";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { useEditingForm } from "./useEditingForm";
import { getTimesheetRecurrenceLineIdentification } from "../shared/general/helpers";
import { weekAbreviationToTranslationCode } from "../../../../../../../shared/common/maps/enums/data/dates/weekToTranslationCode.maps";
import { formatMinutesToHourMinute } from "../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { useDeletionForm } from "./useDeletionForm";

interface OwProps {
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("timesheetRecurrence.keywords.fields.recurrence"),
      t("timesheetRecurrence.keywords.fields.periodicity"),
      t("timesheetRecurrence.keywords.fields.durationPerDay"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      try {
        const recurrences = await getLoggedUserTimesheetRecurrence();

        const rows: ExternalEnhancedRow[] = recurrences.map(
          (recurrence, i): ExternalEnhancedRow => {
            const days = recurrence.recurrencesDay.map((day) =>
              t(weekAbreviationToTranslationCode[day.weekDay])
            );
            return {
              id: i,
              cells: [
                getTimesheetRecurrenceLineIdentification(recurrence, t),
                days.join(", "),
                formatMinutesToHourMinute(recurrence.minutes),
                {
                  CellRenderer: () => (
                    <div>
                      <EditingButtonContainer {...recurrence} />
                      <DeleteButtonContainer {...recurrence} />
                    </div>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            };
          }
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [t, i18n]);

  return {
    headCells,
    onPageChange,
    editingModal,
    deletionModal,
  };
};
