import { styled } from "@mui/material";

export const StylesContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const StyledDayContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: center;
`;
