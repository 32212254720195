import * as yup from "yup";
import { schemaWeek, Week } from "../../../common/enums/data/dates/Week.enum";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CorporateSegmentsDTO,
  schemaCorporateSegmentsDTO,
} from "../corporateSegment/CorporateSegmentsDTO";
import {
  CostCenterDropdownDTO,
  schemaCostCenterDropdownDTO,
} from "../costCenter/CostCenterDropdownDTO";
import {
  PipedriveOrganizationDropdownDTO,
  schemaPipedriveOrganizationDropdownDTO,
} from "../pipedrive/PipedriveOrganizationDropdownDTO";
import {
  ProjectDropdownDTO,
  schemaProjectDropdownDTO,
} from "../project/ProjectDropdownDTO";

export interface TimesheetRecurrenceDTO {
  project: ProjectDropdownDTO | null;
  costCenter: CostCenterDropdownDTO | null;
  corporateSegment: CorporateSegmentsDTO | null;
  pipedriveOrganization: PipedriveOrganizationDropdownDTO | null;
  allocationType: EmployeeAllocationType;
  minutes: number;
  recurrencesDay: RecurrenceDayDTO[];
}

export interface RecurrenceDayDTO {
  id: number;
  weekDay: Week;
}

export const schemaRecurrencDayDTO = yup.object({
  id: yup.number().integer().defined(),
  weekDay: schemaWeek.defined(),
});

export const schemaTimesheetRecurrenceDTO = yup.object({
  project: schemaProjectDropdownDTO.nullable().defined(),
  costCenter: schemaCostCenterDropdownDTO.nullable().defined(),
  corporateSegment: schemaCorporateSegmentsDTO.nullable().defined(),
  pipedriveOrganization: schemaPipedriveOrganizationDropdownDTO
    .nullable()
    .defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  minutes: yup.number().integer().defined(),
  recurrencesDay: yup.array(schemaRecurrencDayDTO).defined(),
});
