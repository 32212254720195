import { useContext, useMemo } from "react";
import { FormikProps } from "formik";
import { getDaysInMonth } from "date-fns";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedRow,
  ExternalEnhancedRowCells,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import {
  getWeekDays,
  getWeekNumber,
} from "../../../shared/helpers/data/timesheet/general.helpers";
import { TimesheetWeekFormValues } from "../shared/types/form.values";
import {
  isValidStringMinute,
  parseHourStringToMinutes,
} from "../../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { formatMinutesToHourMinute } from "../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { StyledTotalTableCell } from "../shared/common.styles";
import { TotalTableRow } from "../index.styles";

interface OwProps {
  formikProps: FormikProps<TimesheetWeekFormValues>;
}

export const useTotalTableData = ({ formikProps }: OwProps) => {
  const { t } = useTranslation();

  const { values } = formikProps;

  const { dateFilter } = useContext(TimesheetContext);

  const weekNumber = getWeekNumber(dateFilter);
  const monthsDays = getDaysInMonth(dateFilter);

  const sumMonth = useMemo(() => {
    return new Array(monthsDays).fill(0).map((_, i) => {
      const sumProjects = lodash.sum(
        values.projectTimesheets.map((x) =>
          isValidStringMinute(x.days[i].time)
            ? parseHourStringToMinutes(x.days[i].time)
            : 0
        ) ?? 0
      );

      const sumProjectsExtraTime = lodash.sum(
        values.projectTimesheets.map((x) =>
          isValidStringMinute(x.days[i].extraTime)
            ? parseHourStringToMinutes(x.days[i].extraTime)
            : 0
        ) ?? 0
      );

      const sumCostCenters = lodash.sum(
        values.costCenterTimesheets.map((x) =>
          isValidStringMinute(x.days[i].time)
            ? parseHourStringToMinutes(x.days[i].time)
            : 0
        ) ?? 0
      );

      const sumCostCentersExtraTime = lodash.sum(
        values.costCenterTimesheets.map((x) =>
          isValidStringMinute(x.days[i].extraTime)
            ? parseHourStringToMinutes(x.days[i].extraTime)
            : 0
        ) ?? 0
      );

      const sumNewProjects = lodash.sum(
        values.projectCreateTimesheets.map((x) =>
          isValidStringMinute(x.days[i])
            ? parseHourStringToMinutes(x.days[i])
            : 0
        ) ?? 0
      );

      const sumNewProjectsExtraTime = lodash.sum(
        values.projectCreateTimesheets.map((x) =>
          isValidStringMinute(x.extraTime[i])
            ? parseHourStringToMinutes(x.extraTime[i])
            : 0
        ) ?? 0
      );

      const sumNewCostCenters = lodash.sum(
        values.costCenterCreateTimesheets.map((x) =>
          isValidStringMinute(x.days[i])
            ? parseHourStringToMinutes(x.days[i])
            : 0
        ) ?? 0
      );

      const sumNewCostCentersExtraTime = lodash.sum(
        values.costCenterCreateTimesheets.map((x) =>
          isValidStringMinute(x.extraTime[i])
            ? parseHourStringToMinutes(x.extraTime[i])
            : 0
        ) ?? 0
      );

      return (
        sumProjects +
        sumCostCenters +
        sumNewProjects +
        sumNewCostCenters +
        sumProjectsExtraTime +
        sumCostCentersExtraTime +
        sumNewProjectsExtraTime +
        sumNewCostCentersExtraTime
      );
    });
  }, [values]);

  const rows: ExternalEnhancedRow[] = useMemo(() => {
    const lines = [t("timesheets.keywords.fields.appointedHours")];
    const currentAppointments =
      lines.map((type, i): ExternalEnhancedRow => {
        const cells: ExternalEnhancedRowCells = [
          {
            CellRenderer: () => (
              <StyledTotalTableCell>{type}</StyledTotalTableCell>
            ),
          },
        ];

        const weekDays = getWeekDays(dateFilter);
        weekDays.forEach((day) => {
          if (day.date.getMonth() === dateFilter.getMonth()) {
            const monthValue = sumMonth[day.date.getDate() - 1];
            cells.push({
              CellRenderer: () => (
                <StyledTotalTableCell>
                  {formatMinutesToHourMinute(monthValue)}
                </StyledTotalTableCell>
              ),
            });
          } else {
            cells.push("");
          }
        });

        cells.push("");

        return {
          id: i,
          cells,
          CustomTableRow: TotalTableRow,
        };
      }) ?? [];

    return currentAppointments.map((x, i) => ({
      ...x,
      id: i,
    }));
  }, [weekNumber, dateFilter, values, t]);

  return {
    rows,
  };
};
