import { useContext } from "react";
import { CalendarGrid } from "../../../../../../../../../../shared/common/react/components/table/CalendarGrid";
import { CalendarCell } from "./CalendarCell";
import { TimesheetContext } from "../../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";

export const TimesheetCalendarFormWithinContext = () => {
  const { dateFilter } = useContext(TimesheetContext);
  const yearMonth = YearMonth.createFromDate(dateFilter);

  return (
    <CalendarGrid yearMonth={yearMonth} CalendarCellContent={CalendarCell} />
  );
};
