import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useActionButtonDialog } from "../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { TimesheetRecurrenceDTO } from "../../../../../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceDTO";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { deleteTimesheetRecurence } from "../../../../../../../services/timesheetRecurrence/timesheetRecurrence.service";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { getTimesheetRecurrenceLineIdentification } from "../shared/general/helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<TimesheetRecurrenceDTO, TimesheetRecurrenceDTO>({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData }) => (
        <div>
          {t("timesheetRecurrence.keywords.actions.delete", {
            recurrence: internalModalData
              ? getTimesheetRecurrenceLineIdentification(internalModalData, t)
              : "",
          })}
        </div>
      ),
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps,
        setInternalModalData,
      }) => setInternalModalData(contentButtonContentProps),
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: timesheetRecurrence,
      }) => {
        if (!timesheetRecurrence)
          throw new Error(
            "timesheet recurrence data cannot be null on confirm."
          );

        try {
          await deleteTimesheetRecurence(
            timesheetRecurrence.recurrencesDay.map((tr) => tr.id)
          );
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            <Trans
              i18nKey="timesheetRecurrence.errors.data.delete.failedToDelete"
              values=""
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
