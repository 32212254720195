import { useCallback, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FieldArrayRenderProps, FormikProps } from "formik";
import { startOfMonth } from "date-fns";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedRowCells,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import {
  getDaysInMonth,
  getTimesheetLineIdentification,
  getWeekDays,
} from "../../../shared/helpers/data/timesheet/general.helpers";
import { TimesheetHeaderDay } from "../../../shared/react/components/content/TimesheetHeaderDay";
import { CalendarWeekCell } from "../../../shared/react/components/content/CalendarCell/WeekCell";
import { TimesheetWeekFormValues } from "../shared/types/form.values";
import { AddIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import { DeleteIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useDeletionForm } from "../../../shared/hooks/useDeletionForm";
import { EmployeeAllocationType } from "../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { AttachPipedriveOrganization } from "../shared/components/AttachPipedriveOrganization";
import {
  StyledExtraTimeObservationContainer,
  StyledExtraTimeText,
  StyledIdentificationAddCell,
  StyledIdentificationAddCellContainer,
  StyledIdentificationCell,
} from "../shared/common.styles";
import { AppointmentTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/timesheet/AppointmentTypeAutocompleteFormik";
import { Observation } from "../shared/components/Observation";
import { ExtraTime } from "../shared/components/ExtraTime";

interface OwProps {
  formikProps: FormikProps<TimesheetWeekFormValues>;
  arrayCostCenterCreateFormikProps: FieldArrayRenderProps;
  reloadTablePage: () => void;
}

export const useTableDataCostCenters = ({
  formikProps,
  arrayCostCenterCreateFormikProps,
  reloadTablePage,
}: OwProps) => {
  const { t, i18n } = useTranslation();
  const { setFieldValue, values } = formikProps;
  const { remove } = arrayCostCenterCreateFormikProps;

  const newItemRef = useRef<HTMLInputElement | null>(null);

  const { dateFilter, canEditTimesheet, timesheetData } =
    useContext(TimesheetContext);

  const weekDays = getWeekDays(dateFilter);

  const { DeletionButtonContainer, deletionModal } = useDeletionForm();

  const handleDeleteNewAppointment = (index: number) => remove(index);

  const isPastMonth = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    return startOfCurrentMonth > startDateFilter;
  }, [dateFilter]);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        HeadRenderer: () => (
          <StyledIdentificationCell>
            {!isPastMonth && (
              <AddIconButton
                onClick={() => {
                  setFieldValue("costCenterCreateTimesheets", [
                    {
                      appointment: undefined,
                      days: new Array(getDaysInMonth(dateFilter)).fill("00:00"),
                      extraTime: new Array(getDaysInMonth(dateFilter)).fill(
                        "00:00"
                      ),
                      observation: "",
                    },
                    ...values.costCenterCreateTimesheets,
                  ]);
                  setTimeout(() => {
                    newItemRef.current?.focus();
                    newItemRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }, 0);
                }}
              />
            )}
            <span>{t("timesheets.keywords.general.nonProjectTime")}</span>
          </StyledIdentificationCell>
        ),
        value: t("timesheets.keywords.general.nonProjectTime"),
        canSort: false,
      },
    ];

    weekDays.forEach((day) => {
      if (day.date.getMonth() !== dateFilter.getMonth()) {
        headCells.push({
          value: "",
          width: 70,
          canSort: false,
        });
      } else {
        headCells.push({
          HeadRenderer: () => <TimesheetHeaderDay {...day} />,
          value: "",
          width: 70,
          canSort: false,
        });
      }
    });

    headCells.push({
      value: "",
      width: 70,
    });

    return headCells;
  }, [t, dateFilter, isPastMonth, values.costCenterCreateTimesheets.length]);

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      const currentAppointments =
        values.costCenterTimesheets?.map(
          (timesheet, indexTimesheet): ExternalEnhancedRow => {
            const weekDays = getWeekDays(dateFilter);
            const cells: ExternalEnhancedRowCells = [
              {
                CellRenderer: () => (
                  <StyledIdentificationAddCellContainer>
                    <StyledIdentificationAddCell>
                      <span>
                        {getTimesheetLineIdentification(
                          timesheet.timesheet,
                          t,
                          i18n
                        )}{" "}
                      </span>
                      {timesheet.timesheet.allocationType ===
                        EmployeeAllocationType.Support && (
                        <AttachPipedriveOrganization
                          name={`costCenterTimesheets[${indexTimesheet}].pipedriveOrganization`}
                          textButton={
                            timesheet.pipedriveOrganization?.name ??
                            t(
                              "timesheets.actions.general.associeteClientAccount"
                            )
                          }
                          reloadTablePage={reloadTablePage}
                          value={timesheet.pipedriveOrganization}
                        />
                      )}
                    </StyledIdentificationAddCell>
                    <StyledExtraTimeObservationContainer>
                      <Observation
                        name={`costCenterTimesheets[${indexTimesheet}].observation`}
                      />
                      <ExtraTime
                        weekDays={weekDays}
                        reloadTablePage={reloadTablePage}
                        field={`costCenterTimesheets[${indexTimesheet}].days[{{ day }}].extraTime`}
                      />
                    </StyledExtraTimeObservationContainer>
                  </StyledIdentificationAddCellContainer>
                ),
              },
            ];

            weekDays.forEach((day) => {
              if (day.date.getMonth() === dateFilter.getMonth()) {
                cells.push({
                  CellRenderer: () => (
                    <div>
                      <CalendarWeekCell
                        name={`costCenterTimesheets[${indexTimesheet}].days[${day.date.getDate() - 1}].time`}
                      />
                      <div>
                        <StyledExtraTimeText
                          style={{
                            color: "red",
                          }}
                        >
                          {values.costCenterTimesheets[indexTimesheet]?.days[
                            day.date.getDate() - 1
                          ]?.extraTime ?? "00:00"}
                        </StyledExtraTimeText>
                      </div>
                    </div>
                  ),
                });
              } else {
                cells.push("");
              }
            });

            cells.push({
              CellRenderer: () => {
                const appointmentFromAllocation = timesheet.days.every(
                  (x) => x.time === "00:00" && x.extraTime === "00:00"
                );
                return (
                  <>
                    {canEditTimesheet && !appointmentFromAllocation && (
                      <DeletionButtonContainer {...timesheet.timesheet} />
                    )}
                  </>
                );
              },
            });

            return {
              id: indexTimesheet,
              cells,
            };
          }
        ) ?? [];

      const newAppointments =
        values.costCenterCreateTimesheets?.map(
          (timesheet, indexTimesheet): ExternalEnhancedRow => {
            const cells: ExternalEnhancedRowCells = [
              {
                CellRenderer: () => (
                  <StyledIdentificationAddCellContainer>
                    <StyledIdentificationAddCell ref={newItemRef}>
                      <AppointmentTypeAutocompleteFormik
                        name={`costCenterCreateTimesheets[${indexTimesheet}].appointment`}
                        textfieldProps={{
                          variant: "standard",
                        }}
                        autocompleteProps={{
                          value: timesheet.appointment || null,
                        }}
                        idSubsidiaryLastHistory={
                          timesheetData?.idSubsidiaryLastHistory ?? null
                        }
                      />
                      {values.costCenterCreateTimesheets[indexTimesheet]
                        .appointment?.costCenter && (
                        <AttachPipedriveOrganization
                          name={`costCenterCreateTimesheets[${indexTimesheet}].pipedriveOrganization`}
                          textButton={
                            timesheet.pipedriveOrganization?.name ??
                            t(
                              "timesheets.actions.general.associeteClientAccount"
                            )
                          }
                          value={timesheet.pipedriveOrganization}
                          reloadTablePage={reloadTablePage}
                        />
                      )}
                    </StyledIdentificationAddCell>
                    <StyledExtraTimeObservationContainer>
                      <Observation
                        name={`costCenterCreateTimesheets[${indexTimesheet}].observation`}
                      />
                      <ExtraTime
                        weekDays={weekDays}
                        reloadTablePage={reloadTablePage}
                        field={`costCenterCreateTimesheets[${indexTimesheet}].extraTime[{{ day }}]`}
                      />
                    </StyledExtraTimeObservationContainer>
                  </StyledIdentificationAddCellContainer>
                ),
              },
            ];

            const weekDays = getWeekDays(dateFilter);
            weekDays.forEach((day) => {
              if (day.date.getMonth() === dateFilter.getMonth()) {
                cells.push({
                  CellRenderer: () => (
                    <div>
                      <CalendarWeekCell
                        name={`costCenterCreateTimesheets[${indexTimesheet}].days[${day.date.getDate() - 1}]`}
                      />
                      <div>
                        <StyledExtraTimeText
                          style={{
                            color: "red",
                          }}
                        >
                          {
                            values.costCenterCreateTimesheets[indexTimesheet]
                              .extraTime[day.date.getDate() - 1]
                          }
                        </StyledExtraTimeText>
                      </div>
                    </div>
                  ),
                });
              } else {
                cells.push("");
              }
            });

            cells.push({
              CellRenderer: () => (
                <>
                  {canEditTimesheet && (
                    <DeleteIconButton
                      onClick={() => handleDeleteNewAppointment(indexTimesheet)}
                    />
                  )}
                </>
              ),
            });

            return {
              id: indexTimesheet,
              cells,
            };
          }
        ) ?? [];

      return [...currentAppointments, ...newAppointments].map((x, i) => ({
        ...x,
        id: i,
      }));
    }, [dateFilter, values, t]);

  return {
    headCells,
    onPageChange,
    deletionModal,
  };
};
