import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback, useContext } from "react";
import { AddIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import {
  OnClickContentButtonFunction,
  TwoSidedModalActions,
  useFormikModalButton,
} from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useFormikConfig } from "./hooks/useFormikConfig";
import {
  CreateFormValues,
  InternalFormData,
} from "./shared/types/data/form/values.types";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { mofifyTimesheetV2 } from "../../../../../../../../../services/timesheet/timesheets.service";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormButtonComponent } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/props.types";
import { generateDaysForMonth } from "../../../../shared/helpers/data/calendar/generators.helpers";
import { sleep } from "../../../../../../../../../shared/common/helpers/general.helpers";
import { parseHourStringToMinutes } from "../../../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { validateDaysTimesheetValuesExceeded } from "../../../../shared/helpers/data/form/validators.helpers";
import { getCostCenterLoggedUser } from "../../../../../../../../../services/collaboratorHistory/collaboratorHistory.service";
import { OtherHoursHelpIconButton } from "../../shared/components/OtherHoursHelpIconButton";
import { CommonTimesheetModifyingForm } from "../../shared/components/CommonTimesheetModifyingForm";
import { TimesheetContext } from "../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { EmployeeAllocationType } from "../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { ProjectDropdownDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectDropdownDTO";
import { CreationType } from "../../shared/types/values.types";
import { TimesheetAppointmentType } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/timesheet/AppointmentTypeAutocompleteFormik";

interface OwnProps {
  creationType: CreationType;
  shouldShowButtonContained: boolean;
}

export const useCreationForm = ({
  creationType,
  shouldShowButtonContained,
}: OwnProps) => {
  const { t } = useTranslation();
  const { dateFilter, reloadTimesheetData, canEditTimesheet } =
    useContext(TimesheetContext);

  const formikConfig = useFormikConfig();
  const yearMonth = YearMonth.createFromDate(dateFilter);

  const onClickContentButton = useCallback<
    OnClickContentButtonFunction<CreateFormValues, InternalFormData>
  >(
    async ({ setInternalFormData, setFormValues }) => {
      const days = generateDaysForMonth(yearMonth);

      const costCenterCollaborator = await getCostCenterLoggedUser(yearMonth);

      setFormValues({
        ...formikConfig.initialValues,
        days,
      });
      setInternalFormData({
        yearMonth,
        timesheetMonthWithOthers: null,
        isCalendarVisible: false,
        idSubsidiaryLoggedUser: costCenterCollaborator?.subsidiary.id,
      });
    },
    [yearMonth, formikConfig.initialValues]
  );

  const FormButton = useCallback<FormButtonComponent>(
    ({ onClick }) => {
      if (!canEditTimesheet) return <AddIconButton disabled />;

      const name =
        creationType === "project"
          ? t("timesheets.actions.general.addProjectTimesheet")
          : t("timesheets.actions.general.addCostCenterTimesheet");

      return shouldShowButtonContained ? (
        <Button
          sx={{
            textTransform: "none",
            width: "max-content",
          }}
          onClick={onClick}
          variant="contained"
          startIcon={<AddIcon />}
        >
          {name}
        </Button>
      ) : (
        <AddIconButton
          onClick={onClick}
          tooltipText={t("general.actions.general.add")}
        />
      );
    },
    [t, yearMonth, canEditTimesheet]
  );

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton<CreateFormValues, InternalFormData>({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("timesheets.modal.data.create.title"),
        onCloseModal: async ({
          setFormValues,
          formValues,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          const currentModalCount = getOpenCloseModalCount();
          await sleep(500);

          if (!checkInCurrentModalCount(currentModalCount)) return;

          setFormValues({
            ...formValues,
            appointment: null,
          });
        },
      },
      button: {
        FormButton,
        onClickContentButton,
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData }) =>
          internalFormData && (
            <CommonTimesheetModifyingForm
              timesheetMonth={null}
              timesheetMonthWithOthers={
                internalFormData.timesheetMonthWithOthers
              }
              formMode="creation"
              creationType={creationType}
            />
          ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          internalFormData,
        }) => {
          const { t } = useTranslation();

          return (
            <TwoSidedModalActions>
              <div>
                {internalFormData?.isCalendarVisible && (
                  <OtherHoursHelpIconButton />
                )}
              </div>
              <div>
                <Button onClick={closeModal} disabled={isSubmitting}>
                  {t("general.actions.general.cancel")}
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  onClick={submitFormValues}
                  variant="contained"
                >
                  {t("general.actions.general.save")}
                </LoadingButton>
              </div>
            </TwoSidedModalActions>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting, setFieldError },
          closeModal,
          internalFormData,
        }) => {
          const hasFoundErrors = validateDaysTimesheetValuesExceeded(
            formValues.days,
            internalFormData?.timesheetMonthWithOthers
              ?.otherProjectOrCostCenterDays ?? null,
            setFieldError
          );
          if (hasFoundErrors) return;

          try {
            if (creationType === "project") {
              const appointment = formValues.appointment as ProjectDropdownDTO;
              await mofifyTimesheetV2([
                {
                  yearMonth,
                  allocationType: EmployeeAllocationType.Allocation,
                  idCostCenter: undefined,
                  idCorporateSegment: undefined,
                  idProject: appointment?.id,
                  idActivity: formValues.activity?.id,
                  days: formValues.days.map((x) => ({
                    id: x.id,
                    day: x.day,
                    minutes: parseHourStringToMinutes(x.time),
                    extraMinutes: parseHourStringToMinutes(x.extraTime),
                  })),
                },
              ]);
            } else {
              const appointment =
                formValues.appointment as TimesheetAppointmentType;
              await mofifyTimesheetV2([
                {
                  yearMonth,
                  allocationType:
                    appointment.allocationType ??
                    appointment.corporateSegment?.allocationType ??
                    EmployeeAllocationType.Support,
                  idCostCenter:
                    appointment.corporateSegment?.costCenter?.id ??
                    appointment.costCenter?.id ??
                    undefined,
                  idCorporateSegment:
                    appointment.corporateSegment?.id ?? undefined,
                  idProject: undefined,
                  idPipedriveOrganization: formValues.pipedriveOrganization?.id,
                  days: formValues.days.map((x) => ({
                    id: x.id,
                    day: x.day,
                    minutes: parseHourStringToMinutes(x.time),
                    extraMinutes: parseHourStringToMinutes(x.extraTime),
                  })),
                },
              ]);
            }

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            await reloadTimesheetData();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "timesheets.errors.data.create.failedToCreateTimesheetEntry",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
