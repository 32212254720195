import * as yup from "yup";

export enum EmployeeAllocationType {
  Allocation = 0,
  Unallocated = 1,
  Vacation = 2,
  Leave = 3,
  Training = 4,
  Support = 5,
  Allowance = 6,
  PerformanceEvaluation = 7,
  MonthlyOnboarding = 8,
  SupplementaryAllocation = 9,
  CorporateMeeting = 10,
  Holiday = 11,
  RadixActivities = 12,
}

export const schemaEmployeeAllocationType = yup
  .mixed<EmployeeAllocationType>()
  .oneOf(Object.values(EmployeeAllocationType) as EmployeeAllocationType[]);

export const schemaEmployeeAllocationTypeNullable = yup
  .mixed<EmployeeAllocationType>()
  .nullable()
  .oneOf([
    ...Object.values(EmployeeAllocationType),
    null,
  ] as (EmployeeAllocationType | null)[]);
