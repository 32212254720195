import { styled } from "@mui/material";

export const StyledCenteredPicker = styled("div")`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledNavigationBottomContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  padding-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledCalendarAndTimesheetSection = styled("div")`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.spacing(8)});
  padding-top: ${({ theme }) => theme.spacing(2.5)};
  gap: ${({ theme }) => theme.spacing(2.5)};
`;

export const StyledWeekMonthTabContainer = styled("div")`
  width: 100%;
  height: 100%;
`;
