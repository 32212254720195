import { useTranslation } from "react-i18next";
import { useMemo, useRef } from "react";
import { Typography } from "@mui/material";
import { FillRecurrenceFormValues } from "../../values.types";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { getTimesheetRecurrenceLineIdentification } from "../../../../../../../../../PersonalSettings/tabs/TimesheetRecurrence/shared/general/helpers";
import { weekAbreviationToTranslationCode } from "../../../../../../../../../../../../shared/common/maps/enums/data/dates/weekToTranslationCode.maps";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { formatMinutesToHourMinute } from "../../../../../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { CheckboxFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

type OwnProps = FormContentProps<FillRecurrenceFormValues>;

export const RecurrenceForm = ({ formikProps: { values } }: OwnProps) => {
  const { t } = useTranslation();

  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: "",
        canSort: false,
      },
      {
        value: t("timesheetRecurrence.keywords.fields.recurrence"),
        canSort: false,
      },
      {
        value: t("timesheetRecurrence.keywords.fields.periodicity"),
        canSort: false,
      },
      {
        value: t("timesheetRecurrence.keywords.fields.durationPerDay"),
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const rows: ExternalEnhancedRow[] = values.recurrences.map((x, i) => ({
    id: i,
    cells: [
      {
        CellRenderer: () => <CheckboxFormik name={`recurrences[${i}].use`} />,
      },
      getTimesheetRecurrenceLineIdentification(x.recurrence, t),
      x.recurrence.recurrencesDay
        .map((y) => t(weekAbreviationToTranslationCode[y.weekDay]))
        .join(", "),
      formatMinutesToHourMinute(x.recurrence.minutes),
    ],
  }));

  return (
    <div>
      <Typography variant="subtitle1">
        <i>{t("timesheetRecurrence.pages.helper.title")}</i>
      </Typography>
      <li>
        <b>{t("timesheetRecurrence.pages.helper.update")}</b>{" "}
        {t("timesheetRecurrence.pages.helper.updateDescription")}
      </li>
      <li>
        <b>{t("timesheetRecurrence.pages.helper.respectHistory")}</b>{" "}
        {t("timesheetRecurrence.pages.helper.respectHistoryDescription")}
      </li>
      <li>
        <b>{t("timesheetRecurrence.pages.helper.flexibilizes")}</b>{" "}
        {t("timesheetRecurrence.pages.helper.flexibilizesDescription")}
      </li>
      <br />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: false,
        }}
        rows={rows}
      />
    </div>
  );
};
