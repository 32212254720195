import * as yup from "yup";
import { riseApi } from "./connection";
import { PiipedriveDealUpdateTypeDTO } from "../../../shared/specific/DTOs/pipedrive/PiipedriveDealUpdateTypeDTO";
import {
  PipedriveOrganizationDropdownDTO,
  schemaPipedriveOrganizationDropdownDTO,
} from "../../../shared/specific/DTOs/pipedrive/PipedriveOrganizationDropdownDTO";
import { PipedriveOrganizationFiltersDTO } from "../../../shared/specific/DTOs/pipedrive/filters/PipedriveOrganizationFiltersDTO";

export const getCustomersNames = async (): Promise<string[]> => {
  const { data: CustomersNames } = await riseApi.get(
    "Pipedrive/CustomersNames"
  );

  return yup
    .array(yup.string().defined())
    .defined()
    .validateSync(CustomersNames);
};

export const UpdateDealType = async (info: PiipedriveDealUpdateTypeDTO) => {
  await riseApi.put("Pipedrive/UpdateDealType", info);
};

export const getPipedriveOrganizationDropdown = async (
  filters: PipedriveOrganizationFiltersDTO
): Promise<PipedriveOrganizationDropdownDTO[]> => {
  const { data: pipedriveOrganization } = await riseApi.get(
    "PipedriveOrganization/Dropdown",
    {
      params: filters,
    }
  );

  return yup
    .array(schemaPipedriveOrganizationDropdownDTO)
    .defined()
    .validateSync(pipedriveOrganization);
};
