import { EmployeeAllocationType } from "../../enums/allocations/EmployeeAllocationType.enums";

export const employeeAllocationTypeToTranslationCodeMap: {
  [key in EmployeeAllocationType]: string;
} = {
  [EmployeeAllocationType.Allocation]:
    "allocations.keywords.employeeAllocationType.allocation",
  [EmployeeAllocationType.Unallocated]:
    "allocations.keywords.employeeAllocationType.unallocated",
  [EmployeeAllocationType.Vacation]:
    "allocations.keywords.employeeAllocationType.vacation",
  [EmployeeAllocationType.Leave]:
    "allocations.keywords.employeeAllocationType.leave",
  [EmployeeAllocationType.Training]:
    "allocations.keywords.employeeAllocationType.training",
  [EmployeeAllocationType.Support]:
    "allocations.keywords.employeeAllocationType.support",
  [EmployeeAllocationType.Allowance]:
    "allocations.keywords.employeeAllocationType.allowance",
  [EmployeeAllocationType.PerformanceEvaluation]:
    "allocations.keywords.employeeAllocationType.performanceEvaluation",
  [EmployeeAllocationType.MonthlyOnboarding]:
    "allocations.keywords.employeeAllocationType.monthlyOnboarding",
  [EmployeeAllocationType.SupplementaryAllocation]:
    "allocations.keywords.employeeAllocationType.supplementaryAllocation",
  [EmployeeAllocationType.CorporateMeeting]:
    "allocations.keywords.employeeAllocationType.corporateMeeting",
  [EmployeeAllocationType.Holiday]:
    "allocations.keywords.employeeAllocationType.holiday",
  [EmployeeAllocationType.RadixActivities]:
    "allocations.keywords.employeeAllocationType.radixActivities",
};
