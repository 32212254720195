import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { SearchAutocompleteFormikProps } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { ProjectsFiltersDTO } from "../../../../../../../DTOs/project/filters/ProjectsFiltersDTO";
import { getDropdownProjectsCache } from "../../../../../../../../../services/projects/projects.service";
import { ProjectDropdownDTO } from "../../../../../../../DTOs/project/ProjectDropdownDTO";
import { AsyncAutocompleteFormik } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";

export interface ProjectCacheAutocompleteFormikWithoutPermissionTypeProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    ProjectDropdownDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<ProjectsFiltersDTO, "name">;
  filtersMemo?: Omit<ProjectsFiltersDTO, "name">;
}

export const ProjectCacheAutocompleteFormikWithoutPermissionType = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
}: ProjectCacheAutocompleteFormikWithoutPermissionTypeProps<DisableClearable>) => {
  const { t } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getProjectsOnlyFinal = useCallback(async () => {
    const projects = await getDropdownProjectsCache({
      filters: { ...finalFilters },
    });

    return projects;
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t("projects.errors.data.fetch.failedToFetchProjects")}
      fetchOptions={getProjectsOnlyFinal}
      textfieldProps={{
        label: t("projects.keywords.general.project"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
