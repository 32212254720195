import * as yup from "yup";
import LoopIcon from "@mui/icons-material/Loop";
import { useContext, useMemo } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import { LoadingButton } from "@mui/lab";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FillRecurrenceFormValues } from "./shared/values.types";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TimesheetContext } from "../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { getLoggedUserTimesheetRecurrence } from "../../../../../../../../../services/timesheetRecurrence/timesheetRecurrence.service";
import { RecurrenceForm } from "./shared/components/recurrenceForm";
import { TimesheetWeekFormValues } from "../../shared/types/form.values";
import { setTimesheetRecurrenceValues } from "./shared/setTimesheetRecurrenceValues";

export const useFillRecurrenceForm = () => {
  const { dateFilter, timesheetData } = useContext(TimesheetContext);

  const {
    values: timesheetValues,
    setFieldValue: setTimesheetFieldValue,
    submitForm,
  } = useFormikContext<TimesheetWeekFormValues>();

  const formikConfig = useMemo(() => {
    const initialValues: FillRecurrenceFormValues = {
      recurrences: [],
    };

    const validationSchema = yup.object({
      recurrences: yup.array(),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, []);

  const { ContentButton: RecurrenceButton, contentModal: recurrenceModal } =
    useFormikModalButton<FillRecurrenceFormValues>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => (
          <div>{t("timesheets.actions.general.useRecurrence")}</div>
        ),
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();
          return (
            <Button onClick={onClick}>
              <LoopIcon />
              {t("timesheetRecurrence.pages.main.recurrence")}
            </Button>
          );
        },
        onClickContentButtonComponentMemo: async ({
          setFormValues,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const recurrences = await getLoggedUserTimesheetRecurrence();

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setFormValues({
              recurrences: recurrences.map((recurrence) => ({
                recurrence,
                use: true,
              })),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: t("general.errors.data.fetch.failedToFetchData"),
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: (props) => {
          return <RecurrenceForm {...props} />;
        },
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                onClick={submitFormValues}
                variant="contained"
                loading={isSubmitting}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            setSubmitting(true);
            const newValues = setTimesheetRecurrenceValues({
              formValues,
              timesheetValues,
              dateFilter,
              hasTrustedRole: timesheetData?.hasTrustedRole ?? false,
            });

            if (newValues) {
              setTimesheetFieldValue(
                "projectTimesheets",
                newValues.projectTimesheets
              );
              setTimesheetFieldValue(
                "costCenterTimesheets",
                newValues.costCenterTimesheets
              );
              setTimesheetFieldValue(
                "projectCreateTimesheets",
                newValues.projectCreateTimesheets
              );
              setTimesheetFieldValue(
                "costCenterCreateTimesheets",
                newValues.costCenterCreateTimesheets
              );
            }

            await submitForm();

            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "timesheets.errors.data.edit.failedToEditTimesheetEntry",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { RecurrenceButton, recurrenceModal };
};
