import { useMemo, useState } from "react";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { BottomNavigation } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  StyledBottomNavigationAction,
  StyledBoxContainer,
} from "./index.styles";
import { BottomNavigationEnum } from "../../../../types/data/form/general.types";

export const useBottomNavigation = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<BottomNavigationEnum>(
    BottomNavigationEnum.Week
  );

  const bottomNavigationComponent = useMemo(() => {
    return (
      <StyledBoxContainer>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(
            _event: React.SyntheticEvent,
            newValue: BottomNavigationEnum
          ) => {
            setValue(newValue);
          }}
        >
          <StyledBottomNavigationAction
            label={t("general.keywords.fields.week")}
            value={BottomNavigationEnum.Week}
            icon={<CalendarViewWeekIcon />}
            sx={{
              "&.Mui-selected": {
                ".MuiBottomNavigationAction-label": {
                  fontSize: "1.2rem",
                },
              },
              ".MuiBottomNavigationAction-label": {
                fontSize: "1rem",
              },
            }}
          />
          <StyledBottomNavigationAction
            label={t("general.keywords.dateTime.general.month")}
            value={BottomNavigationEnum.Month}
            icon={<CalendarMonthIcon />}
            sx={{
              "&.Mui-selected": {
                ".MuiBottomNavigationAction-label": {
                  fontSize: "1.2rem",
                },
              },
              ".MuiBottomNavigationAction-label": {
                fontSize: "1rem",
              },
            }}
          />
        </BottomNavigation>
      </StyledBoxContainer>
    );
  }, [value, t]);

  return {
    bottomNavigationComponent,
    tabValue: value,
  };
};
