import * as yup from "yup";
import { saveAs } from "file-saver";
import i18next from "i18next";
import { formatFilename } from "../../../shared/common/helpers/data/general/formatters/fileFormatters.helpers";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { CostCenterCreateDTO } from "../../../shared/specific/DTOs/costCenter/CostCenterCreateDTO";
import { CostCenterFiltersDTO } from "../../../shared/specific/DTOs/costCenter/filters/CostCenterFiltersDTO";
import {
  PaginatedCostCenterDTO,
  schemaPaginatedCostCenterDTO,
} from "../../../shared/specific/DTOs/costCenter/PaginatedCostCenterDTO";
import { CostCenterUpdateDTO } from "../../../shared/specific/DTOs/costCenter/CostCenterUpdateDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";
import {
  PaginatedCostCenterDropdownDTO,
  schemaPaginatedCostCenterDropdownDTO,
} from "../../../shared/specific/DTOs/costCenter/PaginatedCostCenterDropdownDTO";
import {
  CostCenterDropdownDTO,
  schemaCostCenterDropdownDTO,
} from "../../../shared/specific/DTOs/costCenter/CostCenterDropdownDTO";

export const getCostCenters = async ({
  filters,
  pagination,
}: {
  filters?: CostCenterFiltersDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedCostCenterDTO> => {
  const { data: paginatedCostCenters } = await riseApi.get("CostCenters", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedCostCenterDTO.validateSync(paginatedCostCenters);
};

export const getDropdownCostCenters = async ({
  filters,
  pagination,
}: {
  filters?: CostCenterFiltersDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedCostCenterDropdownDTO> => {
  const { data: dropdownCostCenters } = await riseApi.get(
    "CostCenters/Dropdown",
    {
      params: { ...filters, ...pagination },
    }
  );

  return schemaPaginatedCostCenterDropdownDTO.validateSync(dropdownCostCenters);
};

export const getDropdownCostCentersCache = async ({
  filters,
}: {
  filters?: CostCenterFiltersDTO;
}): Promise<CostCenterDropdownDTO[]> => {
  const { data: dropdownCostCenters } = await riseApi.get(
    "CostCenters/Dropdown/cache",
    {
      params: { ...filters },
    }
  );

  return yup
    .array(schemaCostCenterDropdownDTO.defined())
    .defined()
    .validateSync(dropdownCostCenters);
};

export const getCostCenterById = async (
  id: number
): Promise<CostCenterDTO | null> => {
  const { data: costCenter } = await riseApi.get(`CostCenters/${id}`);

  return schemaCostCenterDTO.nullable().validateSync(costCenter);
};

export const deleteCostCenter = async (id: number, version: string) => {
  await riseApi.delete(`CostCenters/${id}/${version}`);
};

export const createCostCenter = async (costCenter: CostCenterCreateDTO) => {
  await riseApi.post("CostCenters", costCenter);
};

export const updateCostCenter = async (costCenter: CostCenterUpdateDTO) => {
  await riseApi.put("CostCenters", costCenter);
};

export const generateCostCentersSpreadsheet = async ({
  filters,
  filename,
}: {
  filters?: CostCenterFiltersDTO;
  filename?: string;
}) => {
  const { data: spreadsheetBlob } = await riseApi.get(
    "CostCenters/Spreadsheet",
    {
      params: filters,
      responseType: "blob",
    }
  );

  const finalFilename =
    filename ??
    formatFilename(i18next.t("costCenters.keywords.general.costCenters"), {
      extension: "xlsx",
    });

  saveAs(
    new Blob([spreadsheetBlob], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};
