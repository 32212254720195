import { Box, Chip, TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Week } from "../../../../../../../enums/data/dates/Week.enum";
import {
  weekAbreviationToTranslationCode,
  weekToTranslationCode,
} from "../../../../../../../maps/enums/data/dates/weekToTranslationCode.maps";
import { AutocompleteOption } from "../../../../../../../types/data/form/autocomplete.types";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../general/AutocompleteFormik";
import { StyledMultipleSelectionContainer } from "../../../../../../../../specific/react/components/form/shared/styles/multipleSelection.styles";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<Week>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const WeekAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(Week)
      .filter((x) => typeof x === "number")
      .map((x) => x as unknown as Week)
      .map((week) => ({
        id: week,
        label: t(weekToTranslationCode[week]),
      }));
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
        renderTags: (value) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              {value.map((x, i) => {
                const day = t(weekAbreviationToTranslationCode[x.id]);
                return (
                  <Chip
                    label={day}
                    key={i}
                    sx={{
                      height: "24px",
                    }}
                  />
                );
              })}
            </div>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {option.label}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
      }}
      textfieldProps={textfieldProps}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
