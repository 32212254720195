import { styled } from "@mui/material/styles";

export const StyledContainerForm = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  min-width: 550px;
`;

export const StyledAppointmentRow = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledPeriodicityRow = styled("div")`
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};
`;
