import * as yup from "yup";

export interface ActivityDropdownDTO {
  id: number;
  idProject: number;
  description: string;
  scheduledDate: Date;
}

export const schemaActivityDropdownDTO = yup.object({
  id: yup.number().integer().defined(),
  idProject: yup.number().integer().defined(),
  description: yup.string().defined(),
  scheduledDate: yup.date().defined(),
});
