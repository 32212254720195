import { styled, TableRow } from "@mui/material";

export const StyledWeekTabContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

export const StyledButtonArea = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
`;

export const StyledTablesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  overflow-y: auto;
  border-radius: 4px;
`;

export const StyledTotalTable = styled("div")`
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

export const TotalTableRow = styled(TableRow)`
  background-color: ${({ theme }) => theme.palette.action.hover};

  &.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  .MuiTableCell-root {
    color: "black";
  }

  .MuiIconButton-root {
    color: "black";
  }
`;
