import { Button } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { startOfMonth } from "date-fns";
import { useContext, useMemo } from "react";
import { TipPopoverButton } from "../../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";
import { PipedriveOrganizationAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/pipedriveDeal/PipedriveOrganizationAutocompleteFormik";
import { PipedriveOrganizationDropdownDTO } from "../../../../../../../../../../shared/specific/DTOs/pipedrive/PipedriveOrganizationDropdownDTO";
import { TimesheetContext } from "../../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";

interface OwnProps {
  textButton: string;
  name: string;
  reloadTablePage: () => void;
  value: PipedriveOrganizationDropdownDTO | null;
}

export const AttachPipedriveOrganization = ({
  name,
  textButton,
  reloadTablePage,
  value,
}: OwnProps) => {
  const { dateFilter } = useContext(TimesheetContext);

  const isPastMonth = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    return startOfCurrentMonth > startDateFilter;
  }, [dateFilter]);
  return (
    <TipPopoverButton
      button={
        <Button
          startIcon={<InsertLinkIcon />}
          sx={{
            textTransform: "none",
          }}
          disabled={isPastMonth}
        >
          {textButton}
        </Button>
      }
      content={
        <PipedriveOrganizationAutocompleteFormik
          name={name}
          autocompleteProps={{
            sx: {
              width: "300px",
            },
            value: value || null,
          }}
        />
      }
    />
  );
};
