import { useTranslation } from "react-i18next";
import { InvoiceDatesDataFormValues } from "../../../types/form/invoiceDatesDataFormValues";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { StyledInvoiceDatesForm } from "./index.styles";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";

type OwnProps = FormContentProps<InvoiceDatesDataFormValues, InvoiceDTO>;

export const DatesDataForm = ({
  formikProps: { isSubmitting },
  internalFormData,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledInvoiceDatesForm>
      {internalFormData?.issueDate && (
        <DatePickerFormik
          name="issueDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.issueDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {internalFormData?.paymentDate && (
        <DatePickerFormik
          name="paymentDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.paymentDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {internalFormData?.dueDate && (
        <DatePickerFormik
          name="dueDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.dueDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {internalFormData?.provisionCancellationDate && (
        <DatePickerFormik
          name="plannedCancellationDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.provisionCancellationDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {internalFormData?.cancellationDate && (
        <DatePickerFormik
          name="cancellationDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.cancellationDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {internalFormData?.pddDate && (
        <DatePickerFormik
          name="pddDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.pddDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {internalFormData?.lossDate && (
        <DatePickerFormik
          name="lossDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.lossDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
    </StyledInvoiceDatesForm>
  );
};
