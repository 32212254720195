import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useTimesheetRecurrenceFormikConfig } from "./useFormikConfig";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TimesheetRecurrenceFormValues } from "../shared/types/form.values";
import { TimesheetRecurrenceDTO } from "../../../../../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceDTO";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { formatMinutesToHourMinute } from "../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { weekAbreviationToTranslationCode } from "../../../../../../../shared/common/maps/enums/data/dates/weekToTranslationCode.maps";
import { CommonTimesheetRecurrenceForm } from "../shared/components/CommonTimesheetRecurrenceForm";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { TimesheetRecurrenceModifyDTO } from "../../../../../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceModifyDTO";
import { parseHourStringToMinutes } from "../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { mofifyTimesheetRecurrennce } from "../../../../../../../services/timesheetRecurrence/timesheetRecurrence.service";
import { getTimesheetRecurrenceLineIdentification } from "../shared/general/helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useTimesheetRecurrenceFormikConfig({
    formMode: "edit",
  });

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      TimesheetRecurrenceFormValues,
      TimesheetRecurrenceDTO,
      TimesheetRecurrenceDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData }) => (
          <div>
            {t("timesheetRecurrence.keywords.actions.edit", {
              recurrence: internalFormData
                ? getTimesheetRecurrenceLineIdentification(internalFormData, t)
                : "",
            })}
          </div>
        ),
        modalMode: "dialog",
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: timesheetRecurrence,
          setFormValues,
          setInternalFormData,
        }) => {
          setInternalFormData(timesheetRecurrence);
          setFormValues({
            appointment: null,
            pipedriveOrganization: null,
            project: null,
            days: timesheetRecurrence.recurrencesDay.map((x) => ({
              id: x.weekDay,
              label: weekAbreviationToTranslationCode[x.weekDay],
            })),
            timesheetType: null,
            time: formatMinutesToHourMinute(timesheetRecurrence.minutes),
          });
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ ...rest }) => (
          <CommonTimesheetRecurrenceForm {...rest} mode="edit" />
        ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: timesheetRecurrenceData,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!timesheetRecurrenceData)
            return notifyError(
              t("timesheetRecurrence.errors.data.edit.failedToEdit")
            );

          try {
            const formData: TimesheetRecurrenceModifyDTO = {
              idProject: timesheetRecurrenceData.project?.id,
              idCostCenter: timesheetRecurrenceData.costCenter?.id,
              idCorporateSegment: timesheetRecurrenceData.corporateSegment?.id,
              idPipedriveOrganization:
                timesheetRecurrenceData.pipedriveOrganization?.id,
              allocationType: timesheetRecurrenceData.allocationType,
              minutes: parseHourStringToMinutes(formValues.time),
              recurrencesDay: formValues.days.map((day) => ({
                weekDay: day.id,
              })),
            };

            await mofifyTimesheetRecurrennce(formData);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            notifyError(t("timesheetRecurrence.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
