import { styled } from "@mui/material";

export const StyledIdentificationCell = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledTotalTableCell = styled("span")`
  font-weight: bold;
`;
