import * as yup from "yup";
import { ActivityCloseDTO } from "../../../shared/specific/DTOs/activity/ActivityCloseDTO";
import { ActivityCreateDTO } from "../../../shared/specific/DTOs/activity/ActivityCreateDTO";
import { ActivityCreateManyDTO } from "../../../shared/specific/DTOs/activity/ActivityCreateManyDTO";
import {
  ActivityCreationInfoDTO,
  schemaActivityCreationInfoDTO,
} from "../../../shared/specific/DTOs/activity/ActivityCreationInfoDTO";
import { ActivityDeleteManyDTO } from "../../../shared/specific/DTOs/activity/ActivityDeleteManyDTO";
import { ActivityDeprovisionCreateDTO } from "../../../shared/specific/DTOs/activity/ActivityDeprovisionCreateDTO";
import { ActivityDeprovisionUpdateDTO } from "../../../shared/specific/DTOs/activity/ActivityDeprovisionUpdateDTO";
import {
  ActivityDTO,
  schemaActivityDTO,
} from "../../../shared/specific/DTOs/activity/ActivityDTO";
import { ActivityReopenDTO } from "../../../shared/specific/DTOs/activity/ActivityReopenDTO";
import {
  ActivitySummaryDTO,
  schemaActivitySummaryDTO,
} from "../../../shared/specific/DTOs/activity/ActivitySummaryDTO";
import { ActivityUpdateDTO } from "../../../shared/specific/DTOs/activity/ActivityUpdateDTO";
import { ActivityBackendFiltersDTO } from "../../../shared/specific/DTOs/activity/filters/ActivityBackendFiltersDTO";
import { riseApi } from "./connection";
import {
  ActivityDropdownDTO,
  schemaActivityDropdownDTO,
} from "../../../shared/specific/DTOs/activity/ActivityDropdownDTO";

export const getActivities = async ({
  filters,
}: {
  filters?: ActivityBackendFiltersDTO;
} = {}): Promise<ActivityDTO[]> => {
  const { data: activities } = await riseApi.get("Activities", {
    params: { ...filters },
  });

  return yup.array(schemaActivityDTO).defined().validateSync(activities);
};

export const getActivitiesDropdown = async ({
  filters,
}: {
  filters?: ActivityBackendFiltersDTO;
} = {}): Promise<ActivityDropdownDTO[]> => {
  const { data: activities } = await riseApi.get("Activities/Dropdown", {
    params: { ...filters },
  });

  return yup
    .array(schemaActivityDropdownDTO)
    .defined()
    .validateSync(activities);
};

export const getActivityById = async (
  id: number
): Promise<ActivityDTO | null> => {
  const { data: activity } = await riseApi.get(`Activities/${id}`);

  return schemaActivityDTO.nullable().validateSync(activity);
};

export const createActivity = async (activity: ActivityCreateDTO) => {
  await riseApi.post("Activities", activity);
};

export const createActivities = async (
  activitiesCreateMany: ActivityCreateManyDTO
) => {
  await riseApi.post("Activities/Many", activitiesCreateMany);
};

export const createActivityDeprovision = async (
  deprovision: ActivityDeprovisionCreateDTO
) => {
  await riseApi.post("Activities/Deprovisions", deprovision);
};

export const updateActivity = async (activity: ActivityUpdateDTO) => {
  await riseApi.put("Activities", activity);
};

export const updateActivityDeprovision = async (
  deprovision: ActivityDeprovisionUpdateDTO
) => {
  await riseApi.put("Activities/Deprovisions", deprovision);
};

export const closeActivity = async (activityCloseDTO: ActivityCloseDTO) => {
  await riseApi.put("Activities/Close", activityCloseDTO);
};

export const reopenActivity = async (activityReopenDTO: ActivityReopenDTO) => {
  await riseApi.put("Activities/Reopen", activityReopenDTO);
};

export const getProjectActivitiesSummary = async (
  idProject: number
): Promise<ActivitySummaryDTO | null> => {
  const { data: activitiesSummary } = await riseApi.get(
    `Activities/Project/${idProject}/Summary`
  );

  return schemaActivitySummaryDTO.nullable().validateSync(activitiesSummary);
};

export const getActivityCreationInfo = async (
  idProject: number
): Promise<ActivityCreationInfoDTO | null> => {
  const { data: activitiesCreationInfo } = await riseApi.get(
    `Activities/Project/${idProject}/CreationInfo`
  );

  return schemaActivityCreationInfoDTO
    .nullable()
    .validateSync(activitiesCreationInfo);
};

export const deleteActivity = async (id: number, version: string) => {
  await riseApi.delete(`Activities/${id}/${version}`);
};

export const deleteManyActivities = async (array: ActivityDeleteManyDTO[]) => {
  const { data: activityManyResponseDTO } = await riseApi.post(
    "Activities/DeleteManyActivities",
    array
  );
  return activityManyResponseDTO;
};

export const reopenManyActivities = async (array: ActivityReopenDTO[]) => {
  const { data: activityManyResponseDTO } = await riseApi.post(
    "Activities/ReopenManyActivities",
    array
  );
  return activityManyResponseDTO;
};

export const closeManyActivities = async (array: ActivityCloseDTO[]) => {
  const { data: activityManyResponseDTO } = await riseApi.put(
    "Activities/CloseManyActivities",
    array
  );
  return activityManyResponseDTO;
};
