import { useFormikContext } from "formik";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TimesheetWeekFormValues } from "../shared/types/form.values";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { ExternalEnhancedHeadCell } from "../../../../../../../../shared/common/react/components/table/EnhancedTable";

interface OwnProps {
  reloadTablePageProject: () => void;
  reloadTablePageCostCenter: () => void;
  costCenterHeadCells: ExternalEnhancedHeadCell[];
  projectHeadCells: ExternalEnhancedHeadCell[];
}

export const useReloadTables = ({
  reloadTablePageProject,
  reloadTablePageCostCenter,
  costCenterHeadCells,
  projectHeadCells,
}: OwnProps) => {
  const { t } = useTranslation();
  const { values, errors } = useFormikContext<TimesheetWeekFormValues>();
  const { dateFilter } = useContext(TimesheetContext);

  const projectActivityJson = JSON.stringify(
    values.projectTimesheets.map((x) => x.activity)
  );

  const projectCreateJson = JSON.stringify(
    values.projectCreateTimesheets.map((x) => ({
      appointment: x.appointment,
      activity: x.activity,
    }))
  );

  const costCenterpipedriveOrganizationJson = JSON.stringify(
    values.costCenterTimesheets.map((x) => x.pipedriveOrganization)
  );

  const costCenterCreateJson = JSON.stringify(
    values.costCenterCreateTimesheets.map((x) => ({
      appointment: x.appointment,
      pipedriveOrganization: x.pipedriveOrganization,
    }))
  );

  const errorsJson = JSON.stringify(errors);

  useEffect(() => {
    reloadTablePageProject();
  }, [projectActivityJson, projectCreateJson, projectHeadCells]);

  useEffect(() => {
    reloadTablePageCostCenter();
  }, [
    costCenterpipedriveOrganizationJson,
    costCenterCreateJson,
    costCenterHeadCells,
  ]);

  useEffect(() => {
    reloadTablePageProject();
    reloadTablePageCostCenter();
  }, [dateFilter, errorsJson, t]);
};
