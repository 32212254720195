import CommentIcon from "@mui/icons-material/Comment";
import { startOfMonth } from "date-fns";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TipPopoverButton } from "../../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";
import { TimesheetContext } from "../../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { SimpleIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";

interface OwnProps {
  name: string;
}

export const Observation = ({ name }: OwnProps) => {
  const { t } = useTranslation();
  const { dateFilter } = useContext(TimesheetContext);

  const isPastMonth = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    return startOfCurrentMonth > startDateFilter;
  }, [dateFilter]);

  return (
    <TipPopoverButton
      button={
        <SimpleIconButton
          Icon={CommentIcon}
          tooltipText={t("allocationsMap.keywords.general.observation")}
          size="small"
        />
      }
      content={
        <TextFieldFormik
          name={name}
          InputProps={{
            sx: {
              width: "300px",
            },
            disabled: isPastMonth,
          }}
          label={t("allocationsMap.keywords.general.observation")}
        />
      }
    />
  );
};
