import i18next from "i18next";
import { useContext, useRef } from "react";
import { FieldArray, Formik, FormikProps } from "formik";
import { WeekTabWithInContext } from "./WeekTabWithInContext";
import { TimesheetWeekFormValues } from "./shared/types/form.values";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TimesheetContext } from "../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { TimesheetModifyMonthDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetModifyMonthDTO";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { EmployeeAllocationType } from "../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { parseHourStringToMinutes } from "../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { mofifyTimesheetV2 } from "../../../../../../../services/timesheet/timesheets.service";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { useFormikConfig } from "./hooks/useFormikConfig";

export const WeekTab = () => {
  const formikRef = useRef<FormikProps<TimesheetWeekFormValues>>(null);

  const { dateFilter, reloadTimesheetData, setLoading } =
    useContext(TimesheetContext);

  const formikConfig = useFormikConfig();

  const onSubmit = async (props: TimesheetWeekFormValues) => {
    setLoading(true);
    const {
      projectTimesheets,
      costCenterTimesheets,
      projectCreateTimesheets,
      costCenterCreateTimesheets,
    } = props;

    const modifyTimesheets: TimesheetModifyMonthDTO[] = [];
    try {
      for (let i = 0; i < projectTimesheets.length; i++) {
        const projectTimesheet = projectTimesheets[i];

        if (!projectTimesheet.timesheet.project)
          throw new Error("Project cannot be null.");

        const formattedTimesheetProject: TimesheetModifyMonthDTO = {
          yearMonth: YearMonth.createFromDate(dateFilter),
          allocationType: EmployeeAllocationType.Allocation,
          idCorporateSegment: undefined,
          idCostCenter: undefined,
          idActivity: projectTimesheet.activity?.id,
          idProject: projectTimesheet.timesheet.project.id,
          days: projectTimesheet.days.map((x) => ({
            id: x.id,
            day: x.day,
            minutes: parseHourStringToMinutes(x.time),
            extraMinutes: parseHourStringToMinutes(x.extraTime),
          })),
          observation: projectTimesheet.observation,
        };
        modifyTimesheets.push(formattedTimesheetProject);
      }

      for (let i = 0; i < costCenterTimesheets.length; i++) {
        const costCenterTimesheet = costCenterTimesheets[i];

        const formattedTimesheetCostCenter: TimesheetModifyMonthDTO = {
          yearMonth: costCenterTimesheet.timesheet.yearMonth,
          allocationType: costCenterTimesheet.timesheet.allocationType,
          idCorporateSegment:
            costCenterTimesheet.timesheet.corporateSegment?.id,
          idCostCenter: costCenterTimesheet.timesheet.costCenter?.id,
          idProject: undefined,
          idPipedriveOrganization:
            costCenterTimesheet.pipedriveOrganization?.id,
          days: costCenterTimesheet.days.map((x) => ({
            id: x.id,
            day: x.day,
            minutes: parseHourStringToMinutes(x.time),
            extraMinutes: parseHourStringToMinutes(x.extraTime),
          })),
          observation: costCenterTimesheet.observation,
        };
        modifyTimesheets.push(formattedTimesheetCostCenter);
      }

      for (let i = 0; i < projectCreateTimesheets.length; i++) {
        const projectCreateTimesheet = projectCreateTimesheets[i];

        if (!projectCreateTimesheet.appointment)
          throw new Error("Project cannot be null.");

        const formattedTimesheetProject: TimesheetModifyMonthDTO = {
          yearMonth: YearMonth.createFromDate(dateFilter),
          allocationType: EmployeeAllocationType.Allocation,
          idCorporateSegment: undefined,
          idCostCenter: undefined,
          idActivity: projectCreateTimesheet.activity?.id,
          idProject: projectCreateTimesheet.appointment.id,
          days: projectCreateTimesheet.days.map((x, i) => ({
            id: undefined,
            day: i + 1,
            minutes: parseHourStringToMinutes(x),
            extraMinutes: parseHourStringToMinutes(
              projectCreateTimesheet.extraTime[i]
            ),
          })),
          observation: projectCreateTimesheet.observation,
        };
        modifyTimesheets.push(formattedTimesheetProject);
      }

      for (let i = 0; i < costCenterCreateTimesheets.length; i++) {
        const costCenterCreateTimesheet = costCenterCreateTimesheets[i];

        if (!costCenterCreateTimesheet.appointment)
          throw new Error("Cost Center cannot be null.");

        const costCenter = costCenterCreateTimesheet.appointment?.costCenter;
        const corporateSegment =
          costCenterCreateTimesheet.appointment?.corporateSegment;

        const finalAllocationType =
          costCenterCreateTimesheet.appointment?.allocationType ??
          corporateSegment?.allocationType ??
          EmployeeAllocationType.Support;

        const formattedTimesheetCreateCostCenter: TimesheetModifyMonthDTO = {
          yearMonth: YearMonth.createFromDate(dateFilter),
          allocationType: finalAllocationType,
          idCorporateSegment: corporateSegment?.id,
          idCostCenter: costCenter?.id ?? corporateSegment?.costCenter?.id,
          idPipedriveOrganization:
            finalAllocationType === EmployeeAllocationType.Support
              ? costCenterCreateTimesheet.pipedriveOrganization?.id
              : undefined,
          idProject: undefined,
          days: costCenterCreateTimesheet.days.map((x, i) => ({
            id: undefined,
            day: i + 1,
            minutes: parseHourStringToMinutes(x),
            extraMinutes: parseHourStringToMinutes(
              costCenterCreateTimesheet.extraTime[i]
            ),
          })),
          observation: costCenterCreateTimesheet.observation,
        };
        modifyTimesheets.push(formattedTimesheetCreateCostCenter);
      }

      await mofifyTimesheetV2(modifyTimesheets);
      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage:
          "timesheets.errors.data.create.failedToCreateTimesheetEntry",
      });
      setLoading(false);
    } finally {
      reloadTimesheetData();
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formikConfig.initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={onSubmit}
      validationSchema={formikConfig.validationSchema}
    >
      {(formikProps) => (
        <FieldArray name="costCenterCreateTimesheets">
          {(arrayCostCenterCreateFormikProps) => (
            <FieldArray name="projectCreateTimesheets">
              {(arrayprojectCreateFormikProps) => (
                <WeekTabWithInContext
                  formikProps={formikProps}
                  arrayprojectCreateFormikProps={arrayprojectCreateFormikProps}
                  arrayCostCenterCreateFormikProps={
                    arrayCostCenterCreateFormikProps
                  }
                />
              )}
            </FieldArray>
          )}
        </FieldArray>
      )}
    </Formik>
  );
};
