import { useTranslation } from "react-i18next";
import { WeekDay } from "../../../../types/data/form/general.types";
import { CellContainer } from "./index.styles";

export const TimesheetHeaderDay = ({ date, dayOfWeek }: WeekDay) => {
  const { t } = useTranslation();
  return (
    <CellContainer>
      <span>{t(dayOfWeek)}</span>
      <span>{date.getDate()}</span>
    </CellContainer>
  );
};
