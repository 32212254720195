import * as yup from "yup";

export enum WeekNumberEnum {
  Week1 = 1,
  Week2 = 2,
  Week3 = 3,
  Week4 = 4,
  Week5 = 5,
}

export const schemaWeekNumberEnum = yup
  .mixed<WeekNumberEnum>()
  .oneOf(Object.values(WeekNumberEnum) as WeekNumberEnum[]);

export const schemaWeekNumberEnumNullable = yup
  .mixed<WeekNumberEnum>()
  .nullable()
  .oneOf([...Object.values(WeekNumberEnum), null] as (WeekNumberEnum | null)[]);
