export const isValidStringMinute = (minuteString: string) => {
  return /^\d\d:\d\d$/.test(minuteString);
};

export const parseHourStringToMinutes = (hourString: string) => {
  if (!isValidStringMinute(hourString)) throw new Error("Invalid time.");

  const [hours, minutes] = hourString.split(":").map((x) => parseInt(x));
  return hours * 60 + minutes;
};
