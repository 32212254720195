import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { StyledButtonContainer } from "./index.styles";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { UpdateIssuedSuperUserDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateIssuedSuperUserDTO";
import { UpdateIssuedSuperUser } from "../../../../../../../../../services/invoices/invoices.service";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useSuperUserEditingDatesFormikConfig } from "../../../../../../Invoices/InvoicesViewPage/shared/react/hooks/data/validation/useSuperUserEditingDatesFormikConfig";
import { InvoiceDatesDataFormValues } from "../../../../../../Invoices/InvoicesViewPage/shared/types/form/invoiceDatesDataFormValues";
import { DatesDataForm } from "../../../../../../Invoices/InvoicesViewPage/shared/components/CommonInvoicesForm/DatesDataForm";
import { convertTo } from "../../../../../../../../../shared/common/helpers/types/converters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
  invoice: InvoiceDTO;
}

export interface UseEditingCellFormContentButtonContentProps {
  children: JSX.Element | null;
  invoice: InvoiceDTO;
}

export const useEditingIssuedDate = ({
  reloadTablePage,
  invoice,
}: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useSuperUserEditingDatesFormikConfig({
    invoiceData: invoice,
  });

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      InvoiceDatesDataFormValues,
      InvoiceDTO,
      UseEditingCellFormContentButtonContentProps
    >
  >(
    async ({
      contentButtonContentProps: { invoice: invoiceData },
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
    }) => {
      setFormValues({
        issueDate: invoiceData.issueDate?.toDate() ?? null,
        cancellationDate: invoiceData.cancellationDate?.toDate() ?? null,
        lossDate: invoiceData.lossDate?.toDate() ?? null,
        paymentDate: invoiceData.paymentDate?.toDate() ?? null,
        pddDate: invoiceData.pddDate?.toDate() ?? null,
        plannedCancellationDate:
          invoiceData.plannedCancellationDate?.toDate() ?? null,
        dueDate: invoiceData.dueDate?.toDate() ?? null,
      });
      setInternalFormData(invoice);

      setIsLoadingModal(false);
    },
    [t]
  );

  const {
    ContentButton: EditingIssuedDateButtonContainer,
    contentModal: editingIssuedDateModal,
  } = useFormikModalButton({
    modal: {
      ModalTitleMemo: () => {
        return <span>{t("invoice.modal.data.edit.issuedDate")}</span>;
      },
      keepModalMounted: 1000,
      modalMode: "dialog",
    },
    button: {
      onClickContentButtonComponent,
      createModalButtonContainerMemo: ({
        onClickButton,
        onClickContentButtonComponent,
      }) => {
        return (props) => {
          const { children } = props;
          const onClick = () => {
            onClickButton();
            onClickContentButtonComponent({ props });
          };

          return (
            <StyledButtonContainer onClick={onClick}>
              {children}
            </StyledButtonContainer>
          );
        };
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) =>
        internalFormData && (
          <DatesDataForm internalFormData={internalFormData} {...rest} />
        ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData: invoiceData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        if (!invoiceData)
          throw new Error("Missing internal form data on submit.");

        try {
          await UpdateIssuedSuperUser(
            convertTo<UpdateIssuedSuperUserDTO>({
              id: invoiceData.id,
              idInvoiceGroup: invoiceData.idInvoiceGroup,
              invoiceIds: invoiceData?.invoiceGroup.map((x) => x.id),
              issueDate: formValues.issueDate ? formValues.issueDate : null,
              paymentDate: formValues.paymentDate
                ? formValues.paymentDate
                : null,
              plannedCancellationDate: formValues.plannedCancellationDate
                ? formValues.plannedCancellationDate
                : null,
              cancellationDate: formValues.cancellationDate
                ? formValues.cancellationDate
                : null,
              pddDate: formValues.pddDate ? formValues.pddDate : null,
              lossDate: formValues.lossDate ? formValues.lossDate : null,
              dueDate: formValues.dueDate ? formValues.dueDate : null,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          setSubmitting(false);
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "allocations.errors.data.update.failedToUpdateAllocationsGroup",
          });
          setSubmitting(false);
        }
      },
    },
  });

  return { EditingIssuedDateButtonContainer, editingIssuedDateModal };
};
