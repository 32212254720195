import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInvoices } from "../../../../../../../../../services/invoices/invoices.service";
import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { FormattedCurrency } from "../../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { InvoiceFiltersDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/filters/InvoiceFiltersDTO";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { StyledSumActivities } from "../../../ActivitiesTab/ActivitiesTabWithinContext/index.styles";
import { useDeletionForm } from "../useDeletionForm";
import { useEditingForm } from "../useEditingForm";
import { useObservationsModal } from "../useObservationsModal";
import { DeleteInvoiceTableButton } from "./components/DeleteInvoiceButton";
import { useInvoiceRequestForm } from "../useInvoiceRequestForm";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { useViewInvoiceRequest } from "../useViewInvoiceRequest";
import { useEditInvoiceRequest } from "../useEditInvoiceRequest";
import { useReopenInvoiceRequest } from "../useReopenInvoiceRequest";
import {
  NegativeTableRow,
  StyledNegativeValue,
  StyledRedIcon,
  StyledValueWithNegativeAmount,
} from "./index.styles";
import { useCancelationInvoiceRequest } from "../useCancelationInvoiceRequest";
import { invoicesStatusTypeToTranslationCode } from "../../../../../../../../../shared/specific/maps/invoices/invoicesStatusTypeToTranslationCode";
import { InvoicesSuperUserContext } from "../../shared/react/contexts/InvoicesSuperUserContext";
import { useEditingIssuedDate } from "../useEditingIssuedDate";
import { CellContent } from "../useEditingIssuedDate/CellContent";
import { ProjectStatus } from "../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";

interface OwnProps {
  reloadTablePage: () => void;
  reloadInvoiceSummary: () => void;
  filters: InvoiceFiltersDTO;
}

export const useTableData = ({
  reloadTablePage,
  reloadInvoiceSummary,
  filters,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const [sumInvoicesFiltered, setSumInvoicesFiltered] = useState<number | null>(
    null
  );

  const { canEditAllInvoices } = useContext(InvoicesSuperUserContext);

  const { accessMode, project } = useProjectContext();
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
    reloadInvoiceSummary,
  });

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadInvoiceSummary,
    reloadTablePage,
  });
  const { ViewingObservationsButton, viewingObservationsModal } =
    useObservationsModal();

  const { RequestButton, requestModal } = useInvoiceRequestForm({
    reloadInvoiceSummary,
    reloadTablePage,
  });

  const { ViewingInvoiceRequesButtonContainer, viewingInvoiceRequestModal } =
    useViewInvoiceRequest();

  const { EditingInvoiceRequestButtonContainer, editingInvoiceRequestModal } =
    useEditInvoiceRequest({
      reloadTablePage,
    });

  const { ReopenRequestButtonContainer, reopenRequestModal } =
    useReopenInvoiceRequest({
      reloadTablePage,
    });

  const { CancellationInvoiceRequestButton, cancellationInvoiceRequestModal } =
    useCancelationInvoiceRequest({
      reloadTablePage,
      reloadInvoiceSummary,
    });

  const generateDeleteButton = useCallback(
    (invoice: InvoiceDTO) => {
      if (
        [InvoicesStatusEnum.NotReleased, InvoicesStatusEnum.Released].includes(
          invoice.status
        )
      ) {
        return (
          <Protected
            restrictions={{
              type: PermissionType.Invoice,
              level: PermissionLevel.Delete,
            }}
          >
            <DeleteInvoiceTableButton
              invoice={invoice}
              DeleteButtonContainer={DeleteButtonContainer}
            />
          </Protected>
        );
      }

      if (
        canEditAllInvoices &&
        ![
          InvoicesStatusEnum.Canceled,
          InvoicesStatusEnum.ProvisionForCancellation,
        ].includes(invoice.status)
      ) {
        return (
          <DeleteInvoiceTableButton
            invoice={invoice}
            DeleteButtonContainer={DeleteButtonContainer}
          />
        );
      }

      return "";
    },
    [canEditAllInvoices]
  );

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("invoice.keywords.fields.invoiceMilestone"),
      {
        value: "",
        HeadRenderer: () =>
          sumInvoicesFiltered ? (
            <div>
              <span>{t("invoice.keywords.fields.billingAmount")}</span>
              <StyledSumActivities>
                <div>{t("general.keywords.fields.total")}</div>
                <div>
                  <FormattedCurrency
                    value={sumInvoicesFiltered}
                    currencySymbol={project.subsidiary.currencySymbol}
                  />
                </div>
              </StyledSumActivities>
            </div>
          ) : (
            <span>{t("general.keywords.fields.value")}</span>
          ),
      },
      t("general.keywords.fields.percentage"),
      t("Baseline"),
      t("invoice.keywords.fields.plannedDate"),
      t("invoice.keywords.fields.billingDate"),
      t("general.keywords.fields.status"),
      {
        value: "",
        width: accessMode === "writing" ? 182 : 80,
        canSort: false,
      },
    ];

    return headCells;
  }, [t, accessMode, sumInvoicesFiltered, filters, canEditAllInvoices]);

  const generateObservationsColumn = (invoice: InvoiceDTO) => {
    const observationsPairs = [
      {
        translationCode: "general.keywords.fields.status",
        value: t(invoicesStatusTypeToTranslationCode[invoice.status]),
      },
      {
        translationCode: "invoice.keywords.fields.invoiceNumber",
        value: invoice.invoiceNumber,
      },
    ];

    const observations = observationsPairs
      .filter((x) => !!x.value)
      .map((x) => `${t(x.translationCode)}: ${x.value}.`)
      .join(" ");

    const formattedObservations =
      observations.length <= 60
        ? observations || "-"
        : `${observations.slice(0, 60).trim()}...`;

    const shouldShowObservationsButton =
      observations.length > 0 || !!invoice.delayJustification;

    return {
      value: formattedObservations,
      CellRenderer: () => (
        <StyledValueWithNegativeAmount>
          <div>
            {shouldShowObservationsButton && (
              <ViewingObservationsButton {...invoice} />
            )}
            <span>{formattedObservations}</span>
          </div>
          {invoice.negativeInvoicesCancellationReference.length > 0 &&
            invoice.negativeInvoicesCancellationReference.map(
              (negativeInvoice, i) => (
                <StyledNegativeValue key={i}>
                  Status:{" "}
                  {t(
                    invoicesStatusTypeToTranslationCode[negativeInvoice.status]
                  )}
                </StyledNegativeValue>
              )
            )}
        </StyledValueWithNegativeAmount>
      ),
    };
  };

  const singlePageLoader =
    useCallback<ExternalEnhancedTableExternalSinglePageLoader>(async () => {
      try {
        const invoices = await getInvoices({
          filters,
        });

        // 'relevantFilters' is an array of the object 'filters' without idProject and any filter not currently active
        const relevantFilters = Object.entries(
          (({ idProject, ...object }) => object)(filters)
        ).filter((object) => object[1] !== undefined);

        if (relevantFilters.length > 0) {
          const filteredInvoices = invoices.filter((invoice) => {
            return relevantFilters
              .map((filter) => {
                switch (filter[0]) {
                  case "earliestIssueDate":
                    if (
                      invoice.issueDate == null ||
                      invoice.issueDate.toDate() < filter[1]
                    )
                      return false;
                    break;
                  case "latestIssueDate":
                    if (
                      invoice.issueDate == null ||
                      invoice.issueDate.toDate() > filter[1]
                    )
                      return false;
                    break;
                  case "earliestPlannedBillingDate":
                    if (invoice.plannedBillingDate.toDate() < filter[1]) {
                      return false;
                    }
                    break;
                  case "latestPlannedBillingDate":
                    if (invoice.plannedBillingDate.toDate() > filter[1])
                      return false;
                    break;
                }
                return true;
              })
              .every((filter) => filter === true);
          });

          const filteredInvoicesTotalBillingAmount = filteredInvoices
            .map((invoice) => invoice.billingAmount)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
          setSumInvoicesFiltered(filteredInvoicesTotalBillingAmount);
        } else {
          setSumInvoicesFiltered(null);
        }

        const rows = invoices
          .filter((x) => x.status !== InvoicesStatusEnum.AmountCanceled)
          .map((invoice): ExternalEnhancedRow => {
            const percentage =
              (invoice.billingAmount / project.billingAmountWithAdditives) *
              100;

            const row: ExternalEnhancedRow = {
              id: invoice.id,
              rowInfo: invoice,
              cells: [
                invoice.milestone,
                {
                  value: invoice.billingAmount,
                  CellRenderer: () => (
                    <StyledValueWithNegativeAmount>
                      <span>
                        {formatCurrency({
                          value: invoice.billingAmount,
                          currencySymbol:
                            invoice.costCenterPep.subsidiary.currencySymbol,
                        })}
                      </span>
                      {invoice.negativeInvoicesCancellationReference.length >
                        0 &&
                        invoice.negativeInvoicesCancellationReference.map(
                          (negativeInvoice, i) => (
                            <StyledNegativeValue key={i}>
                              {formatCurrency({
                                value: negativeInvoice.billingAmount,
                                currencySymbol:
                                  invoice.costCenterPep.subsidiary
                                    .currencySymbol,
                              })}
                            </StyledNegativeValue>
                          )
                        )}
                    </StyledValueWithNegativeAmount>
                  ),
                },
                {
                  value: percentage,
                  CellRenderer: () => (
                    <StyledValueWithNegativeAmount>
                      {project.billingAmountWithAdditives > 0 &&
                      ![
                        InvoicesStatusEnum.AmountCanceled,
                        InvoicesStatusEnum.CancellationAnalysis,
                        InvoicesStatusEnum.ProvisionForCancellation,
                        InvoicesStatusEnum.Canceled,
                      ].includes(invoice.status)
                        ? `${formatNumber(percentage, {
                            fractionDigits: 2,
                          })}%`
                        : "-"}
                      {invoice.negativeInvoicesCancellationReference.length >
                        0 &&
                        invoice.negativeInvoicesCancellationReference.map(
                          (negativeInvoice, i) => (
                            <StyledNegativeValue key={i}>-</StyledNegativeValue>
                          )
                        )}
                    </StyledValueWithNegativeAmount>
                  ),
                },
                {
                  value: invoice.baselineDate,
                  displayValue: invoice.baselineDate
                    ? Intl.DateTimeFormat(i18n.language).format(
                        invoice.baselineDate
                      )
                    : "-",
                },
                {
                  value: invoice.plannedBillingDate.toDate(),
                  CellRenderer: () => (
                    <StyledValueWithNegativeAmount>
                      {invoice.plannedBillingDate
                        ? Intl.DateTimeFormat(i18n.language).format(
                            invoice.plannedBillingDate.toDate()
                          )
                        : "-"}
                      {invoice.negativeInvoicesCancellationReference.length >
                        0 &&
                        invoice.negativeInvoicesCancellationReference.map(
                          (negativeInvoice, i) => (
                            <StyledNegativeValue key={i}>-</StyledNegativeValue>
                          )
                        )}
                    </StyledValueWithNegativeAmount>
                  ),
                },
                {
                  value: invoice.issueDate?.toDate(),
                  CellRenderer: () => {
                    const {
                      EditingIssuedDateButtonContainer,
                      editingIssuedDateModal,
                    } = useEditingIssuedDate({ reloadTablePage, invoice });
                    return [
                      InvoicesStatusEnum.Issued,
                      InvoicesStatusEnum.ProvisionForCancellation,
                      InvoicesStatusEnum.Canceled,
                      InvoicesStatusEnum.PaidOut,
                      InvoicesStatusEnum.CancellationAnalysis,
                    ].includes(invoice.status) && canEditAllInvoices ? (
                      <>
                        <CellContent
                          EditingButtonContainer={
                            EditingIssuedDateButtonContainer
                          }
                          invoice={invoice}
                        />
                        {editingIssuedDateModal}
                      </>
                    ) : (
                      <StyledValueWithNegativeAmount>
                        {invoice.issueDate
                          ? Intl.DateTimeFormat(i18n.language).format(
                              invoice.issueDate.toDate()
                            )
                          : "-"}
                        {invoice.negativeInvoicesCancellationReference.length >
                          0 &&
                          invoice.negativeInvoicesCancellationReference.map(
                            (negativeInvoice, i) => (
                              <StyledNegativeValue key={i}>
                                {negativeInvoice.issueDate
                                  ? Intl.DateTimeFormat(i18n.language).format(
                                      negativeInvoice.issueDate.toDate()
                                    )
                                  : "-"}
                              </StyledNegativeValue>
                            )
                          )}
                      </StyledValueWithNegativeAmount>
                    );
                  },
                },
                generateObservationsColumn(invoice),
              ],
              CustomTableRow: [
                InvoicesStatusEnum.Pdd,
                InvoicesStatusEnum.Loss,
              ].includes(invoice.status)
                ? NegativeTableRow
                : undefined,
            };

            if (accessMode === "writing")
              row.cells.push({
                CellRenderer: () => (
                  <>
                    {[
                      InvoicesStatusEnum.NotReleased,
                      InvoicesStatusEnum.Returned,
                    ].includes(invoice.status) ||
                    (canEditAllInvoices &&
                      ![
                        InvoicesStatusEnum.ProvisionForCancellation,
                        InvoicesStatusEnum.Canceled,
                        InvoicesStatusEnum.AmountCanceled,
                      ].includes(invoice.status)) ? (
                      <EditingButtonContainer {...invoice} />
                    ) : (
                      ""
                    )}

                    {invoice.status === InvoicesStatusEnum.NotReleased &&
                    !invoice.wasEdited &&
                    ![ProjectStatus.Created, ProjectStatus.Planning].includes(
                      project.status
                    ) ? (
                      <RequestButton {...invoice} />
                    ) : (
                      ""
                    )}

                    {[InvoicesStatusEnum.Released].includes(invoice.status) && (
                      <ReopenRequestButtonContainer {...invoice} />
                    )}

                    {[
                      InvoicesStatusEnum.Released,
                      InvoicesStatusEnum.Analysis,
                      InvoicesStatusEnum.Issued,
                      InvoicesStatusEnum.ProvisionForCancellation,
                      InvoicesStatusEnum.Canceled,
                      InvoicesStatusEnum.PaidOut,
                      InvoicesStatusEnum.CancellationAnalysis,
                      InvoicesStatusEnum.Pdd,
                      InvoicesStatusEnum.Loss,
                    ].includes(invoice.status) ? (
                      <ViewingInvoiceRequesButtonContainer {...invoice} />
                    ) : (
                      ""
                    )}

                    {[InvoicesStatusEnum.Returned].includes(invoice.status) ? (
                      <StyledRedIcon>
                        <EditingInvoiceRequestButtonContainer {...invoice} />
                      </StyledRedIcon>
                    ) : (
                      ""
                    )}

                    {[InvoicesStatusEnum.NotReleased].includes(
                      invoice.status
                    ) && invoice.wasEdited ? (
                      <EditingInvoiceRequestButtonContainer {...invoice} />
                    ) : (
                      ""
                    )}

                    {[
                      InvoicesStatusEnum.Issued,
                      InvoicesStatusEnum.PaidOut,
                    ].includes(invoice.status) ? (
                      <CancellationInvoiceRequestButton {...invoice} />
                    ) : (
                      ""
                    )}

                    {generateDeleteButton(invoice)}
                  </>
                ),
                align: "right",
                paddingmode: "horizontal",
              });

            if (
              accessMode === "reading" &&
              [
                InvoicesStatusEnum.Released,
                InvoicesStatusEnum.Analysis,
                InvoicesStatusEnum.Issued,
                InvoicesStatusEnum.ProvisionForCancellation,
                InvoicesStatusEnum.Canceled,
                InvoicesStatusEnum.PaidOut,
                InvoicesStatusEnum.CancellationAnalysis,
              ].includes(invoice.status)
            ) {
              row.cells.push({
                CellRenderer: () => (
                  <ViewingInvoiceRequesButtonContainer {...invoice} />
                ),
              });
            }

            return row;
          });

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [t, filters, accessMode, canEditAllInvoices, i18n]);

  return {
    headCells,
    singlePageLoader,
    deletionModal,
    viewingObservationsModal,
    editingModal,
    requestModal,
    viewingInvoiceRequestModal,
    editingInvoiceRequestModal,
    reopenRequestModal,
    cancellationInvoiceRequestModal,
  };
};
