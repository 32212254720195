import * as yup from "yup";

export type CodeErrorEnums =
  | General
  | ColaboratorHistory
  | CostCenters
  | JobRate
  | Customers
  | Projects
  | Frames
  | BrlParity
  | Translations
  | Subsidiaries
  | CostCenterPeps
  | Activities
  | Invoice
  | AllocationMap
  | Allocations
  | ContractRole
  | Additives
  | Expenses
  | ProjectSummary
  | ProjectPlanningValidation
  | ProjectBaselines
  | UserCollaborator
  | RevenueInvoices
  | CorporateAllocations
  | AccessGroups
  | UserGroup
  | UserPermission
  | Timesheet
  | CollaboratorHistory
  | Dashboards
  | Report
  | Projection
  | Budget
  | CostDistribution
  | CustomerBalance
  | Wacc
  | FeeYearMonth;

export enum General {
  Forbidden = "general.forbidden",
  UpdateConcurrencyError = "general.updateConcurrencyError",
  Validation = "general.validation",
  ValueToDeleteDoesNotExist = "general.valueToDeleteDoesNotExist",
  ValueToUpdateDoesNotExist = "general.valueToUpdateDoesNotExist",
  NotEmpty = "general.validation.notEmpty",
  MustBeEmpty = "general.validation.mustBeEmpty",
  DoesNotExist = "general.validation.doesNotExist",
  Required = "general.validation.required",
  ExactLength = "general.validation.exactLength",
  MinimumLength = "general.validation.minimumLength",
  MaximumLength = "general.validation.maximumLength",
  MustBePositive = "general.validation.mustBePositive",
  GreaterThanOrEqualTo = "general.validation.greaterThanOrEqualTo",
  LessThanOrEqualTo = "general.validation.lessThanOrEqualTo",
  InvalidEmail = "general.validation.invalidEmail",
  InvalidPhoneNumber = "general.validation.invalidPhoneNumber",
  InvalidCep = "general.validation.invalidCep",
  InvalidCnpj = "general.validation.invalidCnpj",
  ArrayCannotBeEmpty = "general.validation.arrayCannotBeEmpty",
  IdsToUpdateDoNotExist = "general.validation.idsToUpdateDoNotExist",
  LanguagesDoNotExist = "general.languages.doNotExist",
  DateOlderAcceptableLimit = "general.validation.dateOlderAcceptableLimit",
  DateLaterAcceptableLimit = "general.validation.dateLaterAcceptableLimit",
  InvalidDate = "general.validation.invalidDate",
  MutuallyExclusiveFields = "general.validation.mutuallyExclusiveFields",
  InvalidContentType = "general.validation.invalidContentType",
}

export enum Translations {
  DefaultTranslationCodeMissing = "translation.validation.defaultTranslationCodeMissing",
}

export enum ColaboratorHistory {
  JobRoleDependencyExists = "colaboratorHistory.jobRole.dependencyExists",
  CostCenterDependencyExists = "colaboratorHistory.costCenter.dependencyExists",
  CannotHaveTwoEntriesSameYearMonth = "collaboratorHistory.cannotHaveTwoEntriesSameYearMonth",
  UserCollaboratorDoesNotExist = "collaboratorHistory.userCollaborator.doesNotExist",
  StartYearMonthCannotBeforeAdmissionDate = "collaboratorHistory.startYearMonth.cannotBeforeAdmissionDate",
  CollaboratorHistoryStartYearMonthCannotBeforeCurrentDate = "collaboratorHistory.startYearMonth.cannotBeforeCurrentDate",
}

export enum CostCenters {
  DoesNotExist = "costCenters.doesNotExist",
  CodeAlreadyExists = "costCenters.code.alreadyExists",
  CodeInvalid = "costCenters.validation.codeInvalid",
  DivisionDoesNotExist = "costCenters.division.doesNotExist",
  SubsidiaryDoesNotExist = "costCenters.subsidiary.doesNotExist",
  CannotUpdateValuesWhenLinkedToProject = "costCenters.cannotUpdateValuesWhenLinkedToProject",
  HasAssociatedTimesheets = "costCenters.hasAssociatedTimesheets",
  HasAssociatedAllocation = "costCenters.hasAssociatedAllocation",
  HasAssociatedCollaboratorHistory = "costCenters.hasAssociatedCollaboratorHistory",
  HasAssociatedCorporateSegment = "costCenters.hasAssociatedCorporateSegment",
  HasAssociatedCostCenterPep = "costCenters.hasAssociatedCostCenterPep",
  HasAssociatedFrame = "costCenters.hasAssociatedFrame",
  HasAssociatedProjectionAdjustment = "costCenters.hasAssociatedProjectionAdjustment",
  HasAssociatedCustomerBalance = "costCenters.hasAssociatedCustomerBalance",
}

export enum Customers {
  CodeAlreadyExists = "customers.code.alreadyExists",
  CodeInvalid = "customers.validation.codeInvalid",
  CorporateNameAlreadyExists = "customers.corporateName.alreadyExists",
  TradingNameAlreadyExists = "customers.tradingName.alreadyExists",
  EmailAlreadyExists = "customers.email.alreadyExists",
  RegistrationCodeAlreadyExists = "customers.registrationCode.alreadyExists",
  StateCodeAlreadyExists = "customers.stateCode.alreadyExists",
  MunicipalCodeAlreadyExists = "customers.municipalCode.alreadyExists",
  CustomersDoesNotExist = "customers.doesNotExist",
  HasLinkedProjectsFrames = "customers.hasLinkedProjectsFrames",
}

export enum JobRate {
  JobRoleDependencyExists = "jobRates.jobRole.dependencyExists",
  JobRateAlreadyExists = "jobRates.jobRate.alreadyExists",
}

export enum Projects {
  DoesNotExist = "project.doesNotExist",
  CustomerDoesNotExistOrDependencyExists = "project.projectCustomer.doesNotExistOrDependencyExist",
  DivisionDoesNotExist = "project.division.doesNotExist",
  CustomerDoesNotExist = "project.customer.doesNotExist",
  CustomerCannotUpdateCurrentStage = "project.customer.cannotUpdateCurrentStage",
  SubsidiaryDoesNotExist = "project.subsidiary.doesNotExist",
  DivisionMustHavePrefix = "project.division.mustHavePrefix",
  DivisionCanOnlyUpdateOnCreated = "project.division.cannotUpdateCurrentStage",
  UserCoordinatorDoesNotExist = "project.userCoordinator.doesNotExist",
  UserManagerDoesNotExist = "project.userManager.doesNotExist",
  CfopDoesNotExist = "project.cfop.doesNotExist",
  ProductServiceDoesNotExist = "project.productService.doesNotExist",
  FrameDoesNotExistOrDependencyExists = "doesNotExistOrDependencyExists",
  CodeAlreadyExists = "project.code.alreadyExists",
  NameAlreadyExists = "project.name.alreadyExists",
  ProjectCodeInvalid = "project.validation.codeInvalid",
  FrameCustomerCodesDoNotMatch = "project.frame.customerCodesDoNotMatch",
  FrameDivisionsDoNotMatch = "project.frame.divisionsDoNotMatch",
  FrameCurrenciesDoNotMatch = "project.frame.currenciesDoNotMatch",
  ExceedsFrameAmount = "project.billingAmount.exceedsFrameAmount",
  FrameDoesNotMatchCostCenterPeps = "project.frame.doesNotMatchCostCenterPeps",
  ScheduledStartDateAfterScheduledEndDate = "project.scheduledStartDateAfterScheduledEndDate",
  PlanningMustHavePepWithSameSubsidiary = "project.planning.mustHavePepWithSameSubsidiary",
  CannotUpdatePlanningAfterPlanningStage = "project.cannotUpdatePlanningAfterPlanningStage",
  CannotUpdateFinalizedCanceledProjects = "project.cannotUpdateFinalizedCanceledProjects",
  CannotUpdateBlockedFieldsAfterPlanning = "project.cannotUpdateBlockedFieldsAfterPlanning",
  AmountCategoryNoMatches = "project.amountCategory.noMatches",
  FrameMustHaveSameSubsidiary = "project.frame.mustHaveSameSubsidiary",
  FrameMustHaveSameSubsidiaryAsFrameCostCenter = "project.frame.mustHaveSameSubsidiaryAsFrameCostCenter",
  ManagerDoesNotExist = "project.manager.doesNotExist",
  ManagerIsDeactivated = "project.manager.isDeactivated",
  CoordinatorDoesNotExist = "project.coordinator.doesNotExist",
  CoordinatorIsDeactivated = "project.coordinator.isDeactivated",
  ScheduledStartDateThereAreDatesBefore = "project.scheduledStartDate.thereAreDatesBefore",
  CannotReopenCurrentStatus = "project.cannotReopenCurrentStatus",
  SubsidiaryCannotUpdateCurrentStage = "project.subsidiary.cannotUpdateCurrentStage",
}

export enum Frames {
  CostCenterDoesNotExist = "frame.costCenter.doesNotExist",
  CustomerDoesNotExist = "frame.customer.doesNotExist",
  DivisionDoesNotExist = "frame.division.doesNotExist",
  InvalidPepCode = "frames.pepCode.invalid",
  DivisionDoesNotMatch = "frames.costCenter.divisionDoesNotMatch",
  SubsidiaryDoesNotMatch = "frames.costCenter.subsidiaryDoesNotMatch",
  InvalidSubsidiaryPrefixLength = "frames.subsidiary.invalidPrefixLength",
  InvalidDivisionPrefixLength = "frames.division.invalidPrefixLength",
  DeactivatedCostCenters = "frames.costCenters.deactivated",
  AmountInvalid = "frames.validation.amountInvalid",
  StartDateAfterEndDate = "frames.startDateAfterEndDate",
  FrameDoesNotExist = "frames.doesNotExist",
  CannotUpdateValuesWhenLinkedToProject = "frames.cannotUpdateValuesWhenLinkedToProject",
  FrameAlreadyExists = "frames.alreadyExists",
}

export enum Subsidiaries {
  SubsidiaryDoesNotExist = "subsidiary.doesNotExist",
}

export enum CostCenterPeps {
  DoesNotExist = "costCenterPep.doesNotExist",
  SubsidiaryDoesNotExist = "costCenterPep.subsidiary.doesNotExist",
  CostCenterDoesNotExist = "costCenterPep.costCenter.doesNotExist",
  ProjectDoesNotExist = "costCenterPep.project.doesNotExist",
  PepCodeInvalid = "costCenterPep.pepCode.invalid",
  CostCenterDivisionDifferentFromProject = "costCenterPep.costCenter.divisionDifferentFromProject",
  CostCenterSubsidiaryDoesNotMatch = "costCenterPep.costCenter.subsidiaryDoesNotMatch",
  CostCenterDeactivated = "costCenterPep.costCenter.deactivated",
  DivisionInvalidPrefixLength = "costCenterPep.division.invalidPrefixLength",
  SubsidiaryInvalidPrefixLength = "costCenterPep.subsidiary.invalidPrefixLength",
  CannotTaxWithoutBrazilianSubsidiary = "costCenterPep.costCenter.cannotTaxWithoutBrazilianSubsidiary",
  CannotUpdateTheseFieldsAfterCreated = "costCenterPep.general.cannotUpdateTheseFieldsAfterCreated",
  CannotUpdateTheseFieldsAfterPlanning = "costCenterPep.general.cannotUpdateTheseFieldsAfterPlanning",
  CannotUpdateMainPepSubsidiaryAfterCreated = "costCenterPep.general.cannotUpdateMainPepSubsidiaryAfterCreated",
  ProjectSubsidiaryComboAlreadyExists = "costCenterPep.multiple.projectAndSubsidiaryComboAlreadyExists",
  PepCodeDoesNotMatchFrame = "costCenterPep.pepCode.doesNotMatchFrame",
  CostCenterDoesNotMatchFrame = "costCenterPep.costCenter.doesNotMatchFrame",
  SubsidiaryDoesNotMatchFrame = "costCenterPep.subsidiary.doesNotMatchFrame",
  CannotDeleteMainProjectPepAfterPlanning = "costCenterPep.cannotDeleteMainProjectPepAfterPlanning",
  CannotDeleteWhenDependedUpon = "costCenterPep.cannotDeleteWhenDependedUpon",
  CfopDoesNotExist = "costCenterPep.cfop.doesNotExist",
  CostCenterPepBrCfopCanNotBeNull = "costCenterPep.CostCenterPepBrCfopCanNotBeNull",
}

export enum Activities {
  CostCenterPepDoesNotExist = "activities.costCenterPep.doesNotExist",
  DeprovisionTypeDoesNotExist = "activities.deprovisionType.doesNotExist",
  CannotCreateWhenNoMainProjectPep = "activities.cannotCreateWhenNoMainProjectPep",
  ScheduledDateMustBeGreaterOrEqualThanMonth = "activities.scheduledDate.mustBeGreaterOrEqualThanMonth",
  CannotDeleteWhenClosed = "activities.cannotDeleteWhenClosed",
  ScheduledDateMustBeGreaterOrEqualThanProjectStartDate = "activities.scheduledDate.mustBeGreaterOrEqualThanProjectStartDate",
  ProjectMissingScheduledStartDate = "activities.projectMissingScheduledStartDate",
  AlreadyClosed = "activities.alreadyClosed",
  AlreadyOpen = "activities.alreadyOpen",
  CannotReopenBeforeCurrentMonth = "activities.cannotReopenBeforeCurrentMonth",
  CannotWriteCurrentStage = "activities.cannotWriteCurrentStage",
  CompletedDateMustBeGreaterOrEqualThanProjectStartDate = "activities.completedDate.mustBeGreaterOrEqualThanProjectStartDate",
  CompletedDateNotAllowed = "activities.completedDate.notAllowed",
  ActivityDoesNotExist = "activities.activityDoesNotExist",
  AmountMakesBalanceNegativeAtLeastOneYearMonth = "activities.amountMakesBalanceNegativeAtLeastOneYearMonth",
  CannotUpdateClosedActivity = "activities.cannotUpdateClosedActivity",
  CannotReopenDeprovision = "activities.cannotReopenDeprovision",
  ScheduledDateDeprovisionCannotUpdate = "activities.scheduledDate.deprovisionCannotUpdate",
  ScheduledDateDeprovisionMustBeLessOrEqualThanToday = "activities.scheduledDate.deprovisionMustBeLessOrEqualThanToday",
  AmountCategoryInvalidLimit = "activities.amountCategory.invalid",
  AmountGreaterThanProjectLimit = "activities.amount.greaterThanProjectLimit",
  AmountExceedsProjectBudget = "activities.amount.exceedsProjectBudget",
  ProjectDoesNotExist = "activities.project.doesNotExist",
  ActivitiesCompletedDateMustBeLessOrEqualBaseLineEndDate = "activities.completedDate.mustBeLessOrEqualThanBaseLineEndDate",
  ActivitiesCannotDeleteWhenHasAllocationInThisPeriod = "activities.activitiesCannotDeleteWhenHasAllocationInThisPeriod",
  ActivitiesCannotDeleteWhenHasTimesheetRelated = "activities.activitiesCannotDeleteWhenHasTimesheetRelated",
}

export enum Invoice {
  InvoiceCannotWriteCurrentStage = "invoice.cannotWriteCurrentStage",
  CostCenterPepDoesNotExist = "invoices.costCenterPep.doesNotExist",
  CannotCreateWhenNoMainProjectPep = "invoices.cannotCreateWhenNoMainProjectPep",
  PlannedBillingDateCannotBeforeCurrentMonth = "invoices.plannedBillingDate.cannotBeforeCurrentMonth",
  PlannedBillingDateCannotBeforeProjectStart = "invoices.plannedBillingDate.cannotBeforeProjectStart",
  InvoicesPlannedBillingDateAfterProjectEndDelayJustificationRequired = "invoices.plannedBillingDate.afterProjectEndDelayJustificationRequired",
  InvoicesCannotHaveNegativeAmount = "invoices.cannotHaveNegativeAmount",
  CannotWriteBeforePlanning = "invoices.cannotWriteBeforePlanning",
  CannotDeleteIssuedInvoice = "invoices.cannotDeleteIssuedInvoice",
  IssueDateCannotBeBeforeCurrentMonth = "invoices.issueDate.cannotBeBeforeCurrentMonth",
  IssueDateAfterProjectEndDelayJustificationRequired = "invoices.issueDate.afterProjectEndDelayJustificationRequired",
  InvoicesUpdateStatusReleased = "invoices.status.updateToReleased",
  LimitDateRequestDateAreRequired = "invoices.LimitDateAndRequestDate.areRequerided",
  InvoicesUpdateStatusReturned = "invoices.status.updateToReturned",
  InvoiceNoteIsRequired = "invoices.invoiceNote.isRequired",
  InvoicesUpdateStatusAnalysis = "invoices.status.updateToAnalysis",
  InvoicesUpdateStatusIssued = "invoices.status.updateToIssued",
  InvoicesUpdateToIssuedMissingFields = "invoices.IssuedFields.missingFields",
  InvoicesUpdateToPaidOutMissingDueDate = "invoices.status.updateToPaidOutMissingDueDate",
  InvoicesUpdateStatusPaidOut = "invoices.status.updateToPaidOut",
  InvoicesUpdateStatusCancellation = "invoices.status.updateToCancellation",
  InvoicesUpdateStatusProvisionCancellation = "invoices.status.updateToProvisionCancellation",
  InvoicesUpdateToCancellationMissingFields = "invoices.CancellationFields.missingFields",
  InvoicesUpdateToProvisionCancellationMissingFields = "invoices.ProvisionCancellationFields.missingFields",
  InvoicesPaymentDateShouldBeAfterIssuedDate = "invoices.paymentDate.shouldBeAfterIssuedDate",
  InvoicesDueDateShouldBeAfterIssuedDate = "invoices.DueDate.shouldBeAfterIssuedDate",
  InvoicesStatusUpdateToNotReleased = "invoices.status.updateToNotReleased",
  LiquidValueMustBeLessThanBillingAmount = "invoices.status.liquidValueMustBeLessThanBillingAmount",
  InvoicesUpdateStatusCancellationAnalysis = "invoices.status.updateToCancellationAnalysis",
  InvoiceBillingMustBeMoreThanZero = "invoices.BillingAmountMustBeMoreThanZero",
  InvoicesUpdateStatusLoss = "invoices.status.updateToLoss",
  InvoicesUpdateStatusPdd = "invoices.status.updateToPdd",
  InvoicesLossDateShouldBeAfterCurrentDate = "invoices.lossDate.shouldBeAfterCurrentDate",
  InvoicesPddDateShouldBeBeforeCurrentDate = "invoices.pddDate.shouldBeAfterCurrentDate",
}

export enum AllocationMap {
  OutOfBoundsPeriod = "allocationMap.outOfBoundsPeriod",
}

export enum Allocations {
  CostCenterDoesNotExist = "allocations.costCenter.doesNotExist",
  CostCenterPepDoesNotExist = "allocations.costCenterPep.doesNotExist",
  JobRoleDoesNotExist = "allocations.jobRole.doesNotExist",
  WritingMutuallyExclusiveFields = "allocations.writing.mutuallyExclusiveFields",
  PeriodRangeNotDefined = "allocations.period.rangeNotDefined",
  YearMonthOneOrMoreOutOfBoundsPeriod = "allocations.yearMonth.oneOrMoreOutOfBoundsPeriod",
  CannotWriteCurrentStage = "allocations.cannotWriteCurrentStage",
  AllocationAlreadyExists = "allocations.allocation.alreadyExist",
  UserColaboratorDoesNotExist = "allocations.userColaborator.doesNotExist",
  UserColaboratorDoesNotMatchJobRole = "allocations.userColaborator.doesNotMatchJobRole",
  YearMonthCannotBeforeCurrentMonth = "allocations.yearMonth.cannotBeforeCurrentMonth",
  PepDoesNotExist = "allocations.pepDoesNotExist",
  NoJobRolesToUpdateToUser = "allocations.noJobRolesToUpdateToUser",
  CannotReplaceExistingUserAllocation = "allocations.cannotReplaceExistingUserAllocation",
  PercentageWouldBecomeGreaterThanMonthlyLimit = "allocations.percentage.wouldBecomeGreaterThanMonthlyLimit",
  AllocationGroupDoesNotExist = "allocations.allocationGroup.doesNotExist",
  NotJobRoleAllocation = "allocations.notJobRoleAllocation",
  CollaboratorHistoryNoHistoryForPeriod = "allocations.colaboratorHistory.noHistoryForPeriod",
  CollaboratorCannotAllocateAfterResignation = "allocations.cannotAllocateAfterResignation",
  CollaboratorHistoryNoHistoryFound = "allocations.colaboratorHistory.noHistoryFound",
  OfficeAndPepSubsidiariesDoNotMatchForPeriod = "allocations.officeAndPepSubsidiariesDoNotMatchForPeriod",
  PercentageCannotBeZeroOnCreate = "allocations.percentage.cannotBeZeroOnCreate",
  IdUserCollaboratorJobRolesForPeriodDoNotMatch = "allocations.idUserCollaborator.jobRolesForPeriodDoNotMatch",
  YearMonthOutOfBoundsPeriod = "allocations.yearMonth.outOfBoundsPeriod",
  MonthlyAllocationIsClosing = "uploadCorporateAllocation.modal.data.errorMensagens.monthlyAllocationClosed",
  AvoidCreateAllocationWithTwoOrMoreHistory = "allocations.avoidCreateAllocationWithTwoOrMoreHistory",
  AllocationsYearMonthOneOrMoreOutAfterScheduledEndDate = "allocations.yearMonth.oneOrMoreOutAfterScheduledEndDate",
  AllocationsCannotEditWhenHasNotActivities = "allocations.cannotEditWhenHasNotActivities",
}

export enum ContractRole {
  ContrctRoleDependencyExists = "colaboratorHistory.contractRole.dependencyExists",
}

export enum Additives {
  CannotSubmitCurrentState = "additives.cannotSubmitCurrentState",
  ProjectDoesNotExist = "additives.project.doesNotExist",
  ProjectPlanningHasNotStarted = "additives.project.planningHasNotStarted",
  AmountMakesProjectAmountNegative = "additives.amount.makesProjectAmountNegative",
  AmountLessThanActivitiesAmount = "additives.amount.lessThanActivitiesAmount",
  AnticipationJustificationRequired = "additives.anticipationJustification.required",
  CannotSubmitWithAdditiveWithoutApprovedCredit = "additives.cannotSubmitWithAdditiveWithoutApprovedCredit",
  AdditivesMvProjectRequired = "deadlineAdditives.modal.error.MvRequired",
  AdditivesScheduledStartDateCannotBeLessThanProjectStartDate = "deadlineAdditives.modal.error.scheduledStartDateLessThanProjectDate",
  AdditivesUserCollaboratorNotFound = "deadlineAdditives.modal.error.userCollaboratorNotFound",
}

export enum Expenses {
  CostCenterPepDoesNotExist = "expenses.costCenterPep.doesNotExist",
  CannotWriteCurrentStage = "expenses.cannotWriteCurrentStage",
  YearMonthOutOfBoundsPeriod = "expenses.yearMonth.outOfBoundsPeriod",
  AmountCannotBeZeroOnCreate = "expenses.amount.cannotBeZeroOnCreate",
  ExpenseTypeCannotModifyExpense = "expenses.expenseType.cannotModifyExpense",
  YearMonthCannotBeforeCurrentMonth = "expenses.yearMonth.cannotBeforeCurrentMonth",
  PerformedExpensesUploadLedgerAccountThereIsNoPerformedExpense = "performedExpenses.uploadLedgerAccount.thereIsNoPerformedExpense",
}

export enum ProjectSummary {
  MainPepDoesNotExist = "projectSummary.mainPep.doesNotExist",
  BrlParityDoesNotExist = "projectSummary.brlParity.doesNotExist",
}

export enum ProjectPlanningValidation {
  CannotWriteCurrentStage = "projectPlanningValidation.cannotWriteCurrentStage",
  DelayJustificationRequired = "projectPlanningValidation.delayJustification.required",
  ProjectDoesNotExist = "projectPlanningValidation.project.doesNotExist",
  InvalidProject = "projectPlanningValidation.invalidProject",
  MainPepDoesNotExist = "projectPlanningValidation.mainPep.doesNotExist",
  AmountCategoryConfigurationDoesNotExist = "projectPlanningValidation.amountCategoryConfiguration.doesNotExist",
  CannotFinishUnsavedBaseline = "projectPlanningValidation.cannotFinishUnsavedBaseline",
  PlanningAlreadySaved = "projectPlanningValidation.planningAlreadySaved",
}

export enum ProjectBaselines {
  ProjectDoesNotExist = "projectBaselines.project.doesNotExist",
}

export enum UserCollaborator {
  DependencyExists = "userCollaborator.dependency.dependencyExists",
  LoginAlreadyExists = "userCollaborator.login.alreadyExists",
  DateMustBeGreaterThanCurrentOne = "userCollaborator.startYearMonth.mustBeGreaterThanCurrentOne",
  RegistryNumberAlreadyExist = "userCollaborator.registryNumber.exist",
  LoginCannotUpdateWhenUserInnactive = "userCollaborator.login.cannotUpdateWhenUserInnactive",
  workloadDependencyExists = "userColaborator.workload.dependencyExists",
  DisciplineDependencyExists = "userColaborator.discipline.dependencyExists",
}

export enum RevenueInvoices {
  MainPepDoesNotExist = "revenueInvoices.mainPep.doesNotExist",
}

export enum BrlParity {
  BrlParityAlreadyExists = "brlParity.brlParity.alreadyExists",
}

export enum CorporateAllocations {
  CannotReplaceExistingAllocations = "corporateAllocations.cannotReplaceExistingAllocations",
  AllocationTypeCannotBeTypeAllocation = "corporateAllocations.allocationType.cannotBeTypeAllocation",
  YearMonthCannotBeforeCurrentMonth = "corporateAllocations.yearMonth.cannotBeforeCurrentMonth",
  CollaboratorEntryDoesNotExistForYearMonth = "corporateAllocations.collaboratorEntry.doesNotExistForYearMonth",
  CostCenterDoesNotExist = "corporateAllocations.costCenter.doesNotExist",
  PercentageWouldBecomeGreaterThanMonthlyLimit = "corporateAllocations.percentage.wouldBecomeGreaterThanMonthlyLimit",
  CollaboratorCannotAllocateAfterResignation = "corporateAllocations.cannotAllocateAfterResignation",
  CollaboratorHistoryNoHistoryFound = "corporateAllocations.colaboratorHistory.noHistoryFound",
  CollaboratorHistoryNoHistoryForPeriod = "corporateAllocations.colaboratorHistory.noHistoryForPeriod",
  AllocationGroupIsNotCorporateAllocation = "corporateAllocations.allocationGroup.isNotCorporateAllocation",
  UserCollaboratorDiferentSubsidiary = "uploadCorporateAllocation.modal.data.errorMensagens.collaboratorDiferentSubsidiary",
  costCenterForAllowance = "uploadCorporateAllocation.modal.data.errorMensagens.costCenterForAllowance",
  NoPermissionToUseThatAllocation = "uploadProjectAllocation.modal.data.errorMensagens.noPermissionToUseThatAllocation",
  NoPermissionToAccessCalamityCorporateSegment = "uploadProjectAllocation.modal.data.errorMensagens.noPermissionToAccessCalamityCorporateSegment",
  NoPermissionToAccessHECompensationCorporateSegment = "uploadProjectAllocation.modal.data.errorMensagens.noPermissionToAccessHECompensationCorporateSegment",
}

export enum AccessGroups {
  accessGroupDependencyExists = "accessGroupPermission.accessGroup.dependencyExists",
  accessGroupAlreadyExists = "accessGroup.name.alreadyExists",
}

export enum UserGroup {
  userCollaboratorDependencyExists = "userGroup.userCollaborator.dependencyExists",
  userGroupDependencyExists = "userGroup.accessGroup.dependencyExists",
  userAlreadyIncludeInAccessGroup = "userGroup.alreadyExists",
}

export enum UserPermission {
  userCollaboratorDependencyExists = "userPermission.userCollaborator.dependencyExists",
  userCollaboratorPermissionAlreadyExists = "userPermission.alreadyExists",
}

export enum Timesheet {
  UserCollaboratorDoesNotExist = "timesheet.userCollaborator.doesNotExist",
  CostCenterDoesNotExist = "timesheet.costCenter.doesNotExist",
  ProjectDoesNotExist = "timesheet.project.doesNotExist",
  CreateMutuallyExclusiveFields = "timesheets.create.mutuallyExclusiveFields",
  CreateAlreadyExistsForMonth = "timesheets.create.alreadyExistsForMonth",
  DeleteNoItemsFound = "timesheets.delete.noItemsFound",
  MinutesMakesTotalForDayGreaterThanDay = "timesheets.minutes.makesTotalForDayGreaterThanDay",
  CostCenterNoCollaboratorEntryToGetCostCenter = "timesheets.costCenter.noCollaboratorEntryToGetCostCenter",
  TimesheetsCannotInsertAfterCurrentDay = "timesheets.create.cannotInsertOnDatesAfterTheCurrentDay",
}

export enum CollaboratorHistory {
  RegistryNumberCannotBeSameAsAnyIntern = "collaboratorHistory.registryNumber.cannotBeSameAsAnyIntern",
  CollaboratorIsAnIntern = "uploadCollaboratorHistory.modal.data.errorMensagens.effectiveRoleIntern",
}

export enum Dashboards {
  DashboardDependencyExists = "dashboard.dashboardGroup.doesNotExist",
}

export enum Report {
  ProjectsLimitToNotHaveTimeout = "report.provisionReport.projectsLimitToNotHaveTimeout",
}

export enum Projection {
  ProjectionYearMonthAlreadyExists = "projection.yearMonth.alreadyExists",
}

export enum Budget {
  BudgetRoundDoesNotExist = "budgetRound.doesNotExist",
  BudgetDoesNotExist = "budget.doesNotExist",
  BudgetRoundBeginDateMustBeLessThanEndDate = "budgetRound.date.beginDateMustBeLessThanEndDate",
  BudgetRoundBeginDateAndEndDateMustBeInTheSameYear = "budgetRound.date.budgetRoundBeginDateAndEndDateMustBeInTheSameYear",
  TheRangeRoundMustNotOverlapAnother = "budgetRound.date.theRangeRoundMustNotOverlapAnother",
}

export enum CostDistribution {
  CostDistributionAlreadyExists = "costDistribution.alreadyExists",
}

export enum CustomerBalance {
  AmountMustBeGreaterThanZero = "customerBalance.amount.mustBeGreaterThanZero",
  CostCenterParameterNotFound = "customerBalance.costCenterParameter.notFound",
  SegmentGroupNotRelatedToCostCenter = "customerBalance.segmentGroup.notRelatedToCostCenter",
  CustomerBalanceNotFound = "customerBalance.notFound",
  CustomerBalanceCannotBeDeleted = "customerBalance.cannotBeDeleted",
  CustomerBalanceAlreadyExists = "customerBalance.alreadyExists",
}

export enum Wacc {
  WaccAlreadyExists = "wacc.alreadyExists",
  WaccNotFound = "wacc.notFound",
}

export enum FeeYearMonth {
  FeeYearMonthAlreadyExists = "feeYearMonth.alreadyExist",
}

export const schemaCodeErrorEnums = yup
  .mixed<CodeErrorEnums>()
  .oneOf(
    Object.values([
      ...Object.values(ColaboratorHistory),
      ...Object.values(CostCenters),
      ...Object.values(Customers),
      ...Object.values(General),
      ...Object.values(Projects),
      ...Object.values(Frames),
      ...Object.values(UserGroup),
      ...Object.values(AccessGroups),
      ...Object.values(BrlParity),
      ...Object.values(Translations),
      ...Object.values(Subsidiaries),
      ...Object.values(CostCenterPeps),
      ...Object.values(JobRate),
      ...Object.values(Activities),
      ...Object.values(Invoice),
      ...Object.values(AllocationMap),
      ...Object.values(Allocations),
      ...Object.values(ContractRole),
      ...Object.values(Additives),
      ...Object.values(Expenses),
      ...Object.values(ProjectSummary),
      ...Object.values(ProjectPlanningValidation),
      ...Object.values(ProjectBaselines),
      ...Object.values(UserCollaborator),
      ...Object.values(UserPermission),
      ...Object.values(RevenueInvoices),
      ...Object.values(CorporateAllocations),
      ...Object.values(Timesheet),
      ...Object.values(CollaboratorHistory),
      ...Object.values(Dashboards),
      ...Object.values(Report),
      ...Object.values(Projection),
      ...Object.values(Budget),
      ...Object.values(CostDistribution),
      ...Object.values(CustomerBalance),
      ...Object.values(Wacc),
      ...Object.values(FeeYearMonth),
    ]) as CodeErrorEnums[]
  );
