import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTimesheetRecurrenceFormikConfig } from "./useFormikConfig";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { TimesheetRecurrenceModifyDTO } from "../../../../../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceModifyDTO";
import { EmployeeAllocationType } from "../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { parseHourStringToMinutes } from "../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { mofifyTimesheetRecurrennce } from "../../../../../../../services/timesheetRecurrence/timesheetRecurrence.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonTimesheetRecurrenceForm } from "../shared/components/CommonTimesheetRecurrenceForm";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useTimesheetRecurrenceFormikConfig({
    formMode: "create",
  });

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return <div>{t("timesheetRecurrence.keywords.actions.create")}</div>;
      },
      modalMode: "dialog",
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ ...rest }) => (
        <CommonTimesheetRecurrenceForm
          {...rest}
          mode="create"
          internalFormData={undefined}
        />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const corporateSegment = formValues.appointment?.corporateSegment;
          const costCenter = formValues.appointment?.costCenter;
          const appointmentAllocationType =
            formValues.appointment?.allocationType;

          const allocationType = formValues.project
            ? EmployeeAllocationType.Allocation
            : (appointmentAllocationType ??
              corporateSegment?.allocationType ??
              EmployeeAllocationType.Support);

          const formData: TimesheetRecurrenceModifyDTO = {
            idProject: formValues.project?.id,
            idCostCenter: costCenter?.id ?? corporateSegment?.costCenter?.id,
            idCorporateSegment: corporateSegment?.id,
            idPipedriveOrganization:
              allocationType === EmployeeAllocationType.Support
                ? formValues.pipedriveOrganization?.id
                : undefined,
            allocationType,
            minutes: parseHourStringToMinutes(formValues.time),
            recurrencesDay: formValues.days.map((day) => ({
              weekDay: day.id,
            })),
          };

          await mofifyTimesheetRecurrennce(formData);
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "timesheetRecurrence.errors.data.create.failedToCreate",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
