import { useFormikContext } from "formik";
import { useContext, useMemo } from "react";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { Week } from "../../../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { MaskedTextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { TimesheetCalendarContext } from "../shared/react/contexts/TimesheetCalendarContext";
import { DayNumber, TimeOtherProjects } from "./styles/general.styles";
import { StyledCalendarCell } from "./styles/StyledCalendarCell.styles";
import { ExtraTime } from "../../ExtraTime";

interface OwnProps {
  day: number;
  yearMonth: YearMonth;
}

export const CalendarCell = ({ day, yearMonth }: OwnProps) => {
  const { isSubmitting } = useFormikContext();
  const { name, isReadOnly } = useContext(TimesheetCalendarContext);

  const isWeekend = useMemo(() => {
    const weekDay = yearMonth.toDate(day).getDay();
    return weekDay === Week.Saturday || weekDay === Week.Sunday;
  }, [day, yearMonth]);

  return (
    <StyledCalendarCell isWeekend={isWeekend}>
      <DayNumber>{day}</DayNumber>
      <TimeOtherProjects>
        <ExtraTime field={`${name}[${day - 1}].extraTime`} day={day} />
      </TimeOtherProjects>
      <MaskedTextFieldFormik
        name={`${name}[${day - 1}].time`}
        overwrite="shift"
        mask="00:00"
        textFieldProps={{
          disabled: isSubmitting,
          InputProps: {
            readOnly: isReadOnly,
          },
          variant: "standard",
        }}
      />
    </StyledCalendarCell>
  );
};
