import { BottomNavigationAction, Paper, styled } from "@mui/material";

export const StyledBoxContainer = styled(Paper)`
  width: 235.94px;
  height: max-content;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
`;

export const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1)};
  padding-inline: 1.5rem;
`;
