import { AttachActivityDTO } from "../../../shared/specific/DTOs/timesheet/AttachActivityDTO";
import { AttachPipedriveOrganizationDTO } from "../../../shared/specific/DTOs/timesheet/AttachPipedriveOrganizationDTO";
import { TimesheetFiltersDTO } from "../../../shared/specific/DTOs/timesheet/filters/TimesheetFiltersDTO";
import { TimesheetMonthAllEntriesGetDTO } from "../../../shared/specific/DTOs/timesheet/queries/TimesheetMonthAllEntriesGetDTO";
import { TimesheetMonthSingleEntryGetDTO } from "../../../shared/specific/DTOs/timesheet/queries/TimesheetMonthSingleEntryGetDTO";
import { TimesheetDeleteDTO } from "../../../shared/specific/DTOs/timesheet/TimesheetDeleteDTO";
import { TimesheetModifyMonthDTO } from "../../../shared/specific/DTOs/timesheet/TimesheetModifyMonthDTO";
import {
  schemaTimesheetMonthDayAllEntriesDTO,
  TimesheetMonthDayAllEntriesDTO,
} from "../../../shared/specific/DTOs/timesheet/TimesheetMonthDayAllEntriesDTO";
import {
  schemaTimesheetMonthWithOthersDTO,
  TimesheetMonthWithOthersDTO,
} from "../../../shared/specific/DTOs/timesheet/TimesheetMonthWithOthersDTO";
import { TimesheetObservationDTO } from "../../../shared/specific/DTOs/timesheet/TimesheetObservationDTO";
import {
  schemaTimesheetsUserMonthDTO,
  TimesheetsUserMonthDTO,
} from "../../../shared/specific/DTOs/timesheet/TimesheetsUserMonthDTO";
import { riseApi } from "./connection";

export const getTimesheetsPerMonth = async ({
  filters,
}: {
  filters?: TimesheetFiltersDTO;
} = {}): Promise<TimesheetsUserMonthDTO> => {
  const { data: timesheetsPerMonth } = await riseApi.get("/timesheets/months", {
    params: filters,
  });

  return schemaTimesheetsUserMonthDTO
    .defined()
    .validateSync(timesheetsPerMonth);
};

export const getTimesheetMonthSingleEntry = async (
  getterDTO: TimesheetMonthSingleEntryGetDTO
): Promise<TimesheetMonthWithOthersDTO> => {
  const { data: timesheetMonth } = await riseApi.get(
    "/timesheets/month/SingleEntry",
    {
      params: getterDTO,
    }
  );

  return schemaTimesheetMonthWithOthersDTO
    .defined()
    .validateSync(timesheetMonth);
};

export const getTimesheetMonthAllEntries = async (
  getterDTO: TimesheetMonthAllEntriesGetDTO
): Promise<TimesheetMonthDayAllEntriesDTO> => {
  const { data: timesheetsMonth } = await riseApi.get(
    "/timesheets/month/AllEntries",
    {
      params: getterDTO,
    }
  );

  return schemaTimesheetMonthDayAllEntriesDTO.validateSync(timesheetsMonth);
};

export const mofifyTimesheet = async (creationDTO: TimesheetModifyMonthDTO) => {
  await riseApi.put("/timesheets", creationDTO);
};

export const mofifyTimesheetV2 = async (
  creationDTO: TimesheetModifyMonthDTO[]
) => {
  await riseApi.put("/timesheets", creationDTO);
};

export const attachActivity = async (attachDTO: AttachActivityDTO) => {
  await riseApi.put("/timesheets/AttachActivity", attachDTO);
};

export const attachPipedriveOrganization = async (
  attachDTO: AttachPipedriveOrganizationDTO
) => {
  await riseApi.put("/timesheets/AttachPipedriveOrganization", attachDTO);
};

export const saveTimesheetObservation = async (
  observation: TimesheetObservationDTO
) => {
  await riseApi.put("/timesheets/Observation", observation);
};

export const deleteTimesheet = async (deletionDTO: TimesheetDeleteDTO) => {
  await riseApi.delete("/timesheets", {
    data: deletionDTO,
  });
};
