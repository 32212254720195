import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { startOfMonth } from "date-fns";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { getTimesheetLineIdentification } from "../../../shared/helpers/data/timesheet/general.helpers";
import { formatMinutesToHourMinute } from "../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { useDeletionForm } from "../../../shared/hooks/useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useCreationForm } from "./useCreationForm";
import { StyledIdentificationCell } from "../shared/styles/common.styles";
import { useAttachPipedriveOrganization } from "./useAttachPipedriveOrganization";
import { EmployeeAllocationType } from "../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { useSaveTimesheetObservation } from "./useSaveTimesheetObservation";

export const useCostCenterTableData = () => {
  const { t, i18n } = useTranslation();

  const { timesheetData, canEditTimesheet, reloadTimesheetData, dateFilter } =
    useContext(TimesheetContext);

  const { EditingButton, editingModal } = useEditingForm();

  const { DeletionButtonContainer, deletionModal } = useDeletionForm();

  const {
    AttachPipedriveOrganizationButtonContainer,
    attachPipedriveOrganizationModal,
  } = useAttachPipedriveOrganization({
    reloadTablePage: reloadTimesheetData,
  });

  const { TimesheetObservationButtonContainer, timesheetObservationModal } =
    useSaveTimesheetObservation({
      reloadTablePage: reloadTimesheetData,
    });

  const { creationButton, creationModal } = useCreationForm({
    creationType: "costCenter",
    shouldShowButtonContained: false,
  });

  const isPastMonth = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    return startOfCurrentMonth > startDateFilter;
  }, [dateFilter]);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        HeadRenderer: () => (
          <div>
            {!isPastMonth && creationButton}{" "}
            {t("timesheets.keywords.general.nonProjectTime")}
          </div>
        ),
        value: t("timesheets.keywords.general.nonProjectTime"),
      },
      {
        value: "",
        width: 60,
      },
      {
        value: t("timesheets.keywords.fields.totalHoursMonth"),
        width: 300,
      },
      {
        value: "",
        width: 152,
      },
    ];

    return headCells;
  }, [t, dateFilter, isPastMonth]);

  const rows = useMemo(() => {
    const rows = timesheetData?.timesheetMonths
      .filter((x) => x.costCenter)
      .map((timesheetMonth) => {
        const row: ExternalEnhancedRow = [
          {
            CellRenderer: () => (
              <StyledIdentificationCell>
                <span>
                  {getTimesheetLineIdentification(timesheetMonth, t, i18n)}
                </span>
                {timesheetMonth.allocationType ===
                  EmployeeAllocationType.Support && (
                  <AttachPipedriveOrganizationButtonContainer
                    timesheetMonth={timesheetMonth}
                  >
                    {timesheetMonth.pipedriveOrganization?.name ??
                      t("timesheets.actions.general.associeteClientAccount")}
                  </AttachPipedriveOrganizationButtonContainer>
                )}
              </StyledIdentificationCell>
            ),
          },
          {
            CellRenderer: () => (
              <TimesheetObservationButtonContainer {...timesheetMonth} />
            ),
          },
          {
            value: timesheetMonth.minutesSum + timesheetMonth.extraMinutesSum,
            displayValue: formatMinutesToHourMinute(
              timesheetMonth.minutesSum + timesheetMonth.extraMinutesSum
            ),
          },
          {
            CellRenderer: () => {
              const appointmentFromAllocation =
                timesheetMonth.days.length === 0;
              return (
                <>
                  {canEditTimesheet && (
                    <>
                      <EditingButton {...timesheetMonth} />
                      {!appointmentFromAllocation && (
                        <DeletionButtonContainer {...timesheetMonth} />
                      )}
                    </>
                  )}
                </>
              );
            },
            align: "right",
            paddingmode: "horizontal",
          },
        ];

        return row;
      });

    return rows ?? [];
  }, [timesheetData, t]);

  return {
    headCells,
    rows,
    deletionModal,
    editingModal,
    creationModal,
    attachPipedriveOrganizationModal,
    timesheetObservationModal,
  };
};
