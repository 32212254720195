import { styled } from "@mui/material";

export const StyledForm = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledAppointmentFieldsArea = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1rem;
`;

export const StyledWeekChip = styled("div")`
  display: flex;
  gap: 5px;
`;
