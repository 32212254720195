import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { LoggedLayout } from "./layouts/LoggedLayout";
import { ControllershipPage } from "./pages/authenticated/Controllership/ControllershipPage";
import { Error404 } from "./pages/authenticated/errors/Error404";
import { Home } from "./pages/authenticated/Home";
import { CostCentersPage } from "./pages/authenticated/CostCenters/CostCentersPage";
import { CustomersPage } from "./pages/authenticated/Customers/CustomersPage";
import { Management } from "./pages/authenticated/Management/Management";
import { ProjectsMainPage } from "./pages/authenticated/Projects/ProjectsMainPage";
import { FramesMainPage } from "./pages/authenticated/Frames/FramesMainPage";
import { ProjectsCreationPage } from "./pages/authenticated/Projects/ProjectsCreationPage";
import { ProjectsViewingPage } from "./pages/authenticated/Projects/ProjectsViewingPage";
import { CostCenterPepsPage } from "./pages/authenticated/Projects/CostCenterPepsPage";
import { JobRolesPage } from "./pages/authenticated/JobRoles/JobRolesPage";
import { pushToPagesHistory } from "../services/applicationState/history.service";
import { ProjectPlanningPage } from "./pages/authenticated/Projects/ProjectPlanningPage";
import { JobRatesPage } from "./pages/authenticated/JobRates/JobRatesPage";
import { ContractRolesPage } from "./pages/authenticated/ContractRoles/ContractRolesPage";
import { CollaboratorsPage } from "./pages/authenticated/Collaborators/CollaboratorsPage";
import { BrlParitysPage } from "./pages/authenticated/BrlParitys/BrlParityPage";
import { ProtectedRoute } from "./shared/react/components/routing/ProtectedRoute";
import {
  controllershipReadRestrictions,
  costCenterReadRestrictions,
  customerReadRestrictions,
  uploadInvoicesReadRestrictions,
  invoicesReadRestrictions,
  customerBalanceReadRestrictions,
  waccReadRestrictions,
  feeReadRestrictions,
} from "./shared/constants/routing/restrictions/controllershipRestrictions.constants";
import { ProjectedRouteContainer } from "./shared/react/components/routing/ProjectedRouteContainer";
import {
  frameReadRestrictions,
  projectReadRestrictions,
  managementReadRestrictions,
  projectsBaselinesRestrictions,
  corporateAllocationsReadRestrictions,
  collaboratorAllocationsReadRestrictions,
  resultsProjectionReadRestrictions,
  budgetReadRestrictions,
  costDistributionReadRestrictions,
} from "./shared/constants/routing/restrictions/managementRestrictions.contants";
import { ProjectBaselines } from "./pages/authenticated/Projects/ProjectBaselines";
import { AccessGroupsMainPage } from "./pages/authenticated/AccessGroups/AccessGroupsMainPage";
import { CorporateAllocationsPage } from "./pages/authenticated/CorporateAllocations/CorporateAllocationsPage";
import { Reports } from "./pages/authenticated/Reports/ReportsMainPage";
import { CollaboratorTrack } from "./pages/authenticated/Collaborators/CollaboratorTrackPage";
import { UploadPerformedExpense } from "./pages/authenticated/UploadPerformedExpense/UploadPerformedExpensePage";
import { DashboardsView } from "./pages/authenticated/DashboardsView/DashboardsMainPage";
import { AllocationsMap } from "./pages/authenticated/AllocationsMap/AllocationsMapMainPage";
import { Performance } from "./pages/authenticated/Performance/Performance";
import { PeopleAndManagement } from "./pages/authenticated/PeopleAndManagement/PeopleAndManagement";
import { SkillsAndCertifications } from "./pages/authenticated/SkillsAndCertifications";
import { DashboardGroupsPage } from "./pages/authenticated/DashboardGroups";
import { DashboardsPage } from "./pages/authenticated/Dashboards";
import { WorkloadsPage } from "./pages/authenticated/Workloads";
import {
  performanceReadRestrictions,
  jobRateReadRestrictions,
  jobRoleReadRestrictions,
  uploadExpensesRestrictions,
  dashboardsCreateRestrictions,
  accessGroupsReadRestrictions,
  brlParityReadRestrictions,
  uploadPerformedFinancialCostRestrictions,
  monthlyAllocationClosingReadRestrictions,
  uploadLedgerAccountRestrictions,
} from "./shared/constants/routing/restrictions/performanceRestrictions.constants";
import {
  peopleAndManagementReadRestrictions,
  allocationsMapReadRestrictions,
  collaboratorReadRestrictions,
  contractRoleReadRestrictions,
  disciplinesReadRestrictions,
  workloadsCreateRestrictions,
  corporateSegmentReadRestrictions,
  skillCategoriesReadRestrictions,
} from "./shared/constants/routing/restrictions/peopleAndManagementRestrictions.constants";
import { dashboardsViewReadRestrictions } from "./shared/constants/routing/restrictions/dashboardsViewRestrictions.constants";
import { CorporateSegment } from "./pages/authenticated/CorporateSegment";
import { CollaboratorAllocationsPage } from "./pages/authenticated/CollaboratorAllocations";
import { UploadPerformedFinancialCost } from "./pages/authenticated/UploadPerformedFinancialCost/UploadPerformedFinancialCostPage";
import { InvoicesMainPage } from "./pages/authenticated/Invoices/InvoicesMainPage";
import { InvoicesViewPage } from "./pages/authenticated/Invoices/InvoicesViewPage";
import { UploadPaidOutInvoicePage } from "./pages/authenticated/UploadPaidOutInvoice/UploadPaidOutInvoicePage";
import { PipedriveProjectMainPage } from "./pages/authenticated/PipedriveProjects/PipedriveProjectMainPage";
import { ProjectsEditingPage } from "./pages/authenticated/Projects/ProjectsEditingPage";
import { InvoicesFilterProvider } from "./pages/authenticated/Invoices/shared/context/InvoicesFilterProvider";
import { AdminPage } from "./pages/authenticated/AdminPage";
import { BudgetPage } from "./pages/authenticated/Budget";
import { UserCollaboratorProfile } from "./pages/authenticated/UserCollaboratorProfile";
import { CollaboratorManagementPage } from "./pages/authenticated/CollaboratorManagementPage";
import { MonthlyAllocationClosingPage } from "./pages/authenticated/MonthlyAllocationClosing";
import { CostDistributionPage } from "./pages/authenticated/CostDistribution/CostDistributionPage";
import { ProjectionMainPage } from "./pages/authenticated/ProjectionV2/ProjectionMainPage";
import { ProjectionFilterProvider } from "./pages/authenticated/ProjectionV2/shared/context/ProjectionFilterProvider";
import { ProjectionAdjustmentPage } from "./pages/authenticated/ProjectionV2/ProjectionAdjustment";
import { LedgerAccountPage } from "./pages/authenticated/LedgerAccount";
import { ProjectsCreationByDealPage } from "./pages/authenticated/PipedriveProjects/ProjectsCreationByDealPage";
import { ProjectsViewingByDealPage } from "./pages/authenticated/PipedriveProjects/ProjectsViewingByDealPage";
import { AdditivesCreationPage } from "./pages/authenticated/PipedriveProjects/AdditivesCreationPage";
import { CustomerBalancePage } from "./pages/authenticated/CustomerBalance/CustomerBalancePage";
import { WaccPage } from "./pages/authenticated/Wacc/WaccPage";
import { FramesViewPage } from "./pages/authenticated/Frames/FramesViewPage";
import { FeesPage } from "./pages/authenticated/Fees";
import { TimesheetPageV2 } from "./pages/authenticated/Timesheet/TimeSheetPageV2";
import { PersonalSettings } from "./pages/authenticated/PersonalSettings";

export const MainRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    pushToPagesHistory(location);
  }, [location]);

  return (
    <Routes>
      <Route element={<LoggedLayout />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/brl-parity"
          element={
            <ProtectedRoute restrictions={brlParityReadRestrictions}>
              <BrlParitysPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/controllership"
          element={
            <ProtectedRoute restrictions={controllershipReadRestrictions}>
              <ControllershipPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/performance"
          element={
            <ProtectedRoute restrictions={performanceReadRestrictions}>
              <Performance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/people-and-management"
          element={
            <ProtectedRoute restrictions={peopleAndManagementReadRestrictions}>
              <PeopleAndManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/collaborators"
          element={
            <ProtectedRoute restrictions={collaboratorReadRestrictions}>
              <CollaboratorsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/collaborators/:collaboratorId/track"
          element={
            <ProtectedRoute restrictions={collaboratorReadRestrictions}>
              <CollaboratorTrack />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contract-roles"
          element={
            <ProtectedRoute restrictions={contractRoleReadRestrictions}>
              <ContractRolesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/corporate-allocations"
          element={
            <ProtectedRoute restrictions={corporateAllocationsReadRestrictions}>
              <CorporateAllocationsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/collaborator-allocations"
          element={
            <ProtectedRoute
              restrictions={collaboratorAllocationsReadRestrictions}
            >
              <CollaboratorAllocationsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/allocations-map"
          element={
            <ProtectedRoute restrictions={allocationsMapReadRestrictions}>
              <AllocationsMap />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cost-centers"
          element={
            <ProtectedRoute restrictions={costCenterReadRestrictions}>
              <CostCentersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute restrictions={customerReadRestrictions}>
              <CustomersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-invoices"
          element={
            <ProtectedRoute restrictions={uploadInvoicesReadRestrictions}>
              <UploadPaidOutInvoicePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/job-rates"
          element={
            <ProtectedRoute restrictions={jobRateReadRestrictions}>
              <JobRatesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/job-roles"
          element={
            <ProtectedRoute restrictions={jobRoleReadRestrictions}>
              <JobRolesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/management"
          element={
            <ProtectedRoute restrictions={managementReadRestrictions}>
              <Management />
            </ProtectedRoute>
          }
        />
        <Route
          element={
            <ProjectedRouteContainer restrictions={projectReadRestrictions} />
          }
        >
          <Route path="/projects" element={<ProjectsMainPage />} />
          <Route path="/projects/create" element={<ProjectsCreationPage />} />
          <Route path="/projects/:id/edit" element={<ProjectsEditingPage />} />
          <Route path="/projects/:id" element={<ProjectsViewingPage />} />
          <Route
            path="/projects/:idProject/peps"
            element={<CostCenterPepsPage />}
          />
          <Route
            path="/projects/:idProject/planning"
            element={<ProjectPlanningPage />}
          />
        </Route>
        <Route
          element={
            <ProjectedRouteContainer restrictions={frameReadRestrictions} />
          }
        >
          <Route path="/frames" element={<FramesMainPage />} />
          <Route path="/frames/:id" element={<FramesViewPage />} />
        </Route>
        <Route
          path="/project-baselines/:idProject"
          element={
            <ProtectedRoute restrictions={projectsBaselinesRestrictions}>
              <ProjectBaselines />
            </ProtectedRoute>
          }
        />
        <Route
          path="/access-groups"
          element={
            <ProtectedRoute restrictions={accessGroupsReadRestrictions}>
              <AccessGroupsMainPage />
            </ProtectedRoute>
          }
        />
        <Route path="/timesheet" element={<TimesheetPageV2 />} />
        <Route path="/reports" element={<Reports />} />
        <Route
          path="/upload-performed-expense"
          element={
            <ProtectedRoute restrictions={uploadExpensesRestrictions}>
              <UploadPerformedExpense />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-performed-financial-cost"
          element={
            <ProtectedRoute
              restrictions={uploadPerformedFinancialCostRestrictions}
            >
              <UploadPerformedFinancialCost />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboards-view"
          element={
            <ProtectedRoute restrictions={dashboardsViewReadRestrictions}>
              <DashboardsView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard-groups"
          element={
            <ProtectedRoute restrictions={dashboardsCreateRestrictions}>
              <DashboardGroupsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboards"
          element={
            <ProtectedRoute restrictions={dashboardsCreateRestrictions}>
              <DashboardsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/workloads"
          element={
            <ProtectedRoute restrictions={workloadsCreateRestrictions}>
              <WorkloadsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/skills-and-certifications"
          element={
            <ProtectedRoute
              listRestrictions={[
                disciplinesReadRestrictions,
                skillCategoriesReadRestrictions,
              ]}
            >
              <SkillsAndCertifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/corporate-segment"
          element={
            <ProtectedRoute restrictions={corporateSegmentReadRestrictions}>
              <CorporateSegment />
            </ProtectedRoute>
          }
        />
        <Route
          element={
            <ProjectedRouteContainer
              restrictions={resultsProjectionReadRestrictions}
            />
          }
        >
          <Route element={<ProjectionFilterProvider />}>
            <Route path="/projection" element={<ProjectionMainPage />} />
            <Route
              path="/projection/adjustments"
              element={<ProjectionAdjustmentPage />}
            />
          </Route>
        </Route>
        <Route
          element={
            <ProjectedRouteContainer restrictions={invoicesReadRestrictions} />
          }
        >
          <Route element={<InvoicesFilterProvider />}>
            <Route path="/invoices" element={<InvoicesMainPage />} />
            <Route path="/invoices/:id" element={<InvoicesViewPage />} />
          </Route>
        </Route>
        <Route
          path="/registration-of-projects-and-additives"
          element={
            <ProjectedRouteContainer restrictions={projectReadRestrictions} />
          }
        >
          <Route
            path="/registration-of-projects-and-additives"
            element={<PipedriveProjectMainPage />}
          />
          <Route
            path="/registration-of-projects-and-additives/:id/edit"
            element={<ProjectsCreationByDealPage />}
          />
          <Route
            path="/registration-of-projects-and-additives/:id"
            element={<ProjectsViewingByDealPage />}
          />
          <Route
            path="/registration-of-projects-and-additives/additive/create/:id"
            element={<AdditivesCreationPage mode="creation" />}
          />
          <Route
            path="/registration-of-projects-and-additives/additive/:id"
            element={<AdditivesCreationPage mode="viewing" />}
          />
          <Route
            path="/registration-of-projects-and-additives/additive/create"
            element={<AdditivesCreationPage mode="creation" />}
          />
        </Route>

        <Route path="/admin" element={<AdminPage />} />
        <Route
          path="/budget"
          element={
            <ProtectedRoute restrictions={budgetReadRestrictions}>
              <BudgetPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-collaborator-profile"
          element={<UserCollaboratorProfile />}
        />
        <Route
          path="/collaborator-management"
          element={<CollaboratorManagementPage />}
        />
        <Route
          path="/monthly-allocation-closing"
          element={
            <ProtectedRoute
              restrictions={monthlyAllocationClosingReadRestrictions}
            >
              <MonthlyAllocationClosingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cost-distribution"
          element={
            <ProtectedRoute restrictions={costDistributionReadRestrictions}>
              <CostDistributionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ledger-account"
          element={
            <ProtectedRoute restrictions={uploadLedgerAccountRestrictions}>
              <LedgerAccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customer-balance"
          element={
            <ProtectedRoute restrictions={customerBalanceReadRestrictions}>
              <CustomerBalancePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wacc"
          element={
            <ProtectedRoute restrictions={waccReadRestrictions}>
              <WaccPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/fee"
          element={
            <ProtectedRoute restrictions={feeReadRestrictions}>
              <FeesPage />
            </ProtectedRoute>
          }
        />
        <Route path="/personal-settings" element={<PersonalSettings />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
};
