import { useEffect, useState } from "react";
import { ButtonBase, Typography } from "@mui/material";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { useTranslation } from "react-i18next";
import { PageCardButtonContainer } from "../../../../shared/common/react/components/navigation/PageCardButton/index.styles";
import {
  CheckCanLoadDreQueues,
  LoadDrequeues,
} from "../../../../services/DreQueue/dreQueue.service";
import { notifyIf4xxApiErrorDTO } from "../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../services/applicationState/toast.service";

export const DRECardComponent = () => {
  const { t } = useTranslation();
  const [disabledLoadQueues, setDisabledLoadQueues] = useState<boolean>(true);

  const onclick = async () => {
    try {
      await LoadDrequeues();
      notifySuccess(t("home.suscess.data.LoadDrequeues"));
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "",
      });
    } finally {
      setDisabledLoadQueues(true);
    }
  };

  const VerifyQueues = async () => {
    const result = await CheckCanLoadDreQueues();
    setDisabledLoadQueues(!result);
  };
  useEffect(() => {
    VerifyQueues();
    const intervalId = setInterval(VerifyQueues, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ButtonBase onClick={onclick} disabled={disabledLoadQueues}>
      <PageCardButtonContainer>
        <AddToQueueIcon color={disabledLoadQueues ? "disabled" : "primary"} />
        <Typography>{t("permissionTypes.keywords.fields.dreQueue")}</Typography>
      </PageCardButtonContainer>
    </ButtonBase>
  );
};
