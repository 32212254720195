import { useCallback, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FieldArrayRenderProps, FormikProps } from "formik";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useTableDataProjects } from "./hooks/useTableDataProjects";
import { TimesheetContext } from "../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { TimesheetWeekFormValues } from "./shared/types/form.values";
import { getDaysInMonth } from "../../shared/helpers/data/timesheet/general.helpers";
import { formatMinutesToHourMinute } from "../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { useTableDataCostCenters } from "./hooks/useTableDataCostCenters";
import {
  StyledButtonArea,
  StyledTablesContainer,
  StyledTotalTable,
  StyledWeekTabContainer,
} from "./index.styles";
import { useTotalTableData } from "./hooks/useTotalTableData";
import { DaysTimesheetValues } from "../MonthTab/hooks/useEditingForm/types/values.types";
import { useReloadTables } from "./hooks/useReloadTables";
import { useFillRecurrenceForm } from "./hooks/useFillRecurrenceForm";
import { sleep } from "../../../../../../../shared/common/helpers/general.helpers";

interface OwnProps {
  formikProps: FormikProps<TimesheetWeekFormValues>;
  arrayprojectCreateFormikProps: FieldArrayRenderProps;
  arrayCostCenterCreateFormikProps: FieldArrayRenderProps;
}

export const WeekTabWithInContext = ({
  formikProps,
  arrayprojectCreateFormikProps,
  arrayCostCenterCreateFormikProps,
}: OwnProps) => {
  const { setValues, values, submitForm, setFieldValue } = formikProps;
  const { i18n, t } = useTranslation();
  const enhancedTableTotalRef = useRef<EnhancedTableRef>(null);
  const enhancedTableProjectRef = useRef<EnhancedTableRef>(null);
  const ehanchedTableCostCenterRef = useRef<EnhancedTableRef>(null);

  const { timesheetData, dateFilter, canEditTimesheet } =
    useContext(TimesheetContext);

  const reloadTablePageProject = useCallback(
    () => enhancedTableProjectRef.current?.reloadPage(),
    []
  );

  const reloadTablePageCostCenter = useCallback(
    () => ehanchedTableCostCenterRef.current?.reloadPage(),
    []
  );

  const setFieldValues = useCallback(async () => {
    if (!timesheetData) return;
    const projectTimesheets = timesheetData.timesheetMonths.filter(
      (x) => x.project
    );
    const costCenterTimesheets = timesheetData.timesheetMonths.filter(
      (x) => !x.project
    );
    const formValues: TimesheetWeekFormValues = {
      projectTimesheets: projectTimesheets.map((ts) => {
        const numberOfDaysCurrentMonth = getDaysInMonth(dateFilter);
        const days: DaysTimesheetValues[] = [];
        for (let i = 0; i < numberOfDaysCurrentMonth; i++) {
          const day = ts.days.find((d) => d.entryDate.day === i + 1);
          if (!day) {
            days.push({
              time: "00:00",
              day: i + 1,
              extraTime: "00:00",
            });
          } else {
            days.push({
              id: day.id,
              day: i + 1,
              time: formatMinutesToHourMinute(day.minutes),
              extraTime: formatMinutesToHourMinute(day.extraMinutes),
            });
          }
        }
        return {
          timesheet: ts,
          days,
          activity: ts.activity,
          pipedriveOrganization: null,
          observation: ts.observation ?? "",
        };
      }),
      costCenterTimesheets: costCenterTimesheets.map((ts) => {
        const numberOfDaysCurrentMonth = getDaysInMonth(dateFilter);
        const days: DaysTimesheetValues[] = [];
        for (let i = 0; i < numberOfDaysCurrentMonth; i++) {
          const day = ts.days.find((d) => d.entryDate.day === i + 1);
          if (!day) {
            days.push({
              time: "00:00",
              day: i + 1,
              extraTime: "00:00",
            });
          } else {
            days.push({
              id: day.id,
              day: i + 1,
              time: formatMinutesToHourMinute(day.minutes),
              extraTime: formatMinutesToHourMinute(day.extraMinutes),
            });
          }
        }
        return {
          timesheet: ts,
          days,
          activity: ts.activity,
          pipedriveOrganization: ts.pipedriveOrganization,
          observation: ts.observation ?? "",
        };
      }),
      projectCreateTimesheets: [],
      costCenterCreateTimesheets: [],
    };

    setValues(formValues);

    await sleep(100);
    reloadTablePageProject();
    reloadTablePageCostCenter();
  }, [timesheetData]);

  const {
    headCells: projectHeadCells,
    onPageChange: projectRows,
    deletionModal: deletionProjectTimesheetModal,
  } = useTableDataProjects({
    formikProps,
    arrayprojectCreateFormikProps,
    reloadTablePage: reloadTablePageProject,
  });

  const {
    headCells: costCenterHeadCells,
    onPageChange: costCenterRows,
    deletionModal: deletionCostCenterTimesheetModal,
  } = useTableDataCostCenters({
    formikProps,
    arrayCostCenterCreateFormikProps,
    reloadTablePage: reloadTablePageCostCenter,
  });

  const { rows: totalTableDataRows } = useTotalTableData({ formikProps });

  useEffect(() => {
    setFieldValues();
  }, [i18n.language, timesheetData]);

  useReloadTables({
    reloadTablePageProject,
    reloadTablePageCostCenter,
    costCenterHeadCells,
    projectHeadCells,
  });

  const { recurrenceModal, RecurrenceButton } = useFillRecurrenceForm();

  return (
    <StyledWeekTabContainer>
      <StyledTablesContainer>
        <RecurrenceButton />
        {values.projectTimesheets.length > 0 ||
        values.projectCreateTimesheets.length > 0 ? (
          <EnhancedTable
            ref={enhancedTableProjectRef}
            headCells={projectHeadCells}
            uiSettings={{
              minTableHeight: "max-content",
              stickyHeader: true,
            }}
            paginationSettings={{
              usesPagination: false,
              externalPagination: {
                loadSinglePageContent: projectRows,
              },
            }}
          />
        ) : (
          <Button
            sx={{
              textTransform: "none",
              width: "max-content",
            }}
            onClick={() => {
              setFieldValue("projectCreateTimesheets", [
                ...values.projectCreateTimesheets,
                {
                  appointment: undefined,
                  days: new Array(getDaysInMonth(dateFilter)).fill("00:00"),
                  extraTime: new Array(getDaysInMonth(dateFilter)).fill(
                    "00:00"
                  ),
                  observation: "",
                },
              ]);
              reloadTablePageProject();
            }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t("timesheets.actions.general.addProjectTimesheet")}
          </Button>
        )}
        {values.costCenterTimesheets.length > 0 ||
        values.costCenterCreateTimesheets.length > 0 ? (
          <EnhancedTable
            ref={ehanchedTableCostCenterRef}
            headCells={costCenterHeadCells}
            uiSettings={{
              minTableHeight: "max-content",
              stickyHeader: true,
            }}
            paginationSettings={{
              usesPagination: false,
              externalPagination: {
                loadSinglePageContent: costCenterRows,
              },
            }}
          />
        ) : (
          <Button
            sx={{
              textTransform: "none",
              width: "max-content",
            }}
            onClick={() => {
              setFieldValue("costCenterCreateTimesheets", [
                ...values.costCenterCreateTimesheets,
                {
                  appointment: undefined,
                  days: new Array(getDaysInMonth(dateFilter)).fill("00:00"),
                  extraTime: new Array(getDaysInMonth(dateFilter)).fill(
                    "00:00"
                  ),
                  observation: "",
                },
              ]);
              reloadTablePageCostCenter();
            }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t("timesheets.actions.general.addCostCenterTimesheet")}
          </Button>
        )}
        <StyledTotalTable>
          <EnhancedTable
            ref={enhancedTableTotalRef}
            headCells={costCenterHeadCells}
            uiSettings={{
              minTableHeight: "max-content",
              stickyHeader: true,
            }}
            paginationSettings={{
              usesPagination: false,
            }}
            headerSettings={{
              hiddenHeader: true,
            }}
            rows={totalTableDataRows}
          />
        </StyledTotalTable>
      </StyledTablesContainer>
      <StyledButtonArea>
        <div>
          <Button onClick={setFieldValues} disabled={!canEditTimesheet}>
            {t("general.actions.general.cancel")}
          </Button>
          <Button
            onClick={submitForm}
            variant="contained"
            disabled={!canEditTimesheet}
          >
            {t("general.actions.general.save")}
          </Button>
        </div>
      </StyledButtonArea>
      {deletionCostCenterTimesheetModal}
      {deletionProjectTimesheetModal}
      {recurrenceModal}
    </StyledWeekTabContainer>
  );
};
