import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { TimesheetRecurrenceFormValues } from "../shared/types/form.values";
import { FormMode } from "../shared/types/values.types";
import { TimesheetType } from "../../../../../../../shared/specific/enums/timesheets/TimesheetType.enum";

interface OwnProps {
  formMode: FormMode;
}

export const useTimesheetRecurrenceFormikConfig = ({ formMode }: OwnProps) => {
  const { t } = useTranslation();

  const initialValues: TimesheetRecurrenceFormValues = useMemo(() => {
    return {
      timesheetType: null,
      appointment: null,
      project: null,
      pipedriveOrganization: null,
      days: [],
      time: "00:00",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      timesheetType: yup
        .object()
        .nullable()
        .test({
          name: "timesheetType",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (formMode === "create") {
              return value !== null;
            }

            return true;
          },
        }),
      appointment: yup
        .object()
        .nullable()
        .test({
          name: "appointment",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            const type = this.parent.timesheetType;
            if (type?.id === TimesheetType.CostCenter) {
              return value !== null;
            }
            return true;
          },
        }),
      project: yup
        .object()
        .nullable()
        .test({
          name: "appointment",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            const type = this.parent.timesheetType;
            if (type?.id === TimesheetType.Project) {
              return value !== null;
            }
            return true;
          },
        }),
      pipedriveOrganization: yup.object().nullable(),
      days: yup
        .array()
        .required(t("general.errors.data.fields.general.required")),
      time: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
