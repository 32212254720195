import * as yup from "yup";
import { InvoiceBackendFiltersDTO } from "../../../shared/specific/DTOs/invoice/filters/InvoiceBackendFiltersDTO";
import { InvoiceCreateDTO } from "../../../shared/specific/DTOs/invoice/InvoiceCreateDTO";
import {
  InvoiceDTO,
  schemaInvoiceDTO,
} from "../../../shared/specific/DTOs/invoice/InvoiceDTO";
import {
  InvoiceSummaryDTO,
  schemaInvoiceSummaryDTO,
} from "../../../shared/specific/DTOs/invoice/InvoiceSummaryDTO";
import { riseApi } from "./connection";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import {
  PaginatedInvoiceDTO,
  schemaPaginatedInvoiceDTO,
} from "../../../shared/specific/DTOs/invoice/PaginatedInvoiceDTO";
import { InvoiceUploadResponseDTO } from "../../../shared/specific/DTOs/invoice/InvoiceUploadResponseDTO";
import { UpdateBasicInformationsDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateBasicInformationsDTO";
import { ReopenInvoiceRequestDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateBeforeReleasedDTO";
import { UpdateToAnalysisDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToAnalysisDTO";
import { UpdateToCancellationDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToCancellationDTO";
import { UpdateToIssuedDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToIssuedDTO";
import { UpdateToPaidOutDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToPaidOutDTO";
import { UpdateToProvisionCancellationDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToProvisionCancellationDTO";
import { UpdateToReleasedDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToReleasedDTO";
import { UpdateToReturnedDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToReturnedDTO";
import { UpdateToCancellationAnalysisDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToCancellationAnalysisDTO";
import {
  InvoiceOnlyDTO,
  schemaInvoiceOnlyDTO,
} from "../../../shared/specific/DTOs/invoice/InvoiceOnlyDTO";
import { UpdateInvoiceRequestNotReleasedDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateInvoiceRequestNotReleasedDTO";
import { UpdateIssuedSuperUserDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateIssuedSuperUserDTO";
import {
  LastInvoiceDto,
  schemaLastInvoiceDto,
} from "../../../shared/specific/DTOs/invoice/LastInvoiceDto";
import { UpdateToPddDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToPddDTO";
import { UpdateToLossDTO } from "../../../shared/specific/DTOs/invoice/updateProcesses/updateToLossDTO";

export const getInvoices = async ({
  filters,
}: {
  filters?: InvoiceBackendFiltersDTO;
} = {}): Promise<InvoiceDTO[]> => {
  const { data: invoices } = await riseApi.get("Invoices", {
    params: { ...filters },
  });

  return yup.array(schemaInvoiceDTO).defined().validateSync(invoices);
};

export const getInvoicesDropdown = async ({
  filters,
}: {
  filters?: InvoiceBackendFiltersDTO;
} = {}): Promise<InvoiceOnlyDTO[]> => {
  const { data: invoices } = await riseApi.get("Invoices/Dropdown", {
    params: { ...filters },
  });

  return yup.array(schemaInvoiceOnlyDTO).defined().validateSync(invoices);
};

export const getInvoicesPaginated = async ({
  filters,
  pagination,
}: {
  filters?: InvoiceBackendFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedInvoiceDTO> => {
  const statusQueryString = filters?.status
    ? `?${filters?.status.map((n, index) => `status[${index}]=${n}`).join("&")}`
    : "";

  const { data: invoices } = await riseApi.get(
    `Invoices/Paginated/${statusQueryString}`,
    {
      params: {
        ...pagination,
        ...filters,
      },
    }
  );

  return schemaPaginatedInvoiceDTO.validateSync(invoices);
};

export const getInvoiceById = async (
  id: number
): Promise<InvoiceDTO | null> => {
  const { data: invoice } = await riseApi.get(`Invoices/${id}`);

  return schemaInvoiceDTO.nullable().validateSync(invoice);
};

export const getProjectInvoicesSummary = async (
  idProject: number
): Promise<InvoiceSummaryDTO | null> => {
  const { data: invoicesSummary } = await riseApi.get(
    `Invoices/Project/${idProject}/Summary`
  );

  return schemaInvoiceSummaryDTO.nullable().validateSync(invoicesSummary);
};

export const createInvoice = async (invoice: InvoiceCreateDTO) => {
  await riseApi.post("Invoices", invoice);
};

export const UpdateBasicInformations = async (
  invoice: UpdateBasicInformationsDTO
) => {
  await riseApi.put("Invoices/UpdateBasicInformations", invoice);
};

export const ReopenInvoiceRequest = async (
  invoice: ReopenInvoiceRequestDTO
) => {
  await riseApi.put("Invoices/ReopenInvoiceRequest", invoice);
};

export const UpdateToAnalysis = async (invoice: UpdateToAnalysisDTO) => {
  await riseApi.put("Invoices/UpdateToAnalysis", invoice);
};

export const UpdateToCancellation = async (
  invoice: UpdateToCancellationDTO
) => {
  await riseApi.put("Invoices/UpdateToCancellation", invoice);
};

export const UpdateToIssued = async (invoice: UpdateToIssuedDTO) => {
  await riseApi.put("Invoices/UpdateToIssued", invoice);
};

export const UpdateIssuedSuperUser = async (
  invoice: UpdateIssuedSuperUserDTO
) => {
  await riseApi.put("Invoices/UpdateIssuedSuperUser", invoice);
};

export const UpdateToPaidOut = async (invoice: UpdateToPaidOutDTO) => {
  await riseApi.put("Invoices/UpdateToPaidOut", invoice);
};

export const UpdateToPdd = async (invoice: UpdateToPddDTO) => {
  await riseApi.put("Invoices/UpdateToPdd", invoice);
};

export const UpdateToLoss = async (invoice: UpdateToLossDTO) => {
  await riseApi.put("Invoices/UpdateToLoss", invoice);
};

export const UpdateToCancellationAnalysis = async (
  invoice: UpdateToCancellationAnalysisDTO
) => {
  await riseApi.put("Invoices/UpdateToCancellationAnalysis", invoice);
};

export const UpdateToProvisionCancellation = async (
  invoice: UpdateToProvisionCancellationDTO
) => {
  await riseApi.put("Invoices/UpdateToProvisionCancellation", invoice);
};

export const UpdateToReleased = async (invoice: UpdateToReleasedDTO) => {
  await riseApi.put("Invoices/UpdateToReleased", invoice);
};

export const UpdateInvoiceRequestNotReleased = async (
  invoice: UpdateInvoiceRequestNotReleasedDTO
) => {
  await riseApi.put("Invoices/UpdateInvoiceRequestNotReleased", invoice);
};

export const UpdateToReturned = async (invoice: UpdateToReturnedDTO) => {
  await riseApi.put("Invoices/UpdateToReturned", invoice);
};

export const deleteInvoice = async (id: number, version: string) => {
  await riseApi.delete(`Invoices/${id}/${version}`);
};

export const uploadPaidOutInvoices = async (
  file: File
): Promise<InvoiceUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);

  const { data: collaboratorUploadResponse } = await riseApi.post(
    "Invoices/invoicePaidOutFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return collaboratorUploadResponse;
};

export const getLastInvoiceById = async (
  id: number
): Promise<LastInvoiceDto | null> => {
  const { data: LastInvoice } = await riseApi.get(`Invoices/lastInvoice/${id}`);

  return schemaLastInvoiceDto.nullable().validateSync(LastInvoice);
};
