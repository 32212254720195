import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { deleteTimesheet } from "../../../../../../../../services/timesheet/timesheets.service";
import { DeleteActionButton } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/accessories/DeleteActionButton";
import { useActionButtonDialog } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { TimesheetMonthDTO } from "../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ModalContent } from "./ModalContent";
import { TimesheetContext } from "../../react/contexts/TimesheetContext/TimesheetContextProvider";

export const useDeletionForm = () => {
  const { t } = useTranslation();

  const { reloadTimesheetData, setLoading } = useContext(TimesheetContext);

  const {
    ModalButtonContainer: DeletionButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<TimesheetMonthDTO, TimesheetMonthDTO>({
    modal: {
      modalColorVariant: "error",
      modalTitle: t("timesheets.modal.data.delete.title"),
      ModalContentMemo: ({ internalModalData: timesheetMonth }) =>
        timesheetMonth && <ModalContent timesheetMonth={timesheetMonth} />,
    },
    button: {
      ModalButton: DeleteActionButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: timesheetMonth,
        setInternalModalData,
      }) => {
        setInternalModalData(timesheetMonth);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: timesheetMonth,
      }) => {
        setLoading(true);
        if (!timesheetMonth)
          throw new Error("'internalModalData' cannot be null on submit.");

        try {
          await deleteTimesheet({
            yearMonth: timesheetMonth.yearMonth,
            idCostCenter: timesheetMonth.costCenter?.id,
            idProject: timesheetMonth.project?.id,
            idCorporateSegment: timesheetMonth.corporateSegment?.id,
            idActivity: timesheetMonth.activity?.id,
            idPipedriveOrganization: timesheetMonth.pipedriveOrganization?.id,
            allocationType: timesheetMonth.allocationType,
          });

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "timesheets.errors.data.delete.failedToDeleteTimesheetEntry",
          });
        } finally {
          reloadTimesheetData();
        }
      },
    },
  });

  return { DeletionButtonContainer, deletionModal };
};
