import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormContainer = styled("div")`
  display: grid;
  grid-template-areas:
    "a b c"
    "d e f";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(6)}
`;
