import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useContext } from "react";
import { StyledContainerForm, StyledPeriodicityRow } from "./index.styles";
import { TimesheetRecurrenceFormValues } from "../../types/form.values";
import { MaskedTextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { WeekAutocompleteFormik } from "../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/dates/WeekAutocompleteFormik";
import { TimesheetTypeAutocompleteFormik } from "../../../../../../Timesheet/TimesheetPage/shared/react/components/form/formik/autocomplete/TimesheetTypeAutocompleteFormik";
import { TimesheetType } from "../../../../../../../../../shared/specific/enums/timesheets/TimesheetType.enum";
import { AppointmentTypeAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/timesheet/AppointmentTypeAutocompleteFormik";
import { PipedriveOrganizationAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/pipedriveDeal/PipedriveOrganizationAutocompleteFormik";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormMode } from "../../types/values.types";
import { getTimesheetRecurrenceLineIdentification } from "../../general/helpers";
import { TimesheetRecurrenceDTO } from "../../../../../../../../../shared/specific/DTOs/timesheetRecurrence/TimesheetRecurrenceDTO";
import { ProjectStatus } from "../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { ProjectCacheAutocompleteFormikWithoutPermissionType } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectCacheAutocompleteFormikWithoutPermissionType";
import { TimesheetContext } from "../../../../../../Timesheet/TimeSheetPageV2/shared/react/contexts/TimesheetContext/TimesheetContextProvider";

type OwnProps = FormContentProps<
  TimesheetRecurrenceFormValues,
  TimesheetRecurrenceDTO | undefined
> & {
  mode: FormMode;
};

export const CommonTimesheetRecurrenceForm = ({
  formikProps: { values },
  internalFormData,
  mode,
}: OwnProps) => {
  const { t } = useTranslation();

  const { timesheetData } = useContext(TimesheetContext);

  return (
    <StyledContainerForm>
      {mode === "create" ? (
        <>
          <TimesheetTypeAutocompleteFormik
            name="timesheetType"
            autocompleteProps={{
              disableClearable: true,
            }}
            excludeTimesheetTypes={[
              TimesheetType.Management,
              TimesheetType.Corporate,
            ]}
          />

          {values.timesheetType?.id === TimesheetType.Project && (
            <ProjectCacheAutocompleteFormikWithoutPermissionType
              name="project"
              filters={{
                statusIncludeOnly: [
                  ProjectStatus.Ongoing,
                  ProjectStatus.Finalized,
                ],
                finalizedInTheCurrentMonth: true,
              }}
            />
          )}

          {values.timesheetType?.id === TimesheetType.CostCenter && (
            <AppointmentTypeAutocompleteFormik
              name="appointment"
              idSubsidiaryLastHistory={
                timesheetData?.idSubsidiaryLastHistory ?? null
              }
            />
          )}

          {values.appointment?.costCenter && (
            <PipedriveOrganizationAutocompleteFormik name="pipedriveOrganization" />
          )}
        </>
      ) : (
        <>
          <TextField
            label={t("timesheetRecurrence.keywords.fields.recurrence")}
            value={
              internalFormData
                ? getTimesheetRecurrenceLineIdentification(internalFormData, t)
                : "-"
            }
            aria-readonly
            disabled
          />
          {values.pipedriveOrganization && (
            <TextField
              label={t("general.keywords.fields.clientAccount")}
              value={values.pipedriveOrganization?.name}
              aria-readonly
              disabled
            />
          )}
        </>
      )}

      <StyledPeriodicityRow>
        <MaskedTextFieldFormik
          name="time"
          overwrite="shift"
          mask="00:00"
          textFieldProps={{
            label: t("timesheetRecurrence.keywords.fields.durationPerDay"),
            variant: "standard",
            sx: {
              width: "80px",
            },
          }}
        />

        <WeekAutocompleteFormik
          name="days"
          autocompleteProps={{
            multiple: true,
            disableCloseOnSelect: true,
            sx: {
              flexGrow: 1,
            },
          }}
          textfieldProps={{
            label: t("general.keywords.dateTime.general.days"),
          }}
        />
      </StyledPeriodicityRow>
    </StyledContainerForm>
  );
};
