import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { TipPopoverButton } from "../../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";
import { WeekDay } from "../../../../../shared/types/data/form/general.types";
import { CalendarWeekCell } from "../../../../../shared/react/components/content/CalendarCell/WeekCell";
import { weekAbreviationToTranslationCode } from "../../../../../../../../../../shared/common/maps/enums/data/dates/weekToTranslationCode.maps";
import { Week } from "../../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { MoreTimeIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/MoreTimeIconButton";
import { StyledDayContainer, StylesContainer } from "./index.styles";
import { TimesheetContext } from "../../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";

interface OwnProps {
  weekDays: WeekDay[];
  field: string;
  reloadTablePage: () => void;
}

export const ExtraTime = ({ weekDays, reloadTablePage, field }: OwnProps) => {
  const { t } = useTranslation();

  const { dateFilter } = useContext(TimesheetContext);

  if (!field.includes("{{ day }}")) {
    return <span>Error!</span>;
  }

  return (
    <TipPopoverButton
      popoverProps={{
        onClose: reloadTablePage,
      }}
      button={
        <MoreTimeIconButton
          size="small"
          tooltipText={t("timesheets.keywords.general.extraTime")}
        />
      }
      content={
        <div>
          <Typography variant="h6">
            {t("timesheets.keywords.general.extraTime")}
          </Typography>
          <StylesContainer>
            {weekDays
              .filter((day) => day.date.getMonth() === dateFilter.getMonth())
              .map((day, i) => {
                const formattedField = field.replace(
                  "{{ day }}",
                  (day.date.getDate() - 1).toString()
                );
                return (
                  <StyledDayContainer key={i}>
                    <b>
                      {t(
                        weekAbreviationToTranslationCode[
                          day.date.getDay() as Week
                        ]
                      )}
                    </b>
                    <div>
                      <b>{day.date.getDate()}</b>
                    </div>
                    <CalendarWeekCell name={formattedField} />
                  </StyledDayContainer>
                );
              })}
          </StylesContainer>
        </div>
      }
    />
  );
};
