import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { checkIsValidHourString } from "../../../../../shared/helpers/data/calendar/validators.helpers";
import { CreateFormValues } from "../shared/types/data/form/values.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): CreateFormValues => {
    return {
      appointment: null,
      activity: null,
      pipedriveOrganization: null,
      days: [],
      observation: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      appointment: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      activity: yup.object().nullable(),
      pipedriveOrganization: yup.object().nullable(),
      observation: yup.string().nullable(),
      days: yup.array(
        yup.object({
          day: yup.number().integer().defined(),
          time: yup
            .string()
            .required(t("general.errors.data.fields.general.required"))
            .test(
              "is-valid-hour",
              t("general.errors.data.fields.dates.invalidTime"),
              (value) => {
                if (!value) return false;
                return checkIsValidHourString(value);
              }
            ),
        })
      ),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
