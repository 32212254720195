import { useTranslation } from "react-i18next";
import { TabContext, TabList } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { ElementRef, useEffect, useRef, useState } from "react";
import { useTabPanels } from "./hooks/useTabPanels";
import { useTabs } from "./hooks/useTabs";
import { checkIsValidType } from "../../../../shared/common/helpers/data/validation/schema.helpers";
import { schemaTabCode, TabCode } from "./shared/types/TabCode.enums";
import { StyledPlanningTabs, StyledTabs } from "./shared/styles/general.styles";
import { StyledTabContent } from "./shared/styles/StyledTabContent.styles";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";

export const PersonalSettings = () => {
  const { t } = useTranslation();

  const tabs = useTabs();
  const tabPanels = useTabPanels();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(() => {
    if (checkIsValidType<TabCode>(schemaTabCode, searchParams.get("tab")))
      return searchParams.get("tab") as TabCode;
    return tabs[0].key as TabCode;
  });

  const tabListRef = useRef<ElementRef<typeof TabList>>(null);

  useEffect(() => {
    setPageTitle(t("personalSettings.pages.main.title"));
  }, [t]);

  useEffect(() => {
    searchParams.set("tab", tabValue);
    setSearchParams(searchParams, {
      replace: true,
    });
  }, [tabValue]);

  return (
    <TabContext value={tabValue}>
      <StyledPlanningTabs>
        <StyledTabs>
          <TabList
            ref={tabListRef}
            defaultValue={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
          >
            {tabs}
          </TabList>
        </StyledTabs>
        <StyledTabContent stickyHeight={tabListRef.current?.offsetHeight}>
          {tabPanels}
        </StyledTabContent>
      </StyledPlanningTabs>
    </TabContext>
  );
};
