import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormValues } from "../shared/types/form.types";
import { EmployeeAllocationType } from "../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      costCenter: null,
      allocationType: {
        id: EmployeeAllocationType.Allocation,
        label: "",
      },
      namePt: "",
      nameUs: "",
      deactivatedDate: null,
    };
  }, [t]);

  const validationSchema = useMemo(() => {
    return yup.object({
      costCenter: yup.object().nullable(),
      namePt: yup
        .string()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      nameUs: yup
        .string()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      allocationType: yup
        .object()
        .required(t("general.errors.data.fields.general.required")),
      deactivatedDate: yup.date().nullable().default(null),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
