import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { startOfMonth } from "date-fns";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TimesheetContext } from "../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { getTimesheetLineIdentification } from "../../../shared/helpers/data/timesheet/general.helpers";
import { formatMinutesToHourMinute } from "../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { useDeletionForm } from "../../../shared/hooks/useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useCreationForm } from "./useCreationForm";
import { StyledIdentificationCell } from "../shared/styles/common.styles";
import { useAttachActivity } from "./useAttachActivity";
import { useSaveTimesheetObservation } from "./useSaveTimesheetObservation";

export const useProjectTableData = () => {
  const { t, i18n } = useTranslation();

  const { timesheetData, canEditTimesheet, reloadTimesheetData, dateFilter } =
    useContext(TimesheetContext);

  const { EditingButton, editingModal } = useEditingForm();
  const { creationButton, creationModal } = useCreationForm({
    creationType: "project",
    shouldShowButtonContained: false,
  });

  const { AttachActivityButtonContainer, attachActivityModal } =
    useAttachActivity({
      reloadTablePage: reloadTimesheetData,
    });

  const { TimesheetObservationButtonContainer, timesheetObservationModal } =
    useSaveTimesheetObservation({
      reloadTablePage: reloadTimesheetData,
    });

  const { DeletionButtonContainer, deletionModal } = useDeletionForm();

  const isPastMonth = useMemo(() => {
    const startOfCurrentMonth = startOfMonth(new Date());
    const startDateFilter = startOfMonth(dateFilter);
    return startOfCurrentMonth > startDateFilter;
  }, [dateFilter]);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("projects.keywords.general.project"),
        HeadRenderer: () => (
          <div>
            {!isPastMonth && creationButton}{" "}
            {t("projects.keywords.general.project")}
          </div>
        ),
      },
      {
        value: "",
        width: 60,
      },
      {
        value: t("timesheets.keywords.fields.totalHoursMonth"),
        width: 300,
      },
      {
        value: "",
        width: 152,
      },
    ];

    return headCells;
  }, [t, dateFilter, isPastMonth]);

  const rows = useMemo(() => {
    const rows = timesheetData?.timesheetMonths
      .filter((x) => x.project)
      .map((timesheetMonth) => {
        const row: ExternalEnhancedRow = [
          {
            CellRenderer: () => (
              <StyledIdentificationCell>
                <span>
                  {getTimesheetLineIdentification(timesheetMonth, t, i18n)}
                </span>
                <AttachActivityButtonContainer timesheetMonth={timesheetMonth}>
                  {timesheetMonth.activity?.description ??
                    t("timesheets.actions.general.associateActivity")}
                </AttachActivityButtonContainer>
              </StyledIdentificationCell>
            ),
          },
          {
            CellRenderer: () => (
              <TimesheetObservationButtonContainer {...timesheetMonth} />
            ),
          },
          {
            value: timesheetMonth.minutesSum + timesheetMonth.extraMinutesSum,
            displayValue: formatMinutesToHourMinute(
              timesheetMonth.minutesSum + timesheetMonth.extraMinutesSum
            ),
          },
          {
            CellRenderer: () => {
              const appointmentFromAllocation =
                timesheetMonth.days.length === 0;
              return (
                <>
                  {canEditTimesheet && (
                    <>
                      <EditingButton {...timesheetMonth} />
                      {!appointmentFromAllocation && (
                        <DeletionButtonContainer {...timesheetMonth} />
                      )}
                    </>
                  )}
                </>
              );
            },
            align: "right",
            paddingmode: "horizontal",
          },
        ];

        return row;
      });

    return rows ?? [];
  }, [timesheetData, t]);

  return {
    headCells,
    rows,
    deletionModal,
    editingModal,
    creationModal,
    attachActivityModal,
    timesheetObservationModal,
  };
};
