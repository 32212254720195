import * as yup from "yup";
import {
  schemaTimesheetMonthDTO,
  TimesheetMonthDTO,
} from "./TimesheetMonthDTO";

export interface TimesheetsUserMonthDTO {
  timesheetMonths: TimesheetMonthDTO[];
  minutesSum: number;
  extraMinutesSum: number;
  hasTrustedRole: boolean;
  idSubsidiaryLastHistory: number | null;
}

export const schemaTimesheetsUserMonthDTO = yup.object({
  timesheetMonths: yup.array(schemaTimesheetMonthDTO).defined(),
  minutesSum: yup.number().integer().defined(),
  extraMinutesSum: yup.number().integer().defined(),
  hasTrustedRole: yup.boolean().defined(),
  idSubsidiaryLastHistory: yup.number().nullable().defined(),
});
