import { FormikProps } from "formik";
import { Button, Grow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MaskedTextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { WeekAutocompleteFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/dates/WeekAutocompleteFormik";
import { QuickFillFormValues } from "./form.types";
import { StyledQuickFillForm } from "./index.styles";
import { WeekNumberAutocompleteFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/dates/WeekNumberAutocompleteFormik";

interface OwnProps {
  formikProps: FormikProps<QuickFillFormValues>;
}

export const Form = ({ formikProps: { submitForm } }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <Grow in timeout={2000}>
      <StyledQuickFillForm>
        <div>
          <span>{t("timesheets.keywords.general.launch")}</span>
          <MaskedTextFieldFormik name="time" overwrite="shift" mask="00:00" />
          <span>{t("timesheets.keywords.general.hoursInWeek")}</span>
          <WeekNumberAutocompleteFormik
            name="weeks"
            autocompleteProps={{
              multiple: true,
              sx: {
                minWidth: "300px",
              },
            }}
          />
        </div>
        <div>
          <span>
            {t(
              "timesheets.keywords.general.consideringTheFollowingReleaseDays"
            )}
          </span>
          <WeekAutocompleteFormik
            name="days"
            autocompleteProps={{
              multiple: true,
              disableCloseOnSelect: true,
              sx: {
                minWidth: "460px",
              },
            }}
            textfieldProps={{
              label: t("general.keywords.dateTime.general.days"),
            }}
          />
          <Button onClick={submitForm} variant="contained">
            {t("timesheets.actions.general.insert")}
          </Button>
        </div>
      </StyledQuickFillForm>
    </Grow>
  );
};
