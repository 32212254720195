import { TabPanel } from "@mui/lab";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyledTabPanelContent } from "./index.styles";
import { TabCode } from "../../shared/types/TabCode.enums";
import { TimesheetRecurrence } from "../../tabs/TimesheetRecurrence";

export const useTabPanels = () => {
  const { t } = useTranslation();

  const tabPanels = useMemo(() => {
    const tabPanelsInfo = [
      {
        component: <TimesheetRecurrence />,
        tabCode: TabCode.TimesheetRecurrence,
      },
    ];

    return tabPanelsInfo.map(({ component, tabCode }) => (
      <TabPanel value={tabCode} key={tabCode}>
        <StyledTabPanelContent>{component}</StyledTabPanelContent>
      </TabPanel>
    ));
  }, [t]);

  return tabPanels;
};
