import * as yup from "yup";
import { useContext, useMemo, useRef } from "react";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { QuickFillFormValues } from "./form.types";
import { Form } from "./Form";
import { DaysTimesheetValues } from "../../../../../hooks/useEditingForm/types/values.types";
import { TimesheetContext } from "../../../../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { getWeekNumberOfMonth } from "../../../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { Week } from "../../../../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { weekAbreviationToTranslationCode } from "../../../../../../../../../../../../shared/common/maps/enums/data/dates/weekToTranslationCode.maps";

interface OwnProps {
  quickFill: (field: string, value: DaysTimesheetValues[]) => void;
  currentValues: DaysTimesheetValues[];
}

export const QuickFillForm = ({ quickFill, currentValues }: OwnProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<QuickFillFormValues>>(null);

  const { dateFilter } = useContext(TimesheetContext);

  const formikConfig = useMemo(() => {
    const initialValues: QuickFillFormValues = {
      time: "00:00",
      weeks: [],
      days: [
        {
          id: Week.Monday,
          label: t(weekAbreviationToTranslationCode[Week.Monday]),
        },
        {
          id: Week.Tuesday,
          label: t(weekAbreviationToTranslationCode[Week.Tuesday]),
        },
        {
          id: Week.Wednesday,
          label: t(weekAbreviationToTranslationCode[Week.Wednesday]),
        },
        {
          id: Week.Thursday,
          label: t(weekAbreviationToTranslationCode[Week.Thursday]),
        },
        {
          id: Week.Friday,
          label: t(weekAbreviationToTranslationCode[Week.Friday]),
        },
      ],
    };

    const validationSchema = yup.object({
      time: yup
        .string()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      weeks: yup.array(yup.object()).defined(),
      days: yup.array(yup.object()).defined(),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, []);

  const onSubmit = (values: QuickFillFormValues) => {
    const newValues = currentValues.map((currentValue) => {
      const dateTime = new Date(dateFilter.getTime());
      dateTime.setDate(currentValue.day);
      const weekNumber = getWeekNumberOfMonth(dateTime);
      const dayOfWeek = dateTime.getDay();

      const isToSetThatWeek = values.weeks.some(
        (week) => week.id === weekNumber
      );
      const isToSetThatDay = values.days.some((day) => day.id === dayOfWeek);

      if (isToSetThatWeek && isToSetThatDay) {
        return {
          ...currentValue,
          time: values.time,
        };
      }

      return currentValue;
    });

    quickFill("days", newValues);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formikConfig.initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={onSubmit}
      validationSchema={formikConfig.validationSchema}
    >
      {(formikProps) => <Form formikProps={formikProps} />}
    </Formik>
  );
};
