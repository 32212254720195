import { Box, Chip, TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../types/data/form/autocomplete.types";
import { WeekNumberEnum } from "../../../../../../../../../specific/enums/allocations/WeekNumber.enum";
import { StyledWeekChip } from "./index.styles";
import { StyledMultipleSelectionContainer } from "../../../../../../../../../specific/react/components/form/shared/styles/multipleSelection.styles";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<WeekNumberEnum>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const WeekNumberAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
  name,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const options = Object.values(WeekNumberEnum)
      .filter((x) => /^\d+$/.test(String(x)))
      .map((weekNumber) => ({
        id: weekNumber as WeekNumberEnum,
        label: `${t("general.keywords.fields.week")} ${weekNumber.valueOf()}`,
      }));

    return options;
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
        multiple: true as Multiple,
        disableCloseOnSelect: true,
        renderTags: (value) => {
          return (
            <StyledWeekChip>
              {value.map((x, i) => {
                return <Chip label={x.id} key={i} />;
              })}
            </StyledWeekChip>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {option.label}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
      }}
      textfieldProps={{
        ...textfieldProps,
        label: t("general.keywords.dateTime.general.week"),
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
