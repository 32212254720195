import { TextFieldProps } from "@mui/material";
import { Ref, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { CustomAutocompleteRef } from "../../../../../../../../../../../shared/common/react/components/form/general/autocomplete/general/CustomAutocomplete";
import { AutocompleteOption } from "../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { TimesheetType } from "../../../../../../../../../../../shared/specific/enums/timesheets/TimesheetType.enum";
import { timesheetTypeToTranslationCodeMap } from "../../../../../../../../../../../shared/specific/maps/timesheets/TimesheetTypeTranslations.maps";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<TimesheetType>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
  innerRef?: Ref<TimesheetTypeAutocompleteFormikRef>;
  excludeTimesheetTypes?: TimesheetType[];
}

export type TimesheetTypeAutocompleteFormikRef = CustomAutocompleteRef<
  AutocompleteOption<TimesheetType>
>;

export const TimesheetTypeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
  innerRef,
  excludeTimesheetTypes,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo((): AutocompleteOption<TimesheetType>[] => {
    const values = Object.values(TimesheetType)
      .filter((x) => typeof x === "number")
      .map((x) => x as TimesheetType)
      .map((timesheetType) => ({
        id: timesheetType,
        label: t(timesheetTypeToTranslationCodeMap[timesheetType]),
      }));

    if (excludeTimesheetTypes) {
      return values.filter((x) => !excludeTimesheetTypes.includes(x.id));
    }

    return values;
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: t("general.keywords.fields.type"),
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      innerRef={innerRef}
    />
  );
};
