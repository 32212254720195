import { getDaysInMonth } from "date-fns";
import { FillRecurrenceFormValues } from "./values.types";
import { TimesheetWeekFormValues } from "../../../shared/types/form.values";
import { canFillThatDay } from "../../../../../shared/helpers/data/timesheet/general.helpers";
import { formatMinutesToHourMinute } from "../../../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { EmployeeAllocationType } from "../../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";

interface OwnProps {
  formValues: FillRecurrenceFormValues;
  timesheetValues: TimesheetWeekFormValues;
  dateFilter: Date;
  hasTrustedRole: boolean;
}

export const setTimesheetRecurrenceValues = ({
  formValues,
  timesheetValues,
  dateFilter,
  hasTrustedRole,
}: OwnProps) => {
  const { recurrences } = formValues;

  if (!recurrences) return;

  const listRecurrences = recurrences;

  const projectTimesheets = timesheetValues.projectTimesheets.map((pt) => {
    const recurrence = listRecurrences.find(
      (r) =>
        r.recurrence.project &&
        r.recurrence.project.id === pt.timesheet.project?.id
    );

    if (!recurrence || !recurrence.use) return pt;

    pt.days = pt.days.map((day, i) => {
      const canEditThisDay = canFillThatDay(
        dateFilter,
        hasTrustedRole || false,
        day.day
      );
      if (day.time !== "00:00" || !canEditThisDay) return day;

      const dateTime = new Date(dateFilter.getTime());
      dateTime.setDate(day.day);

      const recurrenceDay = recurrence.recurrence.recurrencesDay.some(
        (rd) => rd.weekDay === dateTime.getDay()
      );

      if (recurrenceDay)
        return {
          ...day,
          time: formatMinutesToHourMinute(recurrence.recurrence.minutes),
        };

      return day;
    });

    listRecurrences.splice(listRecurrences.indexOf(recurrence), 1);
    return pt;
  });

  const costCenterTimesheets = timesheetValues.costCenterTimesheets.map(
    (ct) => {
      const { corporateSegment, costCenter, allocationType } = ct.timesheet;
      const pipeDriveOrganization =
        ct.pipedriveOrganization || ct.timesheet.pipedriveOrganization;

      const recurrence = listRecurrences.find(
        (r) =>
          r.recurrence.corporateSegment?.id === corporateSegment?.id &&
          r.recurrence.costCenter?.id === costCenter?.id &&
          r.recurrence.allocationType === allocationType &&
          r.recurrence.pipedriveOrganization?.id === pipeDriveOrganization?.id
      );

      if (!recurrence || !recurrence.use) return ct;

      ct.days = ct.days.map((day, i) => {
        const canEditThisDay = canFillThatDay(
          dateFilter,
          hasTrustedRole || false,
          day.day
        );
        if (day.time !== "00:00" || !canEditThisDay) return day;

        const dateTime = new Date(dateFilter.getTime());
        dateTime.setDate(day.day);

        const recurrenceDay = recurrence.recurrence.recurrencesDay.some(
          (rd) => rd.weekDay === dateTime.getDay()
        );

        if (recurrenceDay)
          return {
            ...day,
            time: formatMinutesToHourMinute(recurrence.recurrence.minutes),
          };

        return day;
      });

      listRecurrences.splice(listRecurrences.indexOf(recurrence), 1);
      return ct;
    }
  );

  const projectCreateTimesheets = timesheetValues.projectCreateTimesheets.map(
    (pct) => {
      const recurrence = listRecurrences.find(
        (r) =>
          r.recurrence.project &&
          r.recurrence.project.id === pct.appointment?.id
      );

      if (!recurrence || !recurrence.use) return pct;

      pct.days = pct.days.map((day, i) => {
        const monthDay = i + 1;
        const canEditThisDay = canFillThatDay(
          dateFilter,
          hasTrustedRole || false,
          monthDay
        );
        if (day !== "00:00" || !canEditThisDay) return day;

        const dateTime = new Date(dateFilter.getTime());
        dateTime.setDate(monthDay);

        const recurrenceDay = recurrence.recurrence.recurrencesDay.some(
          (rd) => rd.weekDay === dateTime.getDay()
        );

        if (recurrenceDay)
          return formatMinutesToHourMinute(recurrence.recurrence.minutes);

        return day;
      });

      listRecurrences.splice(listRecurrences.indexOf(recurrence), 1);
      return pct;
    }
  );

  const costCenterCreateTimesheets =
    timesheetValues.costCenterCreateTimesheets.map((cct) => {
      const corporateSegment = cct.appointment?.corporateSegment;
      const costCenter =
        cct.appointment?.costCenter ?? corporateSegment?.costCenter;

      const allocationType =
        cct.appointment?.allocationType ??
        corporateSegment?.allocationType ??
        EmployeeAllocationType.Support;

      const recurrence = listRecurrences.find(
        (r) =>
          r.recurrence.corporateSegment?.id === corporateSegment?.id &&
          r.recurrence.costCenter?.id === costCenter?.id &&
          r.recurrence.allocationType === allocationType &&
          r.recurrence.pipedriveOrganization?.id ===
            cct.pipedriveOrganization?.id
      );

      if (!recurrence || !recurrence.use) return cct;

      cct.days = cct.days.map((day, i) => {
        const monthDay = i + 1;
        const canEditThisDay = canFillThatDay(
          dateFilter,
          hasTrustedRole || false,
          monthDay
        );

        if (day !== "00:00" || !canEditThisDay) return day;

        const dateTime = new Date(dateFilter.getTime());
        dateTime.setDate(monthDay);

        const recurrenceDay = recurrence.recurrence.recurrencesDay.some(
          (rd) => rd.weekDay === dateTime.getDay()
        );

        if (recurrenceDay)
          return formatMinutesToHourMinute(recurrence.recurrence.minutes);

        return day;
      });

      listRecurrences.splice(listRecurrences.indexOf(recurrence), 1);
      return cct;
    });

  const numberDaysOfMonth = getDaysInMonth(dateFilter);

  listRecurrences.forEach(({ recurrence, use }, id) => {
    if (!use) return;

    if (recurrence.costCenter) {
      costCenterCreateTimesheets.push({
        pipedriveOrganization: recurrence.pipedriveOrganization,
        days: Array.from({ length: numberDaysOfMonth }, (_, i) => {
          const monthDay = i + 1;
          const dateTime = new Date(dateFilter.getTime());
          dateTime.setDate(monthDay);

          const canEditThisDay = canFillThatDay(
            dateFilter,
            hasTrustedRole || false,
            monthDay
          );

          const hasRecurrence = recurrence.recurrencesDay
            .map((x) => x.weekDay)
            .includes(dateTime.getDay());

          return hasRecurrence && canEditThisDay
            ? formatMinutesToHourMinute(recurrence.minutes)
            : "00:00";
        }),
        extraTime: Array.from({ length: numberDaysOfMonth }, () => "00:00"),
        appointment: {
          corporateSegment: recurrence.corporateSegment,
          costCenter: recurrence.costCenter,
          allocationType: recurrence.allocationType,
          id,
        },
        observation: "",
      });
    }

    if (recurrence.project) {
      projectCreateTimesheets.push({
        activity: null,
        days: Array.from({ length: numberDaysOfMonth }, (_, i) => {
          const monthDay = i + 1;
          const dateTime = new Date(dateFilter.getTime());
          dateTime.setDate(monthDay);

          const canEditThisDay = canFillThatDay(
            dateFilter,
            hasTrustedRole || false,
            monthDay
          );

          const hasRecurrence = recurrence.recurrencesDay
            .map((x) => x.weekDay)
            .includes(dateTime.getDay());

          return hasRecurrence && canEditThisDay
            ? formatMinutesToHourMinute(recurrence.minutes)
            : "00:00";
        }),
        extraTime: Array.from({ length: numberDaysOfMonth }, () => "00:00"),
        appointment: recurrence.project,
        observation: "",
      });
    }

    if (!recurrence.costCenter && !recurrence.project) {
      costCenterCreateTimesheets.push({
        pipedriveOrganization: null,
        days: Array.from({ length: numberDaysOfMonth }, (_, i) => {
          const monthDay = i + 1;
          const dateTime = new Date(dateFilter.getTime());
          dateTime.setDate(monthDay);

          const canEditThisDay = canFillThatDay(
            dateFilter,
            hasTrustedRole || false,
            monthDay
          );

          const hasRecurrence = recurrence.recurrencesDay
            .map((x) => x.weekDay)
            .includes(dateTime.getDay());

          return hasRecurrence && canEditThisDay
            ? formatMinutesToHourMinute(recurrence.minutes)
            : "00:00";
        }),
        extraTime: Array.from({ length: numberDaysOfMonth }, () => "00:00"),
        appointment: {
          corporateSegment: null,
          costCenter: null,
          allocationType: recurrence.allocationType,
          id,
        },
        observation: "",
      });
    }
  });

  return {
    projectTimesheets,
    costCenterTimesheets,
    projectCreateTimesheets,
    costCenterCreateTimesheets,
  };
};
