import { getDaysInMonth } from "date-fns";
import { TimesheetsUserMonthDTO } from "../../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetsUserMonthDTO";
import { FillRecurrenceFormValues } from "./values.types";
import { DateOnly } from "../../../../../../../../../../shared/common/classes/data/date/DateOnly";
import { canFillThatDay } from "../../../../../shared/helpers/data/timesheet/general.helpers";
import { TimesheetModifyMonthDTO } from "../../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetModifyMonthDTO";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";

interface OwnProps {
  timesheetData: TimesheetsUserMonthDTO | null;
  formValues: FillRecurrenceFormValues;
  dateFilter: Date;
}

export const setTimesheetRecurrenceValues = ({
  timesheetData,
  formValues,
  dateFilter,
}: OwnProps) => {
  const { recurrences } = formValues;

  if (!recurrences) return;

  const listRecurrences = recurrences;

  const formattedTimesheetData =
    timesheetData?.timesheetMonths.map((timesheet) => {
      return {
        ...timesheet,
        days: Array.from({ length: getDaysInMonth(dateFilter) }).map((_, i) => {
          const item = timesheet.days.find((x) => x.entryDate.day === i + 1);

          if (item) return item;

          const monthDay = i + 1;
          const dateTime = new Date(dateFilter.getTime());
          dateTime.setDate(monthDay);
          return {
            id: timesheet.days.find((x) => x.entryDate.day === monthDay)?.id,
            entryDate: DateOnly.createFromDate(dateTime),
            minutes: 0,
            extraMinutes: 0,
          };
        }),
      };
    }) ?? [];

  const projectTimesheets =
    formattedTimesheetData
      .filter((x) => x.project)
      .map((pt) => {
        const recurrence = listRecurrences.find(
          (r) =>
            r.recurrence.project && r.recurrence.project.id === pt.project?.id
        );

        if (!recurrence || !recurrence.use) return pt;

        pt.days = pt.days.map((day, i) => {
          const canEditThisDay = canFillThatDay(
            dateFilter,
            timesheetData?.hasTrustedRole || false,
            i + 1
          );

          if (day.minutes !== 0 || !canEditThisDay) return day;

          const recurrenceDay = recurrence.recurrence.recurrencesDay.some(
            (rd) => rd.weekDay === day.entryDate.toDate().getDay()
          );

          if (recurrenceDay)
            return {
              ...day,
            };

          return day;
        });

        listRecurrences.splice(listRecurrences.indexOf(recurrence), 1);
        return pt;
      }) ?? [];

  const costCenterTimesheets =
    formattedTimesheetData
      .filter((x) => !x.project)
      .map((ct) => {
        const recurrence = listRecurrences.find(
          (r) =>
            r.recurrence.corporateSegment?.id === ct.corporateSegment?.id &&
            r.recurrence.costCenter?.id === ct.costCenter?.id &&
            r.recurrence.allocationType === ct.allocationType &&
            r.recurrence.pipedriveOrganization?.id ===
              ct.pipedriveOrganization?.id
        );

        if (!recurrence || !recurrence.use) return ct;

        ct.days = ct.days.map((day, i) => {
          const canEditThisDay = canFillThatDay(
            dateFilter,
            timesheetData?.hasTrustedRole || false,
            i + 1
          );

          if (day.minutes !== 0 || !canEditThisDay) return day;

          const recurrenceDay = recurrence.recurrence.recurrencesDay.some(
            (rd) => rd.weekDay === day.entryDate.toDate().getDay()
          );

          if (recurrenceDay)
            return {
              ...day,
              minutes: recurrence.recurrence.minutes,
            };

          return day;
        });

        listRecurrences.splice(listRecurrences.indexOf(recurrence), 1);
        return ct;
      }) ?? [];

  const updateList: TimesheetModifyMonthDTO[] = [
    ...projectTimesheets,
    ...costCenterTimesheets,
  ].map((timesheet) => ({
    idProject: timesheet.project?.id,
    idActivity: timesheet.activity?.id,
    idCostCenter: timesheet.costCenter?.id,
    idCorporateSegment: timesheet.corporateSegment?.id,
    allocationType: timesheet.allocationType,
    yearMonth: timesheet.yearMonth,
    idPipedriveOrganization: timesheet.pipedriveOrganization?.id,
    observation: timesheet.observation ?? "",
    days: timesheet.days.map((day) => ({
      id: day.id,
      day: day.entryDate.day,
      minutes: day.minutes,
      extraMinutes: day.extraMinutes,
    })),
  }));

  listRecurrences.forEach(({ recurrence, use }, id) => {
    if (!use) return;
    updateList.push({
      allocationType: recurrence.allocationType,
      idActivity: undefined,
      idCorporateSegment: recurrence.corporateSegment?.id,
      idCostCenter: recurrence.costCenter?.id,
      idProject: recurrence.project?.id,
      idPipedriveOrganization: recurrence.pipedriveOrganization?.id,
      yearMonth: YearMonth.createFromDate(dateFilter),
      observation: "",
      days: Array.from({ length: getDaysInMonth(dateFilter) }).map((_, i) => {
        const monthDay = i + 1;

        const canEditThisDay = canFillThatDay(
          dateFilter,
          timesheetData?.hasTrustedRole || false,
          monthDay
        );

        const dateTime = new Date(dateFilter.getTime());
        dateTime.setDate(monthDay);

        const hasRecurrence = recurrence.recurrencesDay
          .map((x) => x.weekDay)
          .includes(dateTime.getDay());

        return hasRecurrence && canEditThisDay
          ? {
              day: monthDay,
              minutes: recurrence.minutes,
              extraMinutes: 0,
            }
          : {
              day: monthDay,
              minutes: 0,
              extraMinutes: 0,
            };
      }),
    });
  });

  return updateList;
};
