import { styled } from "@mui/material/styles";

export const StyledQuickFillForm = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.spacing(1)};
  }
`;
