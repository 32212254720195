import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CorporateSegmentsDTO,
  schemaCorporateSegmentsDTO,
} from "../corporateSegment/CorporateSegmentsDTO";
import {
  CostCenterOnlyDTO,
  schemaCostCenterOnlyDTO,
} from "../costCenter/CostCenterOnlyDTO";
import {
  ProjectOnlyDTO,
  schemaProjectOnlyDTO,
} from "../project/ProjectOnlyDTO";
import {
  schemaUserCollaboratorOnlyDTO,
  UserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import {
  schemaTimesheetMonthDayDTO,
  TimesheetMonthDayDTO,
} from "./TimesheetMonthDayDTO";
import {
  schemaWeekNumberEnum,
  WeekNumberEnum,
} from "../../enums/allocations/WeekNumber.enum";
import {
  ActivityDropdownDTO,
  schemaActivityDropdownDTO,
} from "../activity/ActivityDropdownDTO";
import {
  PipedriveOrganizationDropdownDTO,
  schemaPipedriveOrganizationDropdownDTO,
} from "../pipedrive/PipedriveOrganizationDropdownDTO";

export interface TimesheetMonthDTO {
  userCollaborator: UserCollaboratorOnlyDTO;
  project: ProjectOnlyDTO | null;
  costCenter: CostCenterOnlyDTO | null;
  corporateSegment: CorporateSegmentsDTO | null;
  activity: ActivityDropdownDTO | null;
  pipedriveOrganization: PipedriveOrganizationDropdownDTO | null;
  allocationType: EmployeeAllocationType;
  minutesSum: number;
  extraMinutesSum: number;
  yearMonth: YearMonth;
  days: TimesheetMonthDayDTO[];
  weeks: WeekNumberEnum[] | null;
  observation: string | null;
}

export const schemaTimesheetMonthDTO = yup.object({
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  project: schemaProjectOnlyDTO.nullable().defined(),
  costCenter: schemaCostCenterOnlyDTO.nullable().defined(),
  corporateSegment: schemaCorporateSegmentsDTO.nullable().defined(),
  activity: schemaActivityDropdownDTO.nullable().defined(),
  pipedriveOrganization: schemaPipedriveOrganizationDropdownDTO
    .nullable()
    .defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  minutesSum: yup.number().integer().defined(),
  extraMinutesSum: yup.number().integer().defined(),
  yearMonth: schemaYearMonth.defined(),
  days: yup.array(schemaTimesheetMonthDayDTO).defined(),
  weeks: yup.array(schemaWeekNumberEnum.defined()).nullable().defined(),
  observation: yup.string().nullable().defined(),
});
