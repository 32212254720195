import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAreaContainer } from "../TopBar/UserArea/index.styles";
import { StyledPersonalSettings } from "./index.styles";

interface OwnProps {
  children: React.ReactNode;
}

export const PopoverMenu = ({ children }: OwnProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ textTransform: "none", textDecoration: "none" }}
      >
        <UserAreaContainer>{children}</UserAreaContainer>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="subtitle2" style={{ padding: "10px" }}>
          {t("general.keywords.fields.myAccount")}
        </Typography>
        <StyledPersonalSettings
          to="/personal-settings"
          style={{
            textDecoration: "none",
            textTransform: "none",
            color: "inherit",
          }}
        >
          <MenuItem onClick={handleClose}>
            {" "}
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            {t("general.keywords.fields.settings")}
          </MenuItem>
        </StyledPersonalSettings>
      </Menu>
    </div>
  );
};
