import { TextField } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { TimesheetMonthDTO } from "../../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { TimesheetMonthWithOthersDTO } from "../../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthWithOthersDTO";
import { TimesheetCalendarForm } from "../TimesheetCalendarForm";
import { StyledAppointmentFieldsArea, StyledForm } from "./index.styles";
import { getTimesheetLineIdentification } from "../../../../../shared/helpers/data/timesheet/general.helpers";
import { ProjectStatus } from "../../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { CreateFormValues } from "../../../hooks/useCreationForm/shared/types/data/form/values.types";
import {
  EditingFormValues,
  DaysTimesheetValues,
} from "../../../hooks/useEditingForm/types/values.types";
import { QuickFillForm } from "./components/QuickFillForm";
import { CreationType, FormMode } from "../../types/values.types";
import { ActivitiesWithNoPermissionAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/activities/ActivitiesWithNoPermissionAutocompleteFormik";
import { TimesheetContext } from "../../../../../shared/react/contexts/TimesheetContext/TimesheetContextProvider";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { PipedriveOrganizationAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/pipedriveDeal/PipedriveOrganizationAutocompleteFormik";
import {
  AppointmentTypeAutocompleteFormik,
  TimesheetAppointmentType,
} from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/timesheet/AppointmentTypeAutocompleteFormik";
import { ProjectCacheAutocompleteFormikWithoutPermissionType } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectCacheAutocompleteFormikWithoutPermissionType";

interface OwnProps {
  timesheetMonthWithOthers: TimesheetMonthWithOthersDTO | null;
  timesheetMonth: TimesheetMonthDTO | null;
  formMode: FormMode;
  creationType: CreationType | null;
}

export const CommonTimesheetModifyingForm = ({
  timesheetMonthWithOthers,
  timesheetMonth,
  formMode,
  creationType,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { dateFilter, timesheetData } = useContext(TimesheetContext);
  const yearMonth = useMemo(
    () => YearMonth.createFromDate(dateFilter),
    [dateFilter]
  );

  const { values: creationValues, setFieldValue: setFieldValueCreationForm } =
    useFormikContext<CreateFormValues>();

  const { values: editingValues, setFieldValue: setFieldValueEditingForm } =
    useFormikContext<EditingFormValues>();

  const isEditing = formMode === "editing";
  const isCreation = formMode === "creation";
  const isViweing = formMode === "viewing";
  const isCreationProject = creationType === "project";
  const isCreationCostCenter = creationType === "costCenter";

  const quickFill = useCallback(
    (field: string, value: DaysTimesheetValues[]) => {
      if (isCreation) {
        setFieldValueCreationForm(field, value);
      } else if (isEditing) {
        setFieldValueEditingForm(field, value);
      }
    },
    []
  );

  const currentDaysValues = useMemo(() => {
    if (isCreation) return creationValues.days;
    if (isEditing) return editingValues.days;
    return [];
  }, [creationValues.days, editingValues.days]);

  const showCalendar = useMemo(() => {
    if (isEditing || isViweing) return true;
    if (isCreation && creationValues.appointment) return true;
    return false;
  }, [creationValues.appointment]);

  const showPipedriveOrganizationField = useMemo(() => {
    if (!(isCreation && isCreationCostCenter) || !creationValues.appointment)
      return false;

    const appointment = creationValues.appointment as TimesheetAppointmentType;

    const mustShowPipedriveOrganizationField = !!appointment.costCenter;

    return mustShowPipedriveOrganizationField;
  }, [creationValues.appointment]);

  useEffect(() => {
    setFieldValueEditingForm("pipedriveOrganization", null);
  }, [showPipedriveOrganizationField]);

  return (
    <StyledForm>
      {isCreation && isCreationProject && (
        <StyledAppointmentFieldsArea>
          <ProjectCacheAutocompleteFormikWithoutPermissionType
            name="appointment"
            filters={{
              statusIncludeOnly: [
                ProjectStatus.Ongoing,
                ProjectStatus.Finalized,
              ],
              finalizedInTheCurrentMonth: true,
            }}
          />
          {creationValues.appointment && (
            <ActivitiesWithNoPermissionAutocompleteFormik
              name="activity"
              filters={{
                idProject: creationValues.appointment.id,
                yearmonth: yearMonth,
              }}
            />
          )}
        </StyledAppointmentFieldsArea>
      )}

      {isCreation && isCreationCostCenter && (
        <StyledAppointmentFieldsArea>
          <AppointmentTypeAutocompleteFormik
            name="appointment"
            idSubsidiaryLastHistory={
              timesheetData?.idSubsidiaryLastHistory ?? null
            }
          />
          {showPipedriveOrganizationField && (
            <PipedriveOrganizationAutocompleteFormik name="pipedriveOrganization" />
          )}
        </StyledAppointmentFieldsArea>
      )}

      {timesheetMonth && (
        <StyledAppointmentFieldsArea>
          <TextField
            value={getTimesheetLineIdentification(timesheetMonth, t, i18n)}
            label={t("timesheets.keywords.fields.appointment")}
            disabled={isEditing || isViweing}
            InputProps={{ readOnly: isEditing || isViweing }}
          />
          {timesheetMonth.activity && (
            <TextField
              value={timesheetMonth.activity.description}
              label={t("activities.keywords.general.activity")}
              disabled
            />
          )}
          {timesheetMonth.pipedriveOrganization && (
            <TextField
              value={timesheetMonth.pipedriveOrganization.name}
              label={t("general.keywords.fields.clientAccount")}
              disabled
            />
          )}
        </StyledAppointmentFieldsArea>
      )}

      {(isCreation || isEditing) && showCalendar && (
        <QuickFillForm
          quickFill={quickFill}
          currentValues={currentDaysValues}
        />
      )}

      {showCalendar && (
        <TimesheetCalendarForm
          name="days"
          timesheetMonth={timesheetMonthWithOthers}
          isReadOnly={isViweing}
        />
      )}
    </StyledForm>
  );
};
