import { TFunction } from "i18next";
import { Week } from "../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { weekAbreviationToTranslationCode } from "../../../../../../../../../shared/common/maps/enums/data/dates/weekToTranslationCode.maps";
import { TimesheetMonthDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { WeekDay } from "../../../types/data/form/general.types";
import { getNowBrazilianDate } from "../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

export const getTimesheetLineIdentification = (
  timesheet: TimesheetMonthDTO,
  t: TFunction<"translation", undefined>,
  i18n: { language: string }
) => {
  if (timesheet.costCenter && timesheet.corporateSegment) {
    const translatedName =
      timesheet.corporateSegment.corporateSegmentTranslation.find(
        (translation) => translation.idLanguage === i18n.language
      )?.name;
    return `${timesheet.costCenter.code} - ${translatedName || timesheet.corporateSegment.name}`;
  }

  if (timesheet.costCenter) {
    return `${timesheet.costCenter.code} - ${t(employeeAllocationTypeToTranslationCodeMap[timesheet.allocationType])}`;
  }

  if (timesheet.corporateSegment) {
    const translatedName =
      timesheet.corporateSegment.corporateSegmentTranslation.find(
        (translation) => translation.idLanguage === i18n.language
      )?.name;
    return `${translatedName || timesheet.corporateSegment.name}`;
  }

  if (timesheet.project) {
    return timesheet.project.name;
  }

  if (timesheet.allocationType) {
    return t(
      employeeAllocationTypeToTranslationCodeMap[timesheet.allocationType]
    );
  }

  return "-";
};

export function getWeekDays(date: Date) {
  const currentDay = date.getDay();

  const sunday = new Date(date);
  sunday.setDate(date.getDate() - currentDay);

  const week: WeekDay[] = [];

  for (let i = 0; i < 7; i++) {
    const day = new Date(sunday);
    day.setDate(sunday.getDate() + i);
    week.push({
      dayOfWeek: weekAbreviationToTranslationCode[day.getDay() as Week],
      date: day,
    });
  }

  return week;
}

export function getDaysInMonth(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return new Date(year, month, 0).getDate();
}

export const getWeekNumber = (date: Date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const canFillThatDay = (
  dateFilter: Date,
  hasTrustedRule: boolean,
  day: number
): boolean => {
  const dateToFill = new Date(dateFilter.getTime());
  dateToFill.setDate(day);
  dateToFill.setHours(0, 0, 0, 0);

  const today = getNowBrazilianDate();
  today.setHours(0, 0, 0, 0);

  return hasTrustedRule || dateToFill <= today;
};
