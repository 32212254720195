import * as yup from "yup";

export interface PipedriveOrganizationDropdownDTO {
  id: number;
  name: string;
  registrationCode: string;
}

export const schemaPipedriveOrganizationDropdownDTO = yup
  .object({
    id: yup.number().defined(),
    name: yup.string().defined(),
    registrationCode: yup.string().defined(),
  })
  .defined();
