import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isBefore, startOfMonth } from "date-fns";
import { useTranslation } from "react-i18next";
import { TimesheetContextValues } from "./index.types";
import { getNowBrazilianDate } from "../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { TimesheetsUserMonthDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetsUserMonthDTO";
import { getTimesheetsPerMonth } from "../../../../../../../../../services/timesheet/timesheets.service";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { notifyError } from "../../../../../../../../../services/applicationState/toast.service";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

interface OwnProps {
  children: ReactNode;
}

export const TimesheetContext = createContext<TimesheetContextValues>({
  dateFilter: getNowBrazilianDate(),
  setDateFilter: () => null,
  timesheetData: null,
  loading: false,
  reloadTimesheetData: async () => {},
  setLoading: () => null,
  canEditTimesheet: false,
});

export const TimesheetContextProvider = ({ children }: OwnProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<Date>(() =>
    getNowBrazilianDate()
  );
  const [previousDateFilter, setPreviousDateFilter] = useState<Date | null>(
    null
  );

  const [timesheetData, setTimesheetData] =
    useState<TimesheetsUserMonthDTO | null>(null);

  const reloadTimesheetData = useCallback(async () => {
    setTimesheetData(null);
    try {
      setLoading(true);
      const timesheetData = await getTimesheetsPerMonth({
        filters: {
          yearMonth: YearMonth.createFromDate(dateFilter),
        },
      });
      setTimesheetData(timesheetData);
      setPreviousDateFilter(dateFilter);
    } catch (error) {
      notifyError(t("timesheets.errors.data.edit.failedToEditTimesheetEntry"));
    } finally {
      setLoading(false);
    }
  }, [dateFilter]);

  useEffect(() => {
    const currentYearMonthData = previousDateFilter
      ? YearMonth.createFromDate(previousDateFilter)
      : null;
    if (
      currentYearMonthData &&
      YearMonth.createFromDate(dateFilter).equals(currentYearMonthData)
    ) {
      setPreviousDateFilter(dateFilter);
      return;
    }

    reloadTimesheetData();
  }, [dateFilter]);

  const isPastMonth = useMemo(
    () => isBefore(dateFilter, startOfMonth(getNowBrazilianDate())),
    [dateFilter]
  );

  const canEditTimesheet = useMemo(() => !isPastMonth, [isPastMonth]);

  const timesheetContext: TimesheetContextValues = useMemo(() => {
    return {
      dateFilter,
      setDateFilter,
      timesheetData,
      loading,
      reloadTimesheetData,
      setLoading,
      canEditTimesheet,
    };
  }, [dateFilter, timesheetData, loading, canEditTimesheet]);

  return (
    <TimesheetContext.Provider value={timesheetContext}>
      {children}
      <LoadingBackdrop open={loading} />
    </TimesheetContext.Provider>
  );
};
