import { useMemo } from "react";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { TimesheetSuperUserContext } from "./shared/react/contexts/TimesheetSuperUserContext";
import { TimesheetPageWithinContext } from "./TimesheetPageWithinContext";
import { TimesheetContextProvider } from "./shared/react/contexts/TimesheetContext/TimesheetContextProvider";

export const TimesheetPageV2 = () => {
  const {
    superUserButton: canEditPastTimesheetButton,
    inSuperUserMode: canEditPastTimesheet,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditPastTimesheet,
    tooltipNotInSuperUserModeOff:
      "timesheets.actions.authorization.editTimesheetWithoutRestrictions",
    tooltipInSuperUserMode:
      "timesheets.actions.authorization.stopEditTimesheetWithoutRestrictions",
  });

  const timesheetSuperUserValues = useMemo(() => {
    return {
      canEditPastTimesheetButton,
      canEditPastTimesheet,
    };
  }, [canEditPastTimesheetButton, canEditPastTimesheet]);

  return (
    <TimesheetSuperUserContext.Provider value={timesheetSuperUserValues}>
      <TimesheetContextProvider>
        <TimesheetPageWithinContext />
      </TimesheetContextProvider>
    </TimesheetSuperUserContext.Provider>
  );
};
