import { styled } from "@mui/material";

export const UserAreaContainer = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.2)};
  align-items: center;

  & > :nth-child(2) {
    color: ${({ theme }) => theme.palette.text.secondary};

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    width: auto;
    max-width: 280px;
    font-weight: 400;
  }
`;
